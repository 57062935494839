import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import Idle from "./Idle"

export default ({ animate, exit, thrust, fill, style }: any) => (
  <AnimatePresence>
    <motion.div
      transition={{
        rotateZ: {
          type: "spring",
          damping: 33,
          stiffness: 333,
        },
      }}
      initial={{ opacity: 0, y: -10 }}
      animate={{
        opacity: 1,
        y: 0,
        ...(animate || {}),
      }}
      exit={{ opacity: 0, ...(exit || {}) }}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...(style || {}),
      }}
    >
      {thrust ? (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{
            opacity: 0.5,
            x: 50,
          }}
          transition={{
            x: {
              repeatType: "mirror",
              duration: 0.5,
              repeat: Infinity,
              ease: "anticipate",
              // "linear" | "easeIn" | "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" | "backInOut" | "anticipate" | EasingFunction;
            },
            opacity: {
              repeatType: "mirror",
              duration: 1,
              repeat: Infinity,
            },
          }}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Idle fill={fill} />
        </motion.div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Idle fill={"#32AB40"} />
        </div>
      )}
    </motion.div>
  </AnimatePresence>
)
