import { Link } from "react-router-dom"
import React from "react"
import { Button } from "../components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStream, faBuilding } from "@fortawesome/free-solid-svg-icons"
import { useMutation, gql } from "@apollo/client"
import moment from "moment"

export default [
  {
    Header: "Actions",
    accessor: (v: any) => v,
    Cell: ({ cell: { value: facility } }: any) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "0 1em",
          minHeight: 38,
        }}
      >
        {facility.lite && (
          <Link
            to={`/facilities/${facility.id}/timeline`}
            style={{ margin: "0 0.25em" }}
            title="Lite"
          >
            <Button style={{ margin: 0, padding: 4 }}>
              <FontAwesomeIcon icon={faStream} size={"2x"} />
            </Button>
          </Link>
        )}

        {facility.tower && (
          <Link
            to={`/facilities/${facility.id}`}
            style={{ margin: "0 0.25em" }}
            title="Towers"
          >
            <Button style={{ margin: 0, padding: 4 }}>
              <FontAwesomeIcon icon={faBuilding} size={"2x"} />
            </Button>
          </Link>
        )}
      </div>
    ),
  },
  {
    Header: "Name",
    accessor: (v: any) => v,
    id: "name",
    Cell: ({ cell, more }: any) =>
      (more?.me?.roles || []).includes("SUDO") ? (
        <Link to={`/facilities/${cell.value.id}`}>{cell?.value?.name}</Link>
      ) : (
        <div>{cell?.value?.name}</div>
      ),
  },
  {
    Header: "Spaces",
    accessor: "spacesLength",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Cell: ({ cell: { value }, row }: any) => {
      const [updateFacility] = useMutation(gql`
        mutation UpdateFacility($id: ID!, $input: UpdateFacilityInput!) {
          updateFacility(id: $id, input: $input) {
            id
            placie
          }
        }
      `)
      return (
        <input
          type="checkbox"
          checked={value}
          onChange={async (e) => {
            if (!window.confirm("Really?")) {
              return
            }
            await updateFacility({
              variables: {
                id: row.original.id,
                input: { placie: e.target.checked },
              },
            })
          }}
        />
      )
    },
    Header: "Placie",
    accessor: "placie",
  },
  {
    Cell: ({ cell: { value }, row }: any) => {
      const [updateFacility] = useMutation(gql`
        mutation UpdateFacility($id: ID!, $input: UpdateFacilityInput!) {
          updateFacility(id: $id, input: $input) {
            id
            placie
          }
        }
      `)
      return row.original.placie ? (
        <input
          type="number"
          value={value}
          onBlur={async (e) => {
            if (!window.confirm("Really?")) {
              return
            }
            await updateFacility({
              variables: {
                id: row.original.id,
                input: { capacity: parseInt(e.target.value, 10) || 0 },
              },
            })
          }}
        />
      ) : (
        <div></div>
      )
    },
    Header: "Placie Capacity",
    accessor: "capacity",
  },
  {
    Cell: ({ cell: { value } }: any) => (
      <div>{moment(value).fromNow(true)}</div>
    ),
    Header: "Created",
    accessor: "createdAt",
  },
]
