import { useQuery } from "@apollo/client"
import COLUMNS from "./columns"
import React from "react"
import Table from "../../Table"
import { gql } from "@apollo/client"
import Spinner from "../../Spinner"

export default ({ facility }: any) => {
  const { data } = useQuery(
    gql`
      query Spaces($facilityId: ID!) {
        facility(id: $facilityId) {
          id
          spaces {
            id
            label
            tenancy {
              id
              organization {
                id
                name
              }
            }
            assignments {
              id
              membership {
                id
                user {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `,
    { skip: !facility?.id, variables: { facilityId: facility?.id } }
  )

  const spaces = data?.facility?.spaces

  return spaces ? (
    <Table
      data={spaces}
      columns={COLUMNS}
      initialState={{ sortBy: [{ id: "label" }] }}
    />
  ) : (
    <Spinner />
  )
}
