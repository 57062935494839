import { gql, useQuery } from "@apollo/client"
import { sortBy } from "lodash"
import React from "react"

export default () => {
  const { data } = useQuery(gql`
    {
      gates {
        id
        gateDescription
        facility {
          id
          name
        }
      }
    }
  `)

  const gates = sortBy(data?.gates || [], ({ facility: { name } }: any) => name)

  return (
    <div>
      {gates.map((gate: any) => {
        return (
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "100%", display: "flex" }}>{gate.id}</div>
            <div style={{ width: "100%", display: "flex" }}>
              {gate.facility.name}
            </div>
            <div style={{ width: "100%", display: "flex" }}>
              {gate.gateDescription}
            </div>
          </div>
        )
      })}
    </div>
  )
}
