import { Button, Form, Input, Label } from "../components"
import { Field } from "formik"
import Debug from "../Debug"
import React from "react"
import moment from "moment"

export interface FormValues {
  lo: string
  hi: string
  plate: string
}

export default ({ values, setFieldValue, onCancel, isSubmitting }: any) => {
  return (
    <Form>
      <>
        <Label>Plate</Label>
        <Field
          as={Input}
          name="plate"
          required
          autoComplete="plate"
          style={{ textTransform: "uppercase" }}
        />
      </>

      <>
        <Label>Start</Label>
        <Field
          as={Input}
          type="datetime-local"
          name="lo"
          required
          value={moment(values.lo).format("YYYY-MM-DDTHH:mm")}
          onChange={(e: any) =>
            setFieldValue(
              "lo",
              moment(e.target.value, "YYYY-MM-DDTHH:mm").toISOString(true)
            )
          }
        />
      </>
      <>
        <Label>End</Label>
        <Field
          as={Input}
          type="datetime-local"
          name="hi"
          required
          value={moment(values.hi).format("YYYY-MM-DDTHH:mm")}
          onChange={(e: any) =>
            setFieldValue(
              "hi",
              moment(e.target.value, "YYYY-MM-DDTHH:mm").toISOString(true)
            )
          }
        />
      </>
      <div
        style={{
          display: "grid",
          gridGap: "0.5em",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <Button
          onClick={(event) => {
            event.preventDefault()
            onCancel()
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          type="submit"
          style={{
            borderColor: "hsl(127, 55%, 43%)",
            color: "hsl(127, 55%, 43%)",
            width: "100%",
          }}
        >
          Continue
        </Button>
      </div>
      <Debug>{values}</Debug>
    </Form>
  )
}
