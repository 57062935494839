import { Container, Header } from "../components"
import { Formik } from "formik"
import DeleteReservation from "./DeleteReservation"
import React from "react"
import ReservationForm from "../ReservationForm"

export default ({ onCancel, onDelete, reservation = {}, onSubmit }: any) => {
  const { id } = reservation

  return (
    <Container fixed>
      <Header>
        <h3>Update Reservation</h3>
        <DeleteReservation {...{ id, onDelete }} />
      </Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          hi: reservation?.hi,
          lo: reservation?.lo,
          plate: reservation?.plate,
          space: reservation?.space?.id,
        }}
        onSubmit={(values) => onSubmit({ id, ...values })}
      >
        {(props: any) => <ReservationForm {...props} {...{ onCancel }} />}
      </Formik>
    </Container>
  )
}
