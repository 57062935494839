import { setContext } from "@apollo/client/link/context"
import {
  InMemoryCache,
  HttpLink,
  ApolloClient,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client"
import React from "react"
import ENDPOINT from "./endpoint"

const httpLink = new HttpLink({
  uri: ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Create Apollo Client instance
const client = new ApolloClient({
  queryDeduplication: false,
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

// Expose client for debugging
(window as any).APOLLO = client;

export default ({ children }: any) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);
