import React from "react"
import TimeCell from "../TimeCell"

export default [
  {
    Header: "Amount",
    accessor: "amountAud",
    Cell: ({ cell: { value } }: any) => <div>{value}</div>,
  },
  {
    Header: "Time",
    accessor: "createdAt",
    Cell: TimeCell,
  },
  {
    Header: "Approved",
    accessor: "approved",
    Cell: ({ cell: { value } }: any) => <div>{value ? "true" : "false"}</div>,
  },
  {
    Header: "Error",
    accessor: "error",
    Cell: ({ cell: { value } }: any) => <div>{value}</div>,
  },
  // {
  //   Header: "Gate Terminal",
  //   accessor: "gateTerminalId",
  //   Cell: ({ cell: { value } }: any) => <div>{value}</div>,
  // },
]
