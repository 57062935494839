import { Container, Header } from "../components"
import { Formik, FormikHelpers } from "formik"
import { useHistory } from "react-router"
import { useMutation, useQuery } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import React from "react"
import ShareForm, { FormValues } from "../ShareForm"
import Spinner from "../Spinner"
import { gql } from "@apollo/client"

export default ({ id, lo, hi }: any) => {
  const history = useHistory()
  const { addToast } = useToasts()

  const [shareSpace] = useMutation(gql`
    mutation ShareSpace($input: ShareSpaceInput!) {
      shareSpace(input: $input) {
        id
      }
    }
  `)

  const { data: membershipsData } = useQuery(gql`
    query {
      me {
        id
        memberships {
          id
          organization {
            id
            name
            memberships {
              id
              user {
                id
                name
              }
            }
          }
          user {
            id
            name
          }
        }
      }
    }
  `)

  const { data: spaceData } = useQuery(
    gql`
      query Space($id: ID!) {
        space(id: $id) {
          id
          label
          facility {
            id
            name
            address
          }
        }
      }
    `,
    { variables: { id } }
  )
  const space = spaceData?.space

  return membershipsData?.me?.memberships ? (
    <Container fixed>
      <Header>
        <h3>Share your space</h3>
      </Header>
      <Formik
        initialValues={{
          hi,
          lo,
          organization: membershipsData?.me?.memberships[0].organization?.id,
          space: id,
        }}
        // @ts-ignore
        onSubmit={async (
          { membership, organization, ...values }: any,
          _actions: FormikHelpers<FormValues>
        ) => {
          await shareSpace({
            refetchQueries: ["Assignments"],
            variables: {
              input: {
                ...values,
                ...(membership ? { membership } : { organization }),
              },
            },
          })
          addToast("Share created!", {
            appearance: "success",
          })
          history.push("/")
        }}
      >
        {(props: any) => <ShareForm {...props} {...{ space }} />}
      </Formik>
    </Container>
  ) : (
    <Spinner />
  )
}
