import { useQuery } from "@apollo/client"
import Event from "./Event"
import React from "react"
import Spinner from "../Spinner"
import { gql } from "@apollo/client"

export default () => {
  const { data } = useQuery(
    gql`
      query Events {
        events {
          id
        }
      }
    `,
    { pollInterval: 5555 }
  )

  const events = data?.events || []

  return data?.events ? (
    <div>
      {events.map((event: any) => (
        <Event {...event} />
      ))}
    </div>
  ) : (
    <Spinner />
  )
}
