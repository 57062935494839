import styled from "@emotion/styled"

export const Label = styled.label`
  display: block;
  color: ${(props: any) => props.theme.primary};
  border: none;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
  text-transform: uppercase;
  font-size: smaller;
`
