import React from "react"
import styled from "@emotion/styled"
import CELL_WIDTH from "./cell_width"
// import { motion } from "framer-motion"

const SpanStyles = styled.div`
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    box-shadow: 0px 0px 16px -2px hsla(0, 0%, 50%, 0.5);
  }
`

export default ({ i, epoch, range, style = {}, children, ...props }: any) => (
  <SpanStyles
    style={{
      ...style,
      background:
        i != null
          ? `linear-gradient(180deg, #f08, #d0e, #91f, #70f, #40f, #05f, #09f)`
          : "rebeccapurple",
      backgroundAttachment: "fixed",
      borderRadius: "5px",
      left: range.start.diff(epoch, "days", true) * CELL_WIDTH,
      position: "absolute",
      top: 1,
      bottom: 1,
      width: range.diff("days", true) * CELL_WIDTH,
      textTransform: "uppercase",
      fontSize: 12,
    }}
    {...props}
  >
    {children}
  </SpanStyles>
)

/*
<motion.div
    initial={{ scale: 0 }}
    animate={{ scale: 1 }}
    transition={{
      damping: 20,
      stiffness: 500,
      type: "spring",
    }}
  ></motion.div>
*/
