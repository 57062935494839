import React from "react"
import { Button } from "../components"

export default ({ children, setDemo, demo, setScreen }: any) => {
  return (
    <div style={{ height: "100%", position: "relative" }}>
      {children}
      <div style={{ position: "absolute", bottom: 0, display: "flex" }}>
        <div>
          {[
            ["slow", 5555],
            ["fast", 555],
          ].map(([k, v]: any) => {
            return (
              <Button
                style={{ padding: "0.5em", cursor: "pointer" }}
                onClick={() => setDemo(v)}
                {...(demo === v ? { positive: true } : {})}
              >
                {k}
              </Button>
            )
          })}
        </div>

        <div style={{ display: "flex" }}>
          {["idle", "awaitingPlate", "payment.checkWhitelist", "gateOpen"].map(
            (state) => (
              <Button
                style={{ padding: "0.5em", cursor: "pointer" }}
                onClick={() => setScreen(state)}
              >
                {state}
              </Button>
            )
          )}
        </div>
      </div>
    </div>
  )
}
