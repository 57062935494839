import { NavLink as RRNavLink } from "react-router-dom"
import styled from "@emotion/styled"

export const NavLink = styled(RRNavLink)`
  color: hsla(0, 66%, 66%, 1);
  &.active {
    color: ${(props: any) => props.theme.color};
  }
  &:hover {
    color: ${(props: any) => props.theme.color};
  }
`
