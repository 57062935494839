import { useQuery } from "@apollo/client"
import { gql } from "@apollo/client"

export default () => {
  return useQuery(gql`
    query Assignments {
      me {
        id
        assignments {
          id
          lo
          hi
          space {
            id
            label
          }
          source {
            id
          }
        }
        shares {
          id
          lo
          hi
          space {
            id
          }
          user {
            id
            name
          }
          organization {
            id
            name
          }
        }
        requests {
          id
          lo
          hi
          tenancy {
            id
            facility {
              id
              name
              address
            }
          }
        }
        memberships {
          id
          organization {
            id
            shares {
              id
              lo
              hi
              space {
                id
              }
            }
            tenancies {
              id
              facility {
                id
                name
                address
              }
            }
          }
        }
      }
    }
  `)
}
