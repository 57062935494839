import { gql, useQuery } from "@apollo/client"
import React from "react"
import { Link } from "react-router-dom"
import Edge from "../Edge"

export default () => {
  const { data } = useQuery(
    gql`
      {
        facilities {
          id
          name
          monitor
        }
      }
    `
  )
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateRows: "repeat(1, 1fr)",
      }}
    >
      {(data?.facilities || [])
        .filter(({ monitor }: any) => monitor)
        .map(({ id, name }: any) => (
          <div>
            <Link to={`/edges/${id}`}>{name}</Link>
            <Edge {...{ id }} compact={true} />
          </div>
        ))}
    </div>
  )
}
