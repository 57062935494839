import React from "react"
import moment from "moment"
import Plate from "../Plate"
import Action from "../Action"
import COLUMNS from "./columns"
import Table from "../../Table"

const approx = (a: any, b: any) =>
  Math.abs(moment(a).diff(moment(b), "seconds")) < 15

export default ({ observations, gateCharges }: any) => {
  const cluster = (d: any) =>
    d.reduce(
      (r: any, c: any, i: any, a: any) => (
        a[i].plate === a[i - 1]?.plate &&
        approx(a[i].createdAt, a[i - 1]?.createdAt)
          ? r[r.length - 1].push(c)
          : r.push([c]),
        r
      ),
      []
    )

  const clusters = cluster(observations).map((observations: any) => {
    const lo = moment.min(observations.map((o: any) => moment(o.createdAt)))
    const hi = moment.max(observations.map((o: any) => moment(o.createdAt)))
    return {
      plate: observations[0].plate,
      observations,
      lo,
      hi,
      duration: Math.abs(lo.diff(hi, "seconds")),
      gateCharges,
    }
  })

  return (
    <Table
      data={clusters}
      columns={COLUMNS}
      // initialState={{ sortBy: [{ desc: true, id: "createdAt" }] }}
    />
  )

  // return (
  //   <div style={{ width: "100%" }}>
  //     {clusters.map(
  //       ({ plate, observations, lo, hi, duration }: any, i: number) => {
  //         const open =
  //         const gap = i
  //           ? Math.abs(lo.diff(clusters[i - 1].hi))
  //           : Math.abs(lo.diff(moment()))
  //         return (
  //           <>
  //             <div
  //               style={{
  //                 opacity: 0.5,
  //                 display: "flex",
  //                 justifyContent: "flex-end",
  //                 alignItems: "center",
  //                 height: 1,
  //                 background: "grey",
  //               }}
  //             >
  //               {gap ? <div>{moment.duration(gap).humanize()}</div> : null}
  //             </div>
  //             <div
  //               style={{
  //                 display: "flex",
  //                 width: "100%",
  //                 justifyContent: "space-between",
  //                 alignItems: "center",
  //               }}
  //             >
  //               <Plate>{plate}</Plate>
  //               <div style={{ width: "100%" }}>{observations.length} scans</div>
  //               <div style={{ width: "100%" }}>
  //                 {
  //                   gateCharges.filter(
  //                     (gateCharge: any) => gateCharge.plate === plate
  //                   ).length
  //                 }{" "}
  //                 charges
  //               </div>
  //               <div style={{ width: "100%" }}>{duration}s</div>
  //               <Action value={open}></Action>
  //               <div style={{ width: "100%" }}></div>
  //             </div>
  //           </>
  //         )
  //       }
  //     )}
  //   </div>
  // )
}
