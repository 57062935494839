import "./styles.css"
import React from "react"

export default () => {
  return (
    <main
      role="main"
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <ul role="progressbar" aria-busy="true" aria-label="Loading domino shop">
        <li role="presentation"></li>
        <li role="presentation"></li>
        <li role="presentation"></li>
        <li role="presentation"></li>
        <li role="presentation"></li>
        <li role="presentation"></li>
        <li role="presentation"></li>
      </ul>
    </main>
  )
}
