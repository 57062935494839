import { Container, Header } from "../components"
import { Formik, FormikHelpers } from "formik"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import React from "react"
import FacilityForm, { FormValues } from "../FacilityForm"
import { gql } from "@apollo/client"
import { useHistory } from "react-router"

export default ({ organization }: any) => {
  const history = useHistory()

  const { addToast } = useToasts()

  const [createFacility] = useMutation(gql`
    mutation CreateFacility($input: CreateFacilityInput!) {
      createFacility(input: $input) {
        id
      }
    }
  `)

  return (
    <Container fixed>
      <Header>
        <h3>Create Facility</h3>
      </Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: "",
          address: "",
          organization,
          spaces: 10,
        }}
        onSubmit={async (input: any, _actions: FormikHelpers<FormValues>) => {
          await createFacility({
            variables: {
              input,
            },
            refetchQueries: ["Me"],
          })
          addToast("Facility created!", {
            appearance: "success",
          })
          history.push("/")
        }}
      >
        {(props: any) => <FacilityForm {...props} />}
      </Formik>
    </Container>
  )
}
