import { useState, useEffect } from "react"
import { gql } from "@apollo/client"
import createApollo from "./apollo"
const apollo = createApollo()

export default () => {
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await apollo.query({
          query: gql`
            query Things {
              things {
                id
                state
                mac
                archetype {
                  id
                }
                createdAt
                updatedAt
                logs
                temperature: observation(
                  probe: "7ea568d8-6678-45a9-854a-dbc5d7d5b8e0"
                ) {
                  value
                }
                ssh: observation(
                  probe: "89512d9a-eaa1-48a6-9100-94cc1620c6b8"
                ) {
                  value
                }
              }
            }
          `,
          fetchPolicy: "no-cache",
        })
        setData(data)
      } catch (e) {
        console.error(e)
      }
    }
    const interval = setInterval(fetch, 6666)
    fetch()
    return () => {
      clearInterval(interval)
    }
  }, [])

  return data?.data?.things || []
}
