import { gql, useQuery } from "@apollo/client"
import styled from "@emotion/styled"
import moment from "moment"
import React, { useEffect, useRef } from "react"
import { Link } from "react-router-dom"

const TransactionStyles = styled.div`
  display: flex;
  transition: all 0.1s linear;

  & > * {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  video {
    display: block;
    height: 128px;
    width: 200px;
  }

  &:hover {
    video {
      display: block;
    }
  }
`

const Transaction = (props: any) => {
  const { id, plate, createdAt, closedAt, videoUrl, active } = props
  const ref = useRef<HTMLVideoElement | null>(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.currentTime = 11
      // ref.current.playbackRate = 10
    }
  }, [ref])
  return (
    <TransactionStyles>
      <Link to={`/transactions/${id}`} style={{ opacity: 0.5 }}>
        {id.split("-")[0]}
      </Link>{" "}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{moment(createdAt).calendar()}</div>{" "}
        <div>{moment(createdAt).fromNow()}</div>
      </div>
      <div>{plate}</div>
      <div>
        {closedAt
          ? moment(closedAt).diff(moment(createdAt), "seconds")
          : "OPEN"}
      </div>
      {videoUrl ? (
        <video ref={ref} controls src={videoUrl} muted loop></video>
      ) : null}
    </TransactionStyles>
  )
}

export default ({ id }: any) => {
  const { data, error, loading } = useQuery(
    gql`
      query GateTransactions($id: ID!, $lo: String!, $hi: String!) {
        gate(id: $id) {
          id
          transactions(lo: $lo, hi: $hi) {
            id
            plate
            createdAt
            closedAt
            videoUrl
          }
        }
      }
    `,
    {
      variables: {
        id,
        lo: moment().subtract(4, "hour").toISOString(),
        hi: moment().toISOString(),
      },
      pollInterval: 0,
      fetchPolicy: "no-cache",
    }
  )

  const transactions = data?.gate?.transactions || []

  return (
    <div>
      {transactions.slice(0, 8).map((transaction: any) => (
        <Transaction {...transaction} />
      ))}
    </div>
  )
}
