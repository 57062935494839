import { ThemeProvider as EmotionThemeProvider } from "emotion-theming"
import React, { createContext, useContext, useEffect, useState } from "react"
import theme from "./theme"

export const KEY = "3A123271-316D-44E1-87DE-765786736C03"

const ThemeContext = createContext({
  dark: false,
  toggle: () => {
    // do nothing
  },
})

const useTheme = () => useContext(ThemeContext)

const useEffectDarkMode = () => {
  const [themeState, setThemeState] = useState({
    dark: false,
    hasThemeMounted: false,
  })

  useEffect(() => {
    const ls = localStorage.getItem(KEY)
    const dark = ls
      ? ls === "dark"
      : window.matchMedia("(prefers-color-scheme: dark)")?.matches
    setThemeState({ ...themeState, dark, hasThemeMounted: true })
    const mqlListener = (e: any) => {
      setThemeState({
        ...themeState,
        dark: e.matches,
        hasThemeMounted: true,
      })
    }
    const mql = window.matchMedia("(prefers-color-scheme: dark)")
    mql.addListener(mqlListener)
    return () => mql?.removeListener(mqlListener)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [themeState, setThemeState]
}

const ThemeProvider = ({ children }: any) => {
  const [themeState, setThemeState]: any = useEffectDarkMode()

  if (!themeState.hasThemeMounted) {
    return <div />
  }

  const toggle = () => {
    const dark = !themeState.dark
    localStorage.setItem(KEY, dark ? "dark" : "light")
    setThemeState({ ...themeState, dark })
  }

  return (
    <EmotionThemeProvider theme={theme(themeState.dark ? "dark" : "light")}>
      <ThemeContext.Provider
        value={{
          dark: themeState.dark,
          toggle,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </EmotionThemeProvider>
  )
}

export { ThemeProvider, useTheme }
