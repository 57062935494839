import React, { useRef, useEffect, useState } from "react"

export default ({ url }: any) => {
  const ref = useRef<any>(null)
  const [mounted, setMounted] = useState(true)
  const fetch = async () => {
    if (!mounted) {
      return
    }
    const img = await new Promise((resolve, reject) => {
      setTimeout(() => {
        // @ts-ignore
        resolve()
      }, 2000)
      const img = document.createElement("img")
      img.onload = () => {
        resolve(img)
      }
      img.src = `${url}&rnd=${Math.random()}`
      return img
    })
    if (img && ref.current) {
      const ctx = ref.current.getContext("2d")
      ctx.drawImage(img, 0, 0, ref.current.width, ref.current.height)
    }
    setTimeout(fetch, 1000 / 24)
  }
  useEffect(() => {
    fetch()
    return () => {
      setMounted(false)
    }
  })
  return (
    <canvas
      ref={ref}
      style={{
        width: 300,
        height: 240,
        backgroundSize: "cover",
      }}
    ></canvas>
  )
}
