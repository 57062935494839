import { gql, useQuery } from "@apollo/client"
import { faAddressCard, faMask } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as jwt from "jsonwebtoken"
import moment from "moment"
import React, { useMemo, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Button, Header } from "../components"
import Table from "../Table"

const Users = ({ users }: any) => {
  const history = useHistory()

  const [filters] = useState([])

  const columns = useMemo(
    () =>
      [
        {
          Cell: ({ cell: { value: user } }: any) => {
            return (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                {/* <Link to={`/users/${user.id}`}>
                  <Button style={{ margin: 0, padding: "0 2px" }}>
                    <FontAwesomeIcon icon={faAddressCard} />
                  </Button>
                </Link> */}
                {/* <Button
                  style={{ margin: 0, padding: "0 2px" }}
                  onClick={() => {
                    const token = jwt.sign(user, "l3tm3in")
                    localStorage.setItem("token", token)
                    history.push("/settings/profile")
                    ;(window as any).APOLLO.resetStore()
                  }}
                >
                  <FontAwesomeIcon icon={faMask} />
                </Button> */}
              </div>
            )
          },
          Header: "Actions",
          accessor: (v: any) => v,
        },
        {
          Cell: ({ cell: { value } }: any) => (
            <div style={{ color: /.* .*/.test(value) ? "green" : "red" }}>
              {value}
            </div>
          ),
          Header: "Name",
          accessor: "name",
        },
        {
          Cell: ({ cell: { value } }: any) => (
            <a style={{ color: "hsl(0, 0%, 33%)" }} href={`mailto:${value}`}>
              {value}
            </a>
          ),
          Header: "Email",
          accessor: "email",
        },
        {
          Cell: ({ cell: { value } }: any) => <div>{value.join(" ")}</div>,
          Header: "Roles",
          accessor: "roles",
        },
        {
          Cell: ({ cell: { value } }: any) => (
            <div>
              {value
                .map(({ organization }: any) => organization?.name)
                .join(" ")}
            </div>
          ),
          Header: "Memberships",
          accessor: "memberships",
        },
        {
          Cell: ({ cell: { value } }: any) => (
            <div>{moment(value).fromNow(true)}</div>
          ),
          Header: "Created",
          accessor: "createdAt",
        },
        {
          Cell: ({ cell: { value } }: any) => {
            return <div>{value && moment(value).fromNow(true)}</div>
          },
          Header: "Active",
          accessor: ({ activeAt, createdAt }: any) => activeAt || createdAt,
          id: "activeAt",
        },
      ].filter(Boolean),
    [history]
  )

  let pool = users || []
  filters.forEach(([_name, predicate]: any) => {
    pool = pool.filter((user: any) => !predicate(user))
  })

  return (
    <Table
      data={pool}
      columns={columns}
      initialState={{ sortBy: [{ desc: true, id: "activeAt" }] }}
    />
  )
}

export default (_props: any) => {
  const { data } = useQuery(
    gql`
      query Users {
        users {
          id
          name
          email
          roles
          createdAt
          updatedAt
          memberships {
            id
            organization {
              id
              name
            }
          }
        }
      }
    `
  )

  return (
    <div style={{ width: "100%" }}>
      <Header>Users</Header>
      <Users {...{ users: data?.users }} />
    </div>
  )
}
