import { useQuery, gql } from "@apollo/client"
import React from "react"
import Table from "../Table"
import COLUMNS from "./columns"

export default (props: any) => {
  const { data } = useQuery(
    gql`
      query Plate($id: ID!) {
        plate(id: $id) {
          id
          bookings {
            id
            startDay
            endDay
            status
            createdAt
            finalisedAt
            closedAt
            rateCard {
              id
              name
            }
          }
        }
      }
    `,
    { variables: { id: props.plate }, pollInterval: 1337, skip: !props.plate }
  )

  const plate = data?.plate || {}

  return (
    <Table
      data={plate?.bookings || []}
      columns={COLUMNS}
      initialState={{ sortBy: [{ desc: true, id: "createdAt" }] }}
    />
  )
}
