import React from "react"
import Span from "./Span"

export default ({ onMouseDown, ...props }: any) => (
  <Span
    {...props}
    style={{
      alignItems: "center",
      color: "#FEE",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
    }}
    onMouseDown={() => onMouseDown(props)}
  >
    {props.plate}
  </Span>
)
