import { Button, Container, Input, Label } from "../components"
import { Field, Form, Formik } from "formik"
import { useMutation } from "@apollo/client"
import React from "react"
import { gql } from "@apollo/client"

export function Recover() {
  const [recover] = useMutation(gql`
    mutation Recover($email: String!) {
      recover(email: $email)
    }
  `)

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Container>
        <Formik
          initialValues={{}}
          onSubmit={async (values, actions) => {
            await recover({ variables: values })
            actions.setStatus(true)
          }}
        >
          {(props) => (
            <Form>
              {props.status && (
                <p>
                  Check your email for a link to reset your password. If it
                  doesn’t appear within a few minutes, check your spam folder.
                </p>
              )}
              <p>
                Enter your email address and we will send you a password reset
                link.
              </p>
              <>
                <Label style={{ opacity: 0.75 }}>Email</Label>
                <Field
                  as={Input}
                  type="email"
                  name="email"
                  required
                  autoComplete="email"
                />
              </>
              <Button type="submit" positive>
                Send Password Reset Email
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  )
}
