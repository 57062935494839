import { AnimatePresence, motion } from "framer-motion"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"


export function FacilityName({
  show, name, gate
}: {
  show?: boolean
  name?: string
  gate?: string
}) {

  return (
    <AnimatePresence>
      {show ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            fontSize: "1vmin",
            padding: "5vmin",
          }}
        >
          <div style={{
            alignSelf: "flex-end",
            flex: "0 1 auto"
          }}>
            <div>{name}</div>
            <div>{gate}</div>
            <div>v1.8.2-2024-09-16</div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
