import { gql, useMutation } from "@apollo/client"
import React, { useEffect, useRef, useState } from "react"
import Stream from "./Stream"

export default ({
  id,
  gateDescription,
  signs,
  type,
  gpioTargetLevel,
  ip,
  style,
  plate,
}: any) => {
  const [updateGate] = useMutation(gql`
    mutation updateGate($id: ID!, $input: UpdateGateInput!) {
      updateGate(id: $id, input: $input) {
        id
      }
    }
  `)

  return (
    <div style={{ width: "100%", padding: "1em 0.5em", ...(style || {}) }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "33%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
          }}
        >
          {gateDescription}
        </div>
        <div
          style={{
            width: "33%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {plate}
        </div>
        <div style={{ width: "66%", display: "flex" }}>
          {[
            {
              level: 0,
              label: "close",
            },
            {
              level: 1,
              label: "open",
            },
          ].map(({ label, level }: any) => (
            <button
              style={{
                margin: 3,
                padding: "0.5em",
                width: "100%",
                background: "#111",
                color: "#eee",
                border: gpioTargetLevel === level ? "1px solid green" : "none",
                outline: "none",
              }}
              onClick={async () => {
                updateGate({
                  variables: {
                    id,
                    input: {
                      gpioTargetLevel: level,
                      gateDescription,
                    },
                  },
                })
              }}
            >
              {label}
            </button>
          ))}
        </div>
      </div>

      <div style={{ display: "flex", width: "100%" }}>
        <Stream
          url={`http://${ip}/capture/scapture?capture=next&framebuffer=1&source=1`}
        />

        {signs.length ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src={`https://drivo.io/sign?mac=${signs[0].mac}`}
              style={{ border: "none", width: 300, height: 240 }}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}
