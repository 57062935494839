import React from "react"
import moment from "moment"
import TimeCell from "../TimeCell"

export default [
  {
    Header: "Plate",
    accessor: "plate",
  },
  {
    Header: "Open",
    accessor: "open",
    Cell: ({ cell: { value } }: any) => <div>{value ? "true" : "false"}</div>,
  },
  {
    Header: "Gate",
    accessor: "gate",
    Cell: ({ cell: { value } }: any) => <div>{value?.gateDescription}</div>,
  },
  {
    Header: "Time",
    accessor: "createdAt",
    Cell: TimeCell,
  },
]
