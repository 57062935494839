import React, { useEffect, useState } from "react"
import { ApolloProvider, gql, useQuery } from "@apollo/client"
import ThemeButton from "../components/ThemeButton"
import Masquerade, { MasqueradeUUID } from "./Masquerade"
import createAdminApolloClient from "./createAdminApolloClient"
import Eject from "./Eject"
import { useLocation } from "react-router"

const adminApolloClient = createAdminApolloClient()

export default () => {
  const location = useLocation()
  const hidden = ["/sign", "/gates/controls"].some((start) =>
    location.pathname.startsWith(start)
  )

  const { data } = useQuery(
    gql`
      query Me {
        me {
          id
          roles
        }
      }
    `,
    { client: adminApolloClient }
  )

  const me = data?.me

  // const [visible, setVisible] = useState(false)
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setVisible(!!localStorage.getItem(MasqueradeUUID))
  //   }, 1111)
  //   return () => {
  //     clearInterval(interval)
  //   }
  // })

  return me ? (
    <div
      style={{
        display: hidden ? "none" : "flex",
      }}
    >
      <ApolloProvider client={adminApolloClient}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            userSelect: "none",
          }}
        >
          <Masquerade />
          <div style={{ display: "flex", opacity: 0.125 }}>
            <Eject />
            <ThemeButton />
          </div>
        </div>
      </ApolloProvider>
    </div>
  ) : (
    <div></div>
  )
}
