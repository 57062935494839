import { AnimatePresence, motion } from "framer-motion"
import React from "react"

export function Banner({ show, children, style, ...rest }: any) {
  return (
    <AnimatePresence>
      {show ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "1em",
            fontSize: "10vmin",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            ...(style || {}),
          }}
        >
          {children}
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
