import { gql, useQuery } from "@apollo/client"
import { sortBy } from "lodash"
import React from "react"
import Gate from "./Gate"

export default ({id}: any) => {
  const { data, loading } = useQuery(
    gql`
      query Facility($id: ID!) {
        facility(id: $id) {
          id
          name
          address
          organization {
            id
            name
          }
          gates {
            id
            gateDescription
            legacyResponseOverride
            gpioTargetLevel
            type
            rank
            ip
            plate
            signs {
              id
              mac
            }
          }
        }
      }
    `,
    {
      pollInterval: 1111,
      variables: { id },
    }
  )

  const gates = data?.facility?.gates || []

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          height: 1,
          background: loading ? "green" : undefined,
          transition: "all linear 0.5s",
        }}
      ></div>
      <div style={{ height: "100%", overflow: "auto", width: "100%" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "repeat(2, 1fr)",
          }}
        >
          {sortBy(gates, "rank").map((gate: any) => (
            <Gate
              {...gate}
              style={{
                pointerEvents:
                  gate.legacyResponseOverride !== false ? "none" : "all",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
