import { ClassAttributes, HTMLAttributes } from "react"
import styled from "@emotion/styled"

type ContainerProps = HTMLAttributes<HTMLDivElement> &
  ClassAttributes<HTMLDivElement> & {
    fixed?: boolean
  }

export const Container = styled("div")<ContainerProps>({}, () => ({
  margin: "0 auto",
  maxWidth: 960,
  width: "100%",
}))

// div`
//   width: 50vmin;
//   background: hsla(0, 0%, 100%, 0.05);
//   padding: 2em;
//   border-radius: 3px;
// `
