import React, { useMemo } from "react"
import Cell from "./Cell"
import moment from "./moment"

export default ({
  i,
  space,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  header,
  epoch,
  extent,
  children,
}: any) => {
  const cells = useMemo(() => {
    return Array.from({ length: extent }).map((_, i) => {
      const start = epoch.clone().add(i, "days")
      const range = moment.range(start, start.clone().endOf("day"))
      return (
        <Cell
          key={i}
          {...{
            header,
            onMouseDown,
            onMouseMove,
            onMouseUp,
            range,
            space,
          }}
        />
      )
    })
  }, [extent, epoch, space, header, onMouseDown, onMouseUp, onMouseMove])

  return (
    <div
      style={{
        display: "flex",
        height: 42,
        position: "relative",
        background: header
          ? "none"
          : i % 2
          ? "none"
          : "hsla(0, 0%, 50%, 0.075)",
        width: "100%",
      }}
    >
      {children}
      {cells}
    </div>
  )
}
