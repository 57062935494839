import React, { useCallback } from "react"
import CELL_WIDTH from "./cell_width"
import styled from "@emotion/styled"

const Styles = styled.div`
  background: ${(props: any) =>
    props.header ? props.theme.background : "none"};
  &:hover {
    background: hsla(0, 0%, 50%, 0.125);
  }
`

export default ({
  range,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  header,
  space,
}: any) => {
  const onMouseDownCallback = useCallback(() => onMouseDown?.(space, range), [
    space,
    range,
    onMouseDown,
  ])
  const onMouseMoveCallback = useCallback(() => onMouseMove?.(space, range), [
    space,
    range,
    onMouseMove,
  ])
  const onMouseUpCallback = useCallback(() => onMouseUp?.(space, range), [
    space,
    range,
    onMouseUp,
  ])
  return (
    <Styles
      style={{
        alignItems: "center",
        borderLeft: "1px dashed hsla(0, 0%, 50%, 0.125)",
        borderRight: "1px dashed hsla(0, 0%, 50%, 0.125)",
        display: "flex",
        justifyContent: "center",
        maxWidth: CELL_WIDTH,
        minWidth: CELL_WIDTH,
        overflow: "hidden",
        userSelect: "none",
        width: "100%",
        cursor: header || "col-resize",
      }}
      onMouseDown={onMouseDownCallback}
      onMouseMove={onMouseMoveCallback}
      onMouseUp={onMouseUpCallback}
    >
      {header && range.start.format("MMM. D")}
    </Styles>
  )
}
