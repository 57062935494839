import { Redirect } from "react-router"
import { useQuery } from "@apollo/client"
import React from "react"
import { gql } from "@apollo/client"
import Spinner from "../Spinner"
import Onboard from "../Onboard"

export default () => {
  const { loading, data } = useQuery(
    gql`
      query Me {
        me {
          id
          roles
          facility {
            id
          }
        }
      }
    `
  )

  if (loading || !data?.me) {
    return <Spinner />
  }

  return data?.me?.roles?.includes("OPERATOR") && data?.me?.facility?.id ? (
    <Redirect to={`/facilities/${data?.me?.facility?.id}`} />
  ) : data?.me?.roles?.includes("DEVELOPER") ? (
    <Redirect to={`/docs`} />
  ) : data?.me?.roles?.includes("ADMIN") ||
    data?.me?.roles?.includes("SUDO") ? (
    <Redirect to={`/facilities`} />
  ) : (
    <Onboard />
  )
}
