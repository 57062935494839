import { NavLink } from "react-router-dom"
import { useQuery } from "@apollo/client"
import React from "react"
import { gql } from "@apollo/client"
import moment from "moment"

export default (props: any) => {
  const { data } = useQuery(
    gql`
      query Event($id: ID!) {
        event(id: $id) {
          id
          plate
          gateType
          createdAt
          installation {
            id
            name
          }
        }
      }
    `,
    { variables: { id: props.id } }
  )

  const event = data?.event || props

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <NavLink to={`/events/${event.id}`}>{event.id.split("-")[0]}</NavLink>
      <NavLink to={`/plates/${event.plate}`}>
        <div
          style={{
            border: "1px solid green",
            borderRadius: 5,
            padding: "0.25em",
          }}
        >
          {event.plate}
        </div>
      </NavLink>
      <NavLink to={`/events/${event.id}`}>
        <div
          style={{
            border: "1px solid green",
            borderRadius: 5,
            padding: "0.25em",
          }}
        >
          {event.gateType}
        </div>
      </NavLink>
      <NavLink to={`/facilities/${event.installation?.id}`}>
        <div
          style={{
            border: "1px solid green",
            borderRadius: 5,
            padding: "0.25em",
          }}
        >
          {event.installation?.name}
        </div>
      </NavLink>
      <div>{moment(parseInt(event.createdAt, 10)).fromNow()}</div>
    </div>
  )
}
