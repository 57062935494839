import styled from "@emotion/styled"

export const Select = styled.select`
  font-size: 16px;
  border-radius: 3px;
  padding: 1em;
  display: block;
  color: ${(props: any) => props.theme.color};
  background: hsla(0, 0%, 50%, 0.125);
  margin: 0.25rem 0;
  width: 100%;
  height: 42px;
  line-height: 42px;
  border: 1px solid hsl(0, 0%, 50%);
`
