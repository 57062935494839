import { ClassAttributes, HTMLAttributes } from "react"
import styled from "@emotion/styled"

const VARIANTS: any = {
  disabled: {
    cursor: "not-allowed",
    filter: "saturate(0)",
    opacity: 0.5,
  },
  negative: {
    borderColor: "#db2828",
    color: "#db2828",
  },
  positive: {
    borderColor: "#21ba45",
    color: "#21ba45",
  },
}

const collectVariants = (props: any): any =>
  Object.entries(props)
    .filter(([_k, v]) => v !== false)
    .reduce(
      (p, [key]) => ({
        ...p,
        ...(VARIANTS[key] || {}),
      }),
      {}
    )

type ButtonProps = HTMLAttributes<HTMLButtonElement> &
  ClassAttributes<HTMLButtonElement> & {
    positive?: boolean
    negative?: boolean
    disabled?: boolean
  }

const Button = styled("button")<ButtonProps>({}, props => ({
  "&:focus": {
    outline: "none",
  },
  "&:hover": {
    ...((props.disabled ? {} : { filter: "brightness(90%)" }) as any),
  },
  alignItems: "center",
  backgroundColor: "transparent",
  borderColor: "hsl(0, 0%, 66%)",
  borderRadius: 3,
  borderStyle: "solid",
  borderWidth: 1,
  color: "hsl(0, 0%, 66%)",
  cursor: "pointer",
  display: "flex",
  fontSize: 14,
  fontWeight: "bolder",
  justifyContent: "center",
  padding: "1em",
  textTransform: "uppercase",
  ...(collectVariants(props) as any),
}))

export { Button }
