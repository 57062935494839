import React, { useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import Sign from "."
import Debugger from "./Debugger"
import transitions from "./transitions"
import useSignData, { getScreenFromSign } from "./useSignData"
import { randomHello, randomPlate, randomThanks } from "./util"



export function DebuggableSign(props: any) {
  const sign = useSignData(props)

  const [notifyBackend] = useMutation(gql`
        mutation ReportToBackend($signId: ID!, $screen: String!, $plate: String, $amount: Int) {
            reportChargeMessageReceivedOnKiosk(signId: $signId, screen: $screen, plate: $plate, amount: $amount)
        }
    `);

  const [demo, setDemo] = useState(undefined as any)
  const [screen, setScreen] = useState(undefined as any)
  const [thanks, setThanks] = useState(undefined as any)
  const [hello, setHello] = useState(undefined as any)
  const [plate, setPlate] = useState(undefined as any)
  const [int, setInt] = useState(undefined as any)

  useEffect(() => {
    clearInterval(int)
    if (demo) {
      const i = setInterval(() => {
        if (demo) {
          setScreen((screen: any) => transitions[screen])
          setThanks(() => randomThanks())
          setHello(() => randomHello())
          setPlate(() => randomPlate())
        }
      }, demo)
      setInt(i)
    } else {
    }
  }, [demo])

  const common = {
    sign,
    setScreen,
    demo,
    amount: Number(sign?.state?.amount),
    plate: plate || sign?.state?.plate,
    signId: sign?.id,
    hello,
    thanks,
    screen: demo ? screen : getScreenFromSign(sign),
  }

  if (!demo && screen != common.screen) {
    setScreen(common.screen);
    console.log(screen, common.screen);
    const variables = {
      amount: common.amount,
      plate: common.plate,
      screen: common.screen,
      signId: common.signId,
    };

    try {
      if (screen) notifyBackend({ variables });
    } catch (e) {
      console.error("[notifyBackend] error: ", e);
    }
  }

  return props.debug ? (
    <Debugger {...{ setDemo, demo, setScreen, screen }}>
      <Sign {...props} {...common} />
    </Debugger>
  ) : (
    <Sign {...props} {...common} />
  )
}
