import React from "react"
import { Formik, Form, Field } from "formik"
import { Button, Label, Input, Container } from "../components"
import { useAuth } from "../Auth"
import { Link } from "react-router-dom"

export default () => {
  const auth = useAuth()
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Formik
          initialValues={{}}
          onSubmit={async (input, actions) => {
            try {
              await auth.join({ input })
            } catch (e) {
              actions.setStatus(false)
            }
          }}
        >
          {(props) => (
            <Form>
              {props.status === false && (
                <div
                  style={{
                    border: "1px solid hsla(0, 50%, 50%, 0.25)",
                    borderRadius: 3,
                    padding: "1em",
                  }}
                >
                  <p>We had trouble creating a new account for you.</p>
                  <p>An account may already exist with this email.</p>
                  <p>
                    Try <Link to="/login">signing in</Link> or{" "}
                    <Link to="/recover">recovering your account</Link> instead.
                  </p>
                </div>
              )}
              <>
                <Label>Full name</Label>
                <Field as={Input} name="name" required pattern=".+ .+" />
              </>
              <>
                <Label>Email</Label>
                <Field as={Input} type="email" name="email" required />
              </>
              <>
                <Label>Password</Label>
                <Field
                  as={Input}
                  type="password"
                  name="password"
                  required
                  autoComplete="new-password"
                />
              </>
              <Button
                type="submit"
                style={{
                  width: "100%",
                  color: "#32AB40",
                  borderColor: "#32AB40",
                  margin: "2em 0",
                }}
              >
                Continue
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  )
}
