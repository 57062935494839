import { Container, Header } from "../components"
import { Formik, FormikHelpers } from "formik"
import { useHistory } from "react-router"
import { useMutation, useQuery } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import DeleteRequest from "./DeleteRequest"
import React from "react"
import RequestForm, { FormValues } from "../RequestForm"
import Spinner from "../Spinner"
import { gql } from "@apollo/client"

export default ({ id }: any) => {
  const history = useHistory()
  const { addToast } = useToasts()

  const [updateRequest] = useMutation(gql`
    mutation UpdateRequest($id: ID!, $input: UpdateRequestInput!) {
      updateRequest(id: $id, input: $input) {
        id
      }
    }
  `)

  const { data } = useQuery(
    gql`
      query Request($id: ID!) {
        request(id: $id) {
          id
          hi
          lo
          membership {
            id
          }
          tenancy {
            id
            facility {
              id
              name
              address
            }
          }
        }
      }
    `,
    { variables: { id } }
  )
  const request = data?.request

  return request ? (
    <Container fixed>
      <Header>
        <h3>Update your request</h3>
        <DeleteRequest {...{ id }} />
      </Header>

      <Formik
        initialValues={{
          hi: request?.hi,
          lo: request?.lo,
          membership: request.membership?.id,
          tenancy: request.tenancy?.id,
        }}
        onSubmit={async (values: any, _actions: FormikHelpers<FormValues>) => {
          await updateRequest({
            variables: {
              id,
              input: {
                ...values,
              },
            },
          })
          addToast("Request updated!", {
            appearance: "success",
          })
          history.back()
        }}
      >
        {(props: any) => <RequestForm {...props} {...{ request }} />}
      </Formik>
    </Container>
  ) : (
    <Spinner />
  )
}
