import { Machine, assign } from "xstate"
import { useMachine } from "@xstate/react"
import { useApolloClient, gql } from "@apollo/client"
import { useToasts } from "react-toast-notifications"

const useTimelineMachine = ({ facilityId, refetch }: any) => {

  const apollo = useApolloClient()
  const { addToast } = useToasts()

  return useMachine(timelineMachine, {
    services: {
      loadReservation: async (context: any, event) => {
        const id = context.selectedReservation?.id
        if (id == null) {
          throw new Error("ID is required for selectedReservation")
        }

        const { data } = await apollo.query({
          query: gql`
          query Reservation($id: ID!) {
            reservation(id: $id) {
              id
              plate
              lo
              hi
              space {
                id
                label
              }
            }
          }
        `,
          variables: { id },
        })

        return data
      },
      updateReservation: async (_context, event: any) => {
        const { id, ...input } = event.reservation

        const { data } = await apollo.mutate({
          mutation: gql`
          mutation UpdateReservation(
            $id: ID!
            $input: UpdateReservationInput!
          ) {
            updateReservation(id: $id, input: $input) {
              id
            }
          }
        `,
          variables: { id, input },
          refetchQueries: ["Data"],
        })

        addToast("Reservation updated!", {
          appearance: "success",
        })

        return data
      },
      createReservation: async (_context, event: any) => {
        const { ...input } = event.reservation

        const data = await apollo.mutate({
          mutation: gql`
          mutation CreateReservation($input: CreateReservationInput!) {
            createReservation(input: $input) {
              id
            }
          }
        `,
          variables: { input },
          refetchQueries: ["Data"],
        })

        addToast("Reservation created!", {
          appearance: "success",
        })

        return data
      },
      updateSpace: async (_, event: any) => {
        if (event?.space?.label == null || event?.space?.label.length <= 0) {
          return null
        }
        const { id, ...input } = event.space

        // apollo.writeFragment({
        //   id,
        //   fragment: gql`
        //     fragment mySpace on Space {
        //       label
        //     }
        //   `,
        //   data: input,
        // });

        const data = await apollo.mutate({
          mutation: gql`
          mutation UpdateSpace($id: ID!, $input: UpdateSpaceInput!) {
            updateSpace(id: $id, input: $input) {
              id
              label
            }
          }
        `,
          refetchQueries: ["Data"],
          variables: { id, input },
          update: (store, { data: { updateSpace } }) => {
            const query = gql`
            query Data($id: ID!) {
              facility(id: $id) {
                id
                spaces {
                  id
                  label
                  reservations {
                    id
                    lo
                    hi
                    plate
                  }
                }
              }
            }
          `
            const data: any = store.readQuery({
              query, variables: { id: facilityId }
            });
            // TODO: Fix error - attempted to assign readonly property
            data.facility.spaces.find((space: any) => space.id === id).label = input.label
            store.writeQuery({ query, data });
          }
        })

        addToast("Space updated!", {
          appearance: "success",
        })

        return data
      },
      createSpace: async (_, event: any) => {
        if (event?.space?.label == null || event?.space?.label.length <= 0) {
          return null
        }

        const data = await apollo.mutate({
          mutation: gql`
            mutation CreateSpace($input: CreateSpaceInput!) {
              createSpace(input: $input) {
                id
              }
            }
          `,
          refetchQueries: ["Data"],
          variables: {
            input: {
              facility: facilityId,
              label: event?.space?.label,
            },
          },
        })

        addToast("Space created!", {
          appearance: "success",
        })

        return data
      },
      removeSpace: async (context, event) => {
        const id = event?.space?.id
        const data = await apollo.mutate({
          mutation: gql`
            mutation DeleteSpace($id: ID!) {
              deleteSpace(id: $id)
            }
          `,
          refetchQueries: ["Data"],
          variables: {
            id
          },
        })

        addToast("Space deleted!", {
          appearance: "warning",
        })

        return data
      }
      // persistSelectedReservation: async (context, _event: any) => {
      //   // Will be needed for moving and extending
      //   // Differs from updateReservation as this pulls data from context.selectedReservation
      //   // as opposed to receiving via event like when formik is reponsible
      //   const { id, ...input } = context.selectedReservation

      //   const { data } = await apollo.mutate({
      //     mutation: gql`
      //       mutation UpdateReservation(
      //         $id: ID!
      //         $input: UpdateReservationInput!
      //       ) {
      //         updateReservation(id: $id, input: $input) {
      //           id
      //         }
      //       }
      //     `,
      //     variables: { id, input },
      //     refetchQueries: ["Data"],
      //   })

      //   addToast("Reservation updated!", {
      //     appearance: "success",
      //   })

      //   return data
      // },
    },
    actions: {
      removeSpace: async (context, event) => {
        const id = event?.space?.id
        const data = await apollo.mutate({
          mutation: gql`
            mutation DeleteSpace($id: ID!) {
              deleteSpace(id: $id)
            }
          `,
          refetchQueries: ["Data"],
          variables: {
            id
          },
        })

        addToast("Space deleted!", {
          appearance: "warning",
        })

        return data
      },
      handleLoadingError: (context, event: any) =>
        addToast(`Loading Failed: ${event.error.message}`, {
          appearance: "error",
        }),
      startSelectedReservation: assign({
        selectedReservation: (_context, event: any) => event.reservation,
      }),
      assignSelectedReservationRangeEnd: assign({
        selectedReservation: (context, event: any) => {
          const { selectedReservation } = context as any
          const end = event.range.end.clone()

          selectedReservation.range.end = end
          return selectedReservation
        },
      }),
      resetSelectedReservation: assign({
        selectedReservation: (context, event: any) => undefined,
      }),
      assignSelectedReservation: assign({
        selectedReservation: (_context, event: any) => event.reservation,
      }),
      assignSelectedReservationResponse: assign({
        selectedReservation: (_context, event: any) => event.data.reservation,
      }),
      assignSpaces: assign({
        spaces: (_, event) => event.spaces,
      }),
      refetchData: (_context, _event) => {
        refetch()
      },
      handleAdminError: (_, event) => {
        addToast(event?.error?.message || "Error updating spaces", { appearance: "error" })
      },
    },
    guards: {
      isTimeRangeStartAvailable: (context, event) => {
        const spaces = context.spaces || []

        const proposedRange = context.selectedReservation.range.clone()
        proposedRange.start = event.range.start
        for (let i = 0; i < spaces.length; i++) {
          const space = spaces[i]
          if (space.id === context.selectedReservation.space.id) {
            for (let j = 0; j < space.reservations.length; j++) {
              const reservation = space.reservations[j]
              if (reservation.range.overlaps(proposedRange)) {
                return false
              }
            }
          }
        }
        return true
      },
      isTimeRangeEndAvailable: (context, event) => {
        const spaces = context.spaces || []

        const proposedRange = context.selectedReservation.range.clone()
        proposedRange.end = event.range.end
        for (let i = 0; i < spaces.length; i++) {
          const space = spaces[i]
          if (space.id === context.selectedReservation.space.id) {
            for (let j = 0; j < space.reservations.length; j++) {
              const reservation = space.reservations[j]
              if (reservation.range.overlaps(proposedRange)) {
                return false
              }
            }
          }
        }
        return true
      },
      isTimeRangeAvailable: (context, event) => {
        return false
      },
    },
  })
}

export default useTimelineMachine

export const timelineMachine = Machine<any, any, any>(
  {
    id: "timeline",
    initial: "idle",
    context: {},
    on: {
      "UPDATE_SPACES": {
        actions: "assignSpaces"
      },
      "ADMINISTER": "admin"
    },
    states: {
      admin: {
        initial: "idle",
        states: {
          autoAdd: {
            on: {
              "": {
                target: "adding", actions: (context, event) => {
                  console.log({
                    value:
                      context.newLabelInputRef.current.value
                  });

                  context.newLabelInputRef.current.focus()
                  context.newLabelInputRef.current.value = ""
                }
              }
            }
          },
          idle: {
            on: {
              "ADD": "adding",
              "REMOVE": { actions: "removeSpace" },
              "UPDATE": "updating",
              "COMPLETE": {
                target: "#timeline.idle"
              }
            },
          },
          adding: {
            initial: "editing",
            exit: assign({ addAfterSubmitFlag: false }),
            on: {
              "SET_INPUT_REF": {
                actions: assign({ newLabelInputRef: (context, event) => event.newLabelInputRef })
              }
            },
            states: {
              editing: {
                on: {
                  "SUBMIT": "submitted",
                },
              },
              submitted: {
                on: {
                  "ADD": {
                    actions: assign({
                      addAfterSubmitFlag: true
                    }),
                  }
                },
                invoke: {
                  id: "createSpace",
                  src: "createSpace",
                  onDone: [{
                    cond: (context, event) => context.addAfterSubmitFlag,
                    target: "#timeline.admin.autoAdd"
                  }, {
                    target: "#timeline.admin.idle"

                  }],
                  onError: {
                    target: "#timeline.admin.idle",
                    actions: "handleAdminError"
                  }
                }
              }
            }
          },
          updating: {
            initial: "editing",
            exit: assign({ addAfterSubmitFlag: false }),
            states: {
              editing: {
                on: {
                  "SUBMIT": "submitted",
                  "ADD": {
                    actions: assign({
                      addAfterSubmitFlag: true
                    }),
                  }
                }
              },
              submitted: {
                invoke: {
                  id: "updateSpace",
                  src: "updateSpace",
                  onDone: [{
                    cond: (context, event) => context.addAfterSubmitFlag,
                    target: "#timeline.admin.autoAdd"
                  }, {
                    target: "#timeline.admin.idle"

                  }],
                  onError: {
                    target: "#timeline.admin.idle",
                    actions: [
                      "handleAdminError",
                      //  "refetchData"
                    ]
                  }
                }
              }
            }
          },
        }
      },
      draft: {
        initial: "editing",
        states: {
          editing: {
            on: {
              "MOUSE_DOWN.ROW": "#timeline.outlining",
              "MOUSE_DOWN.RESERVATION": "#timeline.moving",
              "MOUSE_DOWN.EDGE": "#timeline.extending",
              CANCEL: {
                actions: "resetSelectedReservation",
                target: "#timeline.idle",
              },
              SUBMIT: "#timeline.draft.submitted",
            },
          },
          submitted: {
            on: { "ADMINISTER": undefined },
            invoke: {
              id: "createReservation",
              onDone: "#timeline.idle",
              onError: "editing",
              src: "createReservation",
            },
          },
        },
      },
      extending: {
        entry: "assignSelectedReservation",
        on: {
          MOUSE_MOVE: {
            actions: "updateSelectedReservation",
            cond: "isTimeRangeAvailable",
          },
          MOUSE_UP: {
            actions: "persistSelectedReservation",
            target: "idle",
          },
        },
      },
      idle: {
        on: {
          "MOUSE_DOWN.ROW": "outlining",
          "MOUSE_DOWN.RESERVATION": "moving",
          "MOUSE_DOWN.EDGE": "extending",
        },
      },
      moving: {
        initial: "waiting",
        entry: "assignSelectedReservation",
        on: {
          MOUSE_MOVE: {
            actions: "updateSelectedReservationOffset",
            cond: "isTimeRangeAvailable",
          },
          MOUSE_UP: [
            {
              actions: "persistSelectedReservation",
              cond: "isTimeRangeAvailable",
              target: "#timeline.idle",
            },
            {
              target: "#timeline.idle",
            },
          ],
        },
        states: {
          waiting: {
            on: {
              "MOUSE_UP": {
                target: "#timeline.selected"
              }
            },
            after: {
              300: "active"
            }
          },
          active: {}
        }

      },
      outlining: {
        entry: "startSelectedReservation",
        on: {
          MOUSE_MOVE: {
            actions: "assignSelectedReservationRangeEnd",
            cond: "isTimeRangeEndAvailable",
          },
          MOUSE_UP: {
            target: "draft",
          },
        },
      },
      selected: {
        initial: "loading",
        on: {
          "MOUSE_DOWN.ROW": "outlining",
          "MOUSE_DOWN.RESERVATION": "moving",
          "MOUSE_DOWN.EDGE": "extending",
        },
        states: {
          editing: {
            on: {
              CANCEL: {
                actions: "resetSelectedReservation",
                target: "#timeline.idle",
              },
              SUBMIT: "#timeline.selected.submitted"
            },
          },
          loading: {
            invoke: {
              id: "loadReservation",
              onDone: {
                target: "editing",
                actions: "assignSelectedReservationResponse"
              },
              onError: {
                actions: "handleLoadingError",
                target: "#timeline.idle",
              },
              src: "loadReservation",
            },
          },
          submitted: {
            on: {
              "ADMINISTER": undefined,
              "MOUSE_DOWN.ROW": undefined,
              "MOUSE_DOWN.RESERVATION": undefined,
              "MOUSE_DOWN.EDGE": undefined,
            },
            invoke: {
              id: "updateReservation",
              onDone: "#timeline.idle",
              onError: "editing",
              src: "updateReservation",
            },
          },
        },
      },
    },
  }
)
