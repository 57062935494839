import { Link } from "react-router-dom"
import { useQuery } from "@apollo/client"
import React from "react"
import Spinner from "../Spinner"
import { gql } from "@apollo/client"
import moment from "moment"

const Plate = ({ id }: any) => {
  const { data } = useQuery(
    gql`
      query Plate($id: ID!) {
        plate(id: $id) {
          id
          bookings {
            id
            href
          }
          events {
            id
            createdAt
            gateType
          }
        }
      }
    `,
    { variables: { id } }
  )

  const plate = data?.plate || { id }

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div>{plate.id}</div>
      {plate.bookings && (
        <div style={{ display: "flex" }}>
          {plate.bookings.map((booking: any) => (
            <a href={booking.href}>{booking.id}</a>
          ))}
        </div>
      )}
      {plate.events && (
        <div style={{ display: "flex" }}>
          {plate.events.map((event: any) => (
            <Link
              to={`/events/${event.id}`}
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: 2,
              }}
            >
              <div>{event.gateType}</div>
              <div style={{ fontSize: "8px" }}>
                {moment(parseInt(event.createdAt, 10)).fromNow(true)}
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default () => {
  const { data } = useQuery(
    gql`
      query Plates {
        plates {
          id
        }
      }
    `
  )

  return data?.plates ? (
    <div>
      {data?.plates.map((plate: any) => (
        <Plate id={plate.id} />
      ))}
    </div>
  ) : (
    <Spinner />
  )
}
