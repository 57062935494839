import React, { useCallback, useEffect, useState } from "react"

import * as THREE from "./three.module"
import { APP } from "./APP"

let velocity = 1 / 60
let theta = 1

export function Scene() {
  const [loaded, setLoaded] = useState(false)

  const [ref, setRef] = useState(null as any)
  const onRefSet = useCallback(
    (ref) => {
      setRef(ref)
      const parent = ref?.current || ref
      if (!loaded && parent) {
        setLoaded(true)
        var loader = new (THREE.FileLoader as any)()
        loader.load("app.json", (text: any) => {
          // @ts-ignore
          var player = new APP.Player(THREE)
          const scene = player.load(JSON.parse(text))
          ;(window as any).scene = scene

          const anim = (id: string, { rotationY }: any = {}) => {
            const o = scene.children.find(({ uuid }: any) => uuid === id)
            setInterval(() => {
              o.translateX(-1)
              theta += velocity
              if (theta > Math.PI / 2 || theta < 0) {
                velocity *= -1
              }
              o.setRotationFromEuler(
                new THREE.Euler(0, rotationY, theta, "XYZ")
              )
              o.translateX(1)
            }, 1000 / 60)
          }

          anim("E7930ABD-4A2B-4A1B-983D-1F6AB2091AA8")
          anim("D74915F1-FFEB-4022-9D47-2D5DF4668005", {
            rotationY: Math.PI,
          })

          player.setSize(window.innerWidth, window.innerHeight)
          player.play()
          parent.appendChild(player.dom)
          window.addEventListener("resize", function () {
            player.setSize(window.innerWidth, window.innerHeight)
          })
        })
      }
    },
    [ref, loaded, setLoaded]
  )

  return <div ref={onRefSet}>Scene</div>
}
