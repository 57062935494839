import { Button } from "../components"
import { useHistory } from "react-router"
import React from "react"

export default () => {
  const history = useHistory()

  return (
    <Button
      onClick={(event) => {
        event.preventDefault()
        history.back()
      }}
    >
      Cancel
    </Button>
  )
}
