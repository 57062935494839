import styled from "@emotion/styled"

export default styled("div")`
  background: ${(props: any) => props.theme.background};
  width: 100vw;
  height: 100%;
  color: ${(props: any) => props.theme.color};
  h1 {
    color: ${(props: any) => props.theme.color};
  }
`
