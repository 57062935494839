import { gql, useQuery, useSubscription } from "@apollo/client"
import { sortBy } from "lodash"
import { DateTime } from "luxon"
import React from "react"
import "./index.css"
import { Lane } from "./Lane"

export default ({ id, compact }: { id: string; compact?: boolean }) => {
  const { data: queryData } = useQuery(
    gql`
      query FacilityEdgeSnapshot($id: ID!) {
        facility(id: $id) {
          id
          gates {
            id
            ip
            rank
          }
          edgeSnapshot {
            id
            data
          }
        }
      }
    `,
    {
      variables: { id },
      pollInterval: 11111,
    }
  )

  const { data: subscriptionData } = useSubscription(
    gql`
      subscription EdgeSnapshotCreated($id: ID!) {
        edgeSnapshotCreated(facility: $id) {
          id
          data
          isDefined
        }
      }
    `,
    { variables: { id } }
  )

  const facility = queryData?.facility
  const snapshot = subscriptionData?.edgeSnapshot || facility?.edgeSnapshot
  

  const lanes = snapshot?.data?.lanes || []
  const carPark = snapshot?.data?.carPark || {}

  const getState = (lane: string) => {
    return lanes.find(({ id }: any) => id === lane)
  }

  const probableOccupants = Object.fromEntries(
    Object.entries(carPark?.context?.occupants || {}).filter(
      ([_, iso]: any) => DateTime.fromISO(iso).diffNow().as("days") > -2
    )
  )
  const staleOccupants = Object.fromEntries(
    Object.entries(carPark?.context?.occupants || {}).filter(
      ([_, iso]: any) => DateTime.fromISO(iso).diffNow().as("days") < -2
    )
  )

  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      {/* <Toolbar {...{ probableOccupants, staleOccupants }} /> */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
        }}
      >
        {sortBy(facility?.gates || [], "rank").map((gate: any) => (
          <Lane id={gate.id} state={getState(gate.id)} {...{ compact }} />
        ))}
      </div>
    </div>
  )
}
