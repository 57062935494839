// import { Button } from "../../components"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { Link } from "react-router-dom"
// import { faTrash } from "@fortawesome/free-solid-svg-icons"
// import { useMutation } from "@apollo/client"
import React from "react"
// import { gql } from "@apollo/client"
// import moment from "moment"

export default [
  {
    Cell: ({ cell: { value } }: any) => <div>{value?.name || value?.id}</div>,
    Header: "Organization",
    accessor: "organization",
  },
  {
    Cell: ({ cell: { value } }: any) => <div>{value?.length}</div>,
    Header: "Spaces",
    accessor: "spaces",
  },
  // {
  //   Header: "Actions",
  //   accessor: (v: any) => v,
  //   Cell: ({ cell: { value: observation } }: any) => (
  //     <DeleteAction id={observation.id} />
  //   )
  // }
]
