import React from "react"
import styled from "@emotion/styled"
import { Button } from "../components"

const Styles = styled.div`
  code {
    margin: 1em 0;
    background: hsl(0, 0%, 50%, 0.075);
    padding: 1em;
    display: block;
  }
`

const EnvButton = styled(Button)`
  border: none;
  background: ${(props: any) =>
    props.env === props.id ? "hsl(127, 55%, 43%)" : "none"};
  color: ${(props: any) =>
    props.env === props.id ? "white" : props.theme.color};
`

export default ({ token, env, setEnv, apollo }: any) => (
  <Styles>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h1>Drivo API</h1>
      <div style={{ display: "flex" }}>
        <EnvButton
          onClick={() => {
            setEnv("development")
            apollo.resetStore()
          }}
          {...{ env, id: "development" }}
        >
          Test
        </EnvButton>
        <EnvButton
          onClick={() => {
            setEnv("production")
            apollo.resetStore()
          }}
          {...{ env, id: "production" }}
        >
          Live
        </EnvButton>
      </div>
    </div>

    <h2>Endpoint</h2>
    <p>The Drivo API has a single endpoint:</p>
    <code>
      <a href="https://api.hotelparking.com.au/graphql">https://api.hotelparking.com.au/graphql</a>
    </code>
    <p>
      A GraphQL Playground is available at{" "}
      <a href="https://api.hotelparking.com.au/graphql">https://api.hotelparking.com.au/graphql</a>
    </p>

    <h2>Authentication</h2>
    <p>
      Requests are protected via{" "}
      <a href="https://tools.ietf.org/html/rfc6750">bearer tokens</a>:
      <code>Authorization: Bearer {token}</code>
    </p>
    {/* <h3>Further reading</h3>
    <ul>
      <li>
        <a href="https://developer.github.com/v4/guides/intro-to-graphql/">
          Introduction to GraphQL (github.com)
        </a>
      </li>
    </ul> */}
  </Styles>
)
