import { gql, useQuery } from "@apollo/client"
import { orderBy } from "lodash"
import moment from "moment"
import React, { useCallback, useEffect, useRef, useState } from "react"

const useData = ({ id, lo, hi }: any) => {
  const { data } = useQuery(
    gql`
      query Observations($id: ID!, $lo: String, $hi: String) {
        gate(id: $id) {
          id
          observations(lo: $lo, hi: $hi) {
            id
            plate
            createdAt
          }
          transitions(lo: $lo, hi: $hi) {
            id
            value
            createdAt
          }
          signs {
            id
            transitions(lo: $lo, hi: $hi) {
              id
              state
              createdAt
            }
          }
        }
      }
    `,
    {
      variables: {
        id,
        lo,
        hi,
      },
      fetchPolicy: "no-cache",
      skip: !id,
    }
  )
  return {
    observations: data?.gate?.observations || [],
    transitions: data?.gate?.transitions || [],
    signs: data?.gate?.signs || [],
  }
}

export default ({ id }: any) => {
  const { data } = useQuery(
    gql`
      query Transaction($id: ID!) {
        gateTransaction(id: $id) {
          id
          plate
          createdAt
          closedAt
          videoUrl
          gate {
            id
          }
        }
      }
    `,
    {
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    }
  )

  const transaction = data?.gateTransaction

  const lo = transaction
    ? moment(transaction.createdAt).subtract(10, "seconds")
    : moment()

  const hi = transaction
    ? moment(transaction.closedAt).add(30, "seconds")
    : moment()

  const { signs, observations, transitions } = useData({
    id: transaction?.gate?.id,
    lo,
    hi,
  })

  const { videoUrl } = transaction || {}

  const [t, setT] = useState<any>(null)
  const [currentTime, setCurrentTime] = useState<any>(0)
  useEffect(() => {
    setInterval(() => {
      setCurrentTime(ref?.current?.currentTime)
    }, 1000 / 60)
  }, [])

  const ref = useRef<any>(null)

  const now = moment(transaction?.createdAt)
    .subtract(10, "seconds")
    .add(currentTime || 0, "seconds")

  const signTransitions = data?.gate?.signs?.[0]?.transitions || []

  const state = signTransitions.find(({ createdAt }: any) => {
    moment(createdAt).isAfter(now)
  })

  return (
    <div style={{ height: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        {JSON.stringify(state)}
      </div>
      <div style={{ display: "flex", height: "100%" }}>
        {videoUrl ? (
          <div
            style={{
              position: "relative",
              width: "100%",
            }}
          >
            <video
              ref={ref}
              style={{ width: "75vmin" }}
              controls
              src={videoUrl}
              muted
              loop
              autoPlay
            ></video>
            <div
              style={{
                pointerEvents: "none",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%", textAlign: "center" }}>
                {currentTime
                  ? currentTime < 10
                    ? `T-${(10 - currentTime).toFixed(1)}`
                    : `T+${(currentTime - 10).toFixed(1)}`
                  : null}
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                {transaction?.createdAt && currentTime
                  ? now.format("HH:mm:ss:SSS")
                  : null}
              </div>
            </div>
          </div>
        ) : null}
        <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
          {orderBy(
            [
              ...observations,
              ...transitions,
              ...(signs?.[0]?.transitions || []).map(
                ({ state, ...rest }: any) => ({
                  ...rest,
                  children: (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gridGap: "1em",
                      }}
                    >
                      <div>sign</div>
                      <div>
                        {state?.salutation
                          ? state?.salutation
                          : JSON.stringify(state)}
                      </div>
                    </div>
                  ),
                })
              ),
            ],
            ["createdAt"],
            ["desc"]
          ).map(({ value, plate, createdAt, children }: any) => {
            const diff = moment(createdAt).diff(
              moment(transaction.createdAt),
              "seconds",
              true
            )
            return (
              <div
                style={{
                  display: "flex",
                  opacity: moment(createdAt).isAfter(transaction?.closedAt)
                    ? 0.5
                    : 1,
                  width: "100%",
                }}
                onMouseOver={() => {
                  if (ref?.current) {
                    ref.current.currentTime = moment(createdAt).diff(
                      moment(transaction.createdAt).subtract(10, "seconds"),
                      "seconds"
                    )
                  }
                }}
              >
                <div style={{ width: "100%" }}>
                  {children
                    ? children
                    : [0, 1].includes(value)
                    ? value
                      ? "GL HI"
                      : "GL LO"
                    : plate}
                </div>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  T+
                  {diff.toFixed(3)}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
