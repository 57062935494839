import { Container, Header } from "../components"
import { Formik, FormikHelpers } from "formik"
import { useHistory } from "react-router"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import React from "react"
import RequestForm, { FormValues } from "../RequestForm"
import { gql } from "@apollo/client"

export default ({ tenancyId, membershipId, lo, hi }: any) => {
  const history = useHistory()
  const { addToast } = useToasts()

  const [requestSpace] = useMutation(gql`
    mutation RequestSpace($input: RequestSpaceInput!) {
      requestSpace(input: $input) {
        id
      }
    }
  `)

  return (
    <Container fixed>
      <Header>Request a parking space</Header>
      <Formik
        initialValues={{
          hi,
          lo,
          membership: membershipId,
          tenancy: tenancyId,
        }}
        onSubmit={async (values: any, _actions: FormikHelpers<FormValues>) => {
          await requestSpace({
            refetchQueries: ["Assignments"],
            variables: {
              input: {
                ...values,
              },
            },
          })
          addToast("Request created!", {
            appearance: "success",
          })
          history.push("/")
        }}
      >
        {(props: any) => <RequestForm {...props} />}
      </Formik>
    </Container>
  )
}
