import { NavLink } from "react-router-dom"
import { useAuthContext } from "../Auth/Context"
import ExpandMenu from "./ExpandMenu"
import Logo from "../Logo"
import React from "react"
import styled from "@emotion/styled"

const Link = styled(NavLink)`
  margin: 0 1em;
`

export default () => {
  const me: any = useAuthContext()
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        padding: "0.5em",
        userSelect: "none",
        width: "100%",
      }}
    >
      <NavLink to="/">
        <Logo />
      </NavLink>
      {me?.roles?.includes("DEVELOPER") ? (
        <>
          <Link to="/applications">Apps</Link>
          <Link to="/docs">Docs</Link>
        </>
      ) : null}
      {me?.roles?.includes("ADMIN") && (
        <>
          <Link to="/facilities">Facilities</Link>
          <Link to="/users">Users</Link>
          <Link to="/organizations">Organizations</Link>
        </>
      )}
      {false && (
        <>
          <Link to="/events">Events</Link>
          <Link to="/plates">Plates</Link>
          <Link to="/composites">Composites</Link>
        </>
      )}
      {!me?.roles?.includes("ADMIN") && (
        <a href="mailto:Nick@drivo.com.au">Support</a>
      )}
      {me ? (
        <ExpandMenu user={me} />
      ) : (
        <div>
          <Link to="/join">Join</Link>
          <Link to="/signin">Sign In</Link>
        </div>
      )}
    </div>
  )
}
