const white = "#FFFFFF"
const black = "#161617"
const gray = "#F8F8F9"
const primary = "#32AB40"

const themeLight = {
  background: gray,
  color: black,
  primary,
}

const themeDark = {
  background: black,
  color: white,
  primary,
}

const theme = (mode: any) => (mode === "dark" ? themeDark : themeLight)

export default theme
