import { useQuery } from "@apollo/client"
import COLUMNS from "./columns"
import React from "react"
import Table from "../../Table"
import { gql } from "@apollo/client"
import Spinner from "../../Spinner"

export default ({ facility }: any) => {
  const { data } = useQuery(
    gql`
      query Spaces($facilityId: ID!) {
        facility(id: $facilityId) {
          id
          tenancies {
            id
            organization {
              id
              name
            }
            spaces {
              id
            }
          }
        }
      }
    `,
    { skip: !facility?.id, variables: { facilityId: facility?.id } }
  )

  const tenancies = data?.facility?.tenancies

  return tenancies ? (
    <Table
      data={tenancies}
      columns={COLUMNS}
      initialState={{ sortBy: [{ desc: true, id: "time" }] }}
    />
  ) : (
    <Spinner />
  )
}
