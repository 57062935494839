import { useQuery, gql } from "@apollo/client"
import React from "react"
import Table from "../Table"
import COLUMNS from "./columns"
import styled from "@emotion/styled"

const Styles = styled.div`
  & {
    label {
      color: hsl(127, 55%, 43%);
    }
  }
`

export default (props: any) => {
  const { data } = useQuery(
    gql`
      query RateCard($id: ID!) {
        rateCard(id: $id) {
          id
          name
          bookings
          bookingRateAudPerDay
          bookingRateAudPerHour
          serviceFeePercent
          version
          hoursUntilExpiry
          maximum24HourAud
          isArchived
          defaultOnExit
          defaultPublic
          paymentGate
          type
        }
      }
    `,
    { variables: { id: props.id }, pollInterval: 1337, skip: !props.id }
  )

  const rateCard = data?.rateCard || {}

  return (
    <Styles>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {[
          "type",
          "bookingRateAudPerDay",
          "bookingRateAudPerHour",
          "serviceFeePercent",
          "version",
          "hoursUntilExpiry",
          "maximum24HourAud",
          "isArchived",
          "defaultOnExit",
          "defaultPublic",
          "paymentGate",
        ].map((key) => (
          <div>
            <label>{key}</label>
            <div>{rateCard[key] === false ? "false" : rateCard[key]}</div>
          </div>
        ))}
      </div>

      <Table
        data={rateCard?.bookings || []}
        columns={COLUMNS}
        initialState={{ sortBy: [{ desc: true, id: "createdAt" }] }}
      />
    </Styles>
  )
}
