import { AnimatePresence } from "framer-motion"
import React, { useEffect, useState, useCallback, useMemo } from "react"
import CreateReservation from "../CreateReservation"
import EditReservation from "../EditReservation"
import moment from "./moment"

import styled from "@emotion/styled"
import Inspector from "./Inspector"

import Stage from "./Stage"
import useTimelineMachine from "./useTimelineMachine"
import useData from "./useData"
import Spinner from "../Spinner"

import MetricBar from "./MetricBar"

const Styles = styled.div`
  background: ${({ theme }: any) => theme.background};
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`

export default ({
  facilityId = "404d9e14-3903-4a76-82ee-10dd8a89bfda",
}: any) => {
  const { data, refetch } = useData(facilityId)

  const spaces = useMemo(() => {
    let spaces = [...(data?.facility?.spaces || [])]
    spaces = spaces.sort((a: any, b: any) =>
      a.label.localeCompare(b.label, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    )
    return spaces.map((space: any) => ({
      ...space,
      reservations: (space.reservations || []).map(
        ({ id, lo, hi, plate }: any) => ({
          id,
          plate,
          range: moment.range(moment(lo), moment(hi)),
        })
      ),
    }))
  }, [data])

  const [state, send, service]: any = useTimelineMachine({
    facilityId,
    refetch,
  })

  useEffect(() => {
    send("UPDATE_SPACES", { spaces })
  }, [send, spaces])

  // ----------- DEBUG ----------

  useEffect(() => {
    if (window.location.hostname === "localhost") {
      const handler = (state: any, event: any) =>
        state.changed && console.log({ state, event })
      service.onTransition(handler)
      return () => service.off(handler)
    }
  }, [service])

  const [today] = useState(moment().startOf("day"))

  const [extent] = useState(90)

  const onMouseDownRow = useCallback(
    (space: any, range: any) => {
      const offsetRange = moment.range(
        range.start.clone().add(16, "hours"),
        range.end.clone().add(10, "hours")
      )
      send("MOUSE_DOWN.ROW", { reservation: { space, range: offsetRange } })
    },
    [send]
  )

  const onMouseDownReservation = useCallback(
    (reservation: any) => {
      send("MOUSE_DOWN.RESERVATION", { reservation })
    },
    [send]
  )

  const onMouseMove = useCallback(
    (_: any, range: any) => {
      const offsetRange = moment.range(
        range.start.clone().add(16, "hours"),
        range.end.clone().add(10, "hours")
      )
      send("MOUSE_MOVE", { range: offsetRange })
    },
    [send]
  )

  const onMouseUp = useCallback(() => {
    send("MOUSE_UP")
  }, [send])

  const epoch = useMemo(() => today.clone().subtract(14, "days"), [today])

  return (
    <Styles>
      <MetricBar {...{ spaces }}></MetricBar>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          position: "relative",
        }}
      >
        <Stage
          {...{
            extent,
            epoch,
            onMouseDownReservation,
            onMouseDownRow,
            onMouseMove,
            onMouseUp,
            state,
            send,
            spaces,
          }}
        />
      </div>

      <AnimatePresence>
        {state.matches("draft") && (
          <Inspector>
            <CreateReservation
              reservation={state.context.selectedReservation}
              onSubmit={(reservation: any) => {
                send("SUBMIT", { reservation })
              }}
              onCancel={() => send("CANCEL")}
            ></CreateReservation>
          </Inspector>
        )}

        {state.matches("selected") && (
          <Inspector>
            {state.matches("selected.loading") ? (
              <Spinner />
            ) : (
              <EditReservation
                reservation={state.context.selectedReservation}
                onCancel={() => send("CANCEL")}
                onDelete={() => send("CANCEL")}
                onSubmit={(reservation: any) => {
                  send("SUBMIT", { reservation })
                }}
              ></EditReservation>
            )}
          </Inspector>
        )}
      </AnimatePresence>
    </Styles>
  )
}
