import { gql, useQuery } from "@apollo/client"
import styled from "@emotion/styled"
import { orderBy } from "lodash"
import { DateTime } from "luxon"
import React, { useState } from "react"

function Device({
  className,
  device: { name, mac, hostname, ip, latestPing, updatedAt },
}: any) {
  return (
    <div {...{ className }}>
      <div>{name}</div>
      <div>{mac}</div>
      <div>{DateTime.fromISO(updatedAt).toRelative()}</div>
      {/* <div>{hostname}</div> */}
      {/* <div>{ip}</div>
      <div>{latestPing?.uptime}</div>
      <div>{latestPing?.procs}</div>
      <div>{latestPing?.versions?.git}</div>
      <div>{latestPing?.versions?.node}</div>
      <div>{latestPing?.cpu?.[0]}</div>
      <div>{latestPing?.mem?.free}</div> */}
    </div>
  )
}

const StyledDevice = styled(Device)`
  display: flex;
  width: 100%;
  flex: 1;
  & > * {
    width: 100%;
  }
`

export default ({ id }: any) => {
  const [filters, setFilters] = useState([] as any[])

  const { data: { facility = {} } = {} } = useQuery(
    gql`
      query FacilityDevices($id: ID!) {
        facility(id: $id) {
          devices {
            id
            mac
            hostname
            ip
            name
            updatedAt
            latestPing {
              id
              cpu
              mem
              uptime
              procs
              versions
            }
          }
        }
      }
    `,
    { variables: { id }, pollInterval: 3333 }
  )

  const devices = orderBy(facility?.devices || [], ["updatedAt"], ["desc"])

  return (
    <div>
      {(devices || []).map((device: any) => (
        <StyledDevice {...{ filters, setFilters, device }} />
      ))}
    </div>
  )
}
