import { gql, useMutation } from "@apollo/client"
import React, { useCallback, useState } from "react"

const controls = false // process.env.NODE_ENV === "development"

export function Cam({ facility, ip, src, onLoad }: any) {
  const [requestMutation] = useMutation(
    gql`
      mutation Request($facility: ID!, $url: String!) {
        request(facility: $facility, url: $url)
      }
    `
  )

  const ref: any = useCallback((node: any) => {
    if (node !== null) {
      node.addEventListener("load", () => {
        if (onLoad) {
          onLoad()
        }
        setTimeout(() => {
          node.src = `${src}?${Date.now()}`
        }, 1000 / 5)
      })
      node.addEventListener("error", () => {
        setTimeout(() => {
          node.src = `${src}?${Date.now()}`
        }, 1000 * 5)
      })
    }
  }, [])

  const buttonStyle = {
    cursor: "pointer",
  }

  const [iris, setIris] = useState(undefined as any)

  return (
    <div style={{ position: "relative" }}>
      <img style={{ height: "100%", width: "100%" }} ref={ref} src={src} />
      {controls ? (
        <>
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              right: 0,
              opacity: 0.25,
            }}
          >
            <input
              value={iris}
              type="range"
              min={0}
              max={100}
              step={10}
              onChange={async (event) => {
                setIris(event.target.value)
                await requestMutation({
                  variables: {
                    facility: facility?.id,
                    url: `http://${ip}/hwlayer/extopt?iris_pc=${event.target.value}&wfilter=1`,
                  },
                })
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              opacity: 0.25,
            }}
          >
            {[1, 2.5, 5, 10, 20, 24].map((v) => (
              <button
                style={buttonStyle}
                onClick={async () => {
                  await requestMutation({
                    variables: {
                      facility: facility?.id,
                      url: `http://${ip}/capture/scapture?wfilter=1&sensor=1&source=1&gain=${v}`,
                    },
                  })
                }}
              >
                {v}
              </button>
            ))}
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              opacity: 0.25,
            }}
          >
            {[49000, 32000, 16000, 8000, 4000, 2000].map((v) => (
              <button
                style={{ ...buttonStyle, display: "block" }}
                onClick={async () => {
                  await requestMutation({
                    variables: {
                      facility: facility?.id,
                      url: `http://${ip}/capture/scapture?wfilter=1&sensor=1&source=1&shutter=${v}`,
                    },
                  })
                }}
              >
                {v}
              </button>
            ))}
          </div>
        </>
      ) : null}
      {/* <div style={{ position: "absolute", left: 0, bottom: 0 }}>
        {["All_Pass", "Infra_Cut"].map((key) => (
          <button
            style={{ ...buttonStyle, display: "block" }}
            onClick={async () => {
              await requestMutation({
                variables: {
                  facility: facility?.id,
                  url: `http://${ip}/control/filtercontrol?section=1&current_mode=${key}&wfilter=1`,
                },
              })
            }}
          >
            {key}
          </button>
        ))}
      </div> */}
    </div>
  )
}
