import { gql, useQuery } from "@apollo/client"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"

export function Weather({
  city,
  show,
  style,
}: {
  city: { id: string }
  show: boolean
  style?: object
}) {
  const { data } = useQuery(
    gql`
      query Weather($id: ID!) {
        city(id: $id) {
          id
          weather
        }
      }
    `,
    {
      variables: { id: city?.id },
      skip: !city?.id,
      pollInterval: 5 * 60 * 1000,
    }
  )
  const weather = data?.city?.weather
  return (
    <AnimatePresence>
      {show && weather ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            fontSize: "5vmin",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            padding: "5vmin",
            ...(style || {}),
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={`https://openweathermap.org/img/wn/${"01n"}@2x.png`}
              style={{ width: "5vmin" }}
            />
            <div>{Math.round(weather?.main?.temp - 273.15)}°</div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "2.5vmin",
                justifyContent: "space-around",
                height: "100%",
                opacity: 0.5,
              }}
            >
              <div style={{ color: "hsl(0, 75%, 75%)" }}>
                {Math.round(weather?.main?.temp_max - 273.15)}
              </div>
              <div style={{ color: "hsl(240, 75%, 75%)" }}>
                {Math.round(weather?.main?.temp_min - 273.15)}
              </div>
            </div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
