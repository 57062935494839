import React from "react"
import moment from "./moment"
import styled from "@emotion/styled"

const MetricStyles = styled.div`
  background: hsla(0, 0%, 50%, 0.1);
  color: ${({ theme }: any) => theme.color};
  width: 100%;
  opacity: 0.9;
`

const Metric = ({ label, value }: any) => (
  <MetricStyles>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.25rem",
        textTransform: "uppercase",
      }}
    >
      {label}
    </div>
    <div
      style={{
        background: `hsla(0, 0%, 100%, 0.25)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.125rem 1rem",
        fontSize: "300%",
      }}
    >
      {value}
    </div>
  </MetricStyles>
)

const calculateOccupied = (reservations: any[], now: any) =>
  reservations.filter((reservation: any) => reservation.range.contains(now))
    .length

const OccupiedMetric = ({ reservations, now }: any) => (
  <Metric label={"Occupied"} value={calculateOccupied(reservations, now)} />
)

const AvailableTonightMetric = ({ spaces, reservations, today }: any) => {
  const value =
    spaces.length -
    reservations.filter(
      (reservation: any) =>
        reservation.range.start.isBefore(today.end) &&
        reservation.range.end.isAfter(today.end)
    ).length
  return <Metric label={"Available Tonight"} {...{ value }} />
}

const AvailableNowMetric = ({ spaces, reservations, now }: any) => (
  <Metric
    label={"Available Now"}
    value={spaces.length - calculateOccupied(reservations, now)}
  />
)

const LeavingTodayMetric = ({ reservations, today }: any) => {
  const value = reservations.filter((reservation: any) =>
    today.contains(reservation.range.end)
  ).length
  return <Metric label={"Leaving Today"} {...{ value }} />
}

const EnteringTodayMetric = ({ reservations, today }: any) => {
  const value = reservations.filter((reservation: any) =>
    today.contains(reservation.range.start)
  ).length
  return <Metric label={"Entering Today"} {...{ value }} />
}

export default (props: any) => {
  const reservations = props.spaces.reduce((acc: any, space: any) => {
    return [...acc, ...space.reservations]
  }, [])
  const today = moment.range(moment().startOf("day"), moment().endOf("day"))
  const now = moment()
  const ctx = { ...props, reservations, today, now }
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gridGap: "1rem",
        padding: "1rem",
      }}
    >
      <OccupiedMetric {...ctx}></OccupiedMetric>
      <AvailableNowMetric {...ctx}></AvailableNowMetric>
      <AvailableTonightMetric {...ctx}></AvailableTonightMetric>
      <LeavingTodayMetric {...ctx}></LeavingTodayMetric>
      <EnteringTodayMetric {...ctx}></EnteringTodayMetric>
    </div>
  )
}
