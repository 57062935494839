import {AnimatePresence, motion} from "framer-motion"
import {DateTime} from "luxon"
import React, {useEffect} from "react"

export function Clock({
  show,
  style,
  timezone,
}: {
  show?: boolean
  style?: object
  timezone?: string
}) {
  const [time, setTime] = React.useState<DateTime>(DateTime.local().setZone(timezone as string));
  useEffect(() => {
    if (timezone) {
      setInterval(() => {
        const currentTime = DateTime.local().setZone(timezone as string)
        setTime(currentTime);
      }, 1000)
    }
  }, [timezone])

  return (
    <AnimatePresence>
      {show ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            fontSize: "5vmin",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            padding: "5vmin",
            ...(style || {}),
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "2vmin",
                justifyContent: "space-around",
                height: "100%",
                overflow: "hidden",
                opacity: 0.5,
                alignItems: "flex-end",
                margin: "0 0.25em",
              }}
            >
              <div>{time.toFormat("cccc")}</div>
              <div>{time.toFormat("d LLL")}</div>
            </div>
            <div>{time.toFormat("h:mm a")}</div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
