import React from "react"

export default ({ fill }: any) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        maxWidth: "50vmin",
        maxHeight: "50vmin",
      }}
    >
      <svg viewBox="0 0 512 512" version="1.1">
        <title>Slice</title>
        <defs>
          <polygon
            id="path-1"
            points="125.447686 115.661372 125 73 387 249.98513 125.447686 439 125.447686 398.174616 326.641277 250.936812"
          ></polygon>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="Path-2" fill-rule="nonzero">
            <use fill="black" fill-opacity="1" href="#path-1"></use>
            <use fill={fill} href="#path-1"></use>
          </g>
        </g>
      </svg>
    </div>
  )
}
