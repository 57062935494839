import styled from "@emotion/styled"

export const Input = styled.input`
  border-radius: 3px;
  padding: 1em;
  display: block;
  color: ${(props: any) => props.theme.color};
  background-color: hsla(0, 0%, 50%, 0.125);
  border: 1px solid grey;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 16px;

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px ${(props: any) => props.theme.background}
      inset;
    -webkit-text-fill-color: ${(props: any) => props.theme.color};
  }
`
