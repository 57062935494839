import { faCalendar } from "@fortawesome/free-solid-svg-icons"
import IconicInput from "../IconicInput"
import React from "react"
import moment from "moment"

export default ({ lo }: any) => (
  <IconicInput icon={faCalendar}>
    <div>{moment(lo).format("dddd, LL")}</div>
  </IconicInput>
)
