import { gql, useQuery } from "@apollo/client"
import styled from "@emotion/styled"
import React, { useEffect, useRef, useState } from "react"

function Device({
  className,
  device: { name, mac, hostname, ip, latestPing },
  filters,
  setFilters,
}: any) {
  return (
    <div {...{ className }}>
      <div
        style={{
          background: filters.includes(mac) ? "red" : undefined,
        }}
        onClick={() => {
          if (filters.includes(mac)) {
            setFilters(filters.filter((filter: any) => filter !== mac))
          } else {
            setFilters([mac, ...filters])
          }
        }}
      >
        Logs
      </div>
      <div>{name}</div>
      <div>{mac}</div>
      <div>{hostname}</div>
      <div>{ip}</div>
      <div>{latestPing?.uptime}</div>
      <div>{latestPing?.procs}</div>
      <div>{latestPing?.versions.git}</div>
      <div>{latestPing?.versions.node}</div>
      <div>{latestPing?.cpu[0]}</div>
      <div>{latestPing?.mem.free}</div>
      {/* <div>
        <pre>{JSON.stringify(latestPing, null, 2)}</pre>
      </div> */}
    </div>
  )
}

const StyledDevice = styled(Device)`
  display: flex;
  width: 100%;
  flex: 1;
  & > * {
    width: 100%;
  }
`

const Bit = ({ prefix, value, index }: any) => {
  let high = ((1 << index) & value) !== 0
  return (
    <div
      style={{
        width: 20,
        height: 20,
        background: high ? "green" : "red",
      }}
    >
      {`${prefix}${index}`}
    </div>
  )
}

const Register = ({ address, value }: any) => {
  return (
    <div>
      <div>
        {
          ({
            0x25: "B",
          } as any)[address]
        }{" "}
        {address} {value}
      </div>
      <div style={{ display: "flex" }}>
        {Array.from({ length: 8 }).map((_: any, index: number) => (
          <Bit
            {...{
              value,
              index,
              prefix: ({
                0x25: "A",
              } as any)[address],
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default () => {
  const [filters, setFilters] = useState([] as any[])
  const [logs, setLogs] = useState([] as any[])
  const [registers, setRegisters] = useState({
    0x25: 0b00111111,
  } as any)
  useEffect(() => {
    const ws = new WebSocket("ws://ssh.drivo.io:8888/")
    ws.onopen = () => {}
    ws.onmessage = (e: any) => {
      try {
        let parsed = JSON.parse(e.data)
        if (parsed[0] === 0x06) {
          let [register, value] = parsed.slice(1)
          setRegisters({
            ...registers,
            [register]: value,
          })
        }
      } catch (e) {}

      setLogs((logs) => [e.data, ...logs])
    }
    return function () {
      ws.close()
    }
  }, [setLogs])

  const { data: { devices } = {} } = useQuery(
    gql`
      query Devices {
        devices {
          id
          mac
          hostname
          ip
          name
          latestPing {
            id
            cpu
            mem
            uptime
            procs
            versions
          }
        }
      }
    `,
    { pollInterval: 3333 }
  )

  const filteredLogs = filters?.length
    ? logs.filter((log) => filters.some((filter) => log.startsWith(filter)))
    : logs

  return (
    <div
      style={{
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {(devices || []).map((device: any) => (
          <StyledDevice {...{ filters, setFilters, device }} />
        ))}
      </div>
      <div
        style={{
          height: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {Object.entries(registers).map(([address, value]: any) => (
          <Register {...{ address, value }} />
        ))}
      </div>
      <div
        style={{
          fontFamily: "monospace",
          fontSize: "125%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {filteredLogs.map((log) => (
          <div>{log}</div>
        ))}
      </div>
    </div>
  )
}
