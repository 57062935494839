/** @jsx jsx */
import { Button, Container, Input, Label } from "../components"
import { Field, Form, Formik } from "formik"
import { Fragment } from "react"
import { Link } from "react-router-dom"
import { css, jsx, keyframes } from "@emotion/core"
import { useAuth } from "../Auth"
import Logo from "../Logo"
import Spinner from "../Spinner"

const shake = keyframes`
0% { transform: translate(1px, 1px) rotate(0deg); }
10% { transform: translate(-1px, -2px) rotate(-1deg); }
20% { transform: translate(-3px, 0px) rotate(1deg); }
30% { transform: translate(3px, 2px) rotate(0deg); }
40% { transform: translate(1px, -1px) rotate(1deg); }
50% { transform: translate(-1px, 2px) rotate(-1deg); }
60% { transform: translate(-3px, 1px) rotate(0deg); }
70% { transform: translate(3px, 1px) rotate(-1deg); }
80% { transform: translate(-1px, -1px) rotate(1deg); }
90% { transform: translate(1px, 2px) rotate(0deg); }
100% { transform: translate(1px, -2px) rotate(-1deg); }
`

const SigninForm = () => (
  <Fragment>
    <Form style={{ width: "100%" }}>
      <div style={{ margin: "1em 0" }}>
        <Fragment>
          <Label style={{ opacity: 0.75 }}>Email</Label>
          <Field
            as={Input}
            type="email"
            name="email"
            required
            autoComplete="email"
          />
        </Fragment>
        <Fragment>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Label style={{ opacity: 0.75 }}>Password</Label>
            <Label style={{ opacity: 0.5 }}>
              <Link to="/recover">Forgot password?</Link>
            </Label>
          </div>
          <Field
            as={Input}
            type="password"
            name="password"
            required
            autoComplete="password"
          />
        </Fragment>
      </div>
      <Button
        type="submit"
        style={{
          borderColor: "hsl(127, 55%, 43%)",
          color: "hsl(127, 55%, 43%)",
          width: "100%",
        }}
      >
        Login
      </Button>
    </Form>
    <div style={{ opacity: 0.5, marginTop: "1em", width: "100%" }}>
      Need an account? <Link to="/join">Join Drivo for free</Link>
    </div>
  </Fragment>
)

export function Signin() {
  const auth = useAuth()

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={async (values, actions) => {
          try {
            await auth.signin(values)
          } catch (e) {
            console.error(e)
            actions.setStatus(false)
            setTimeout(() => {
              actions.setStatus(null)
            }, 333)
          }
        }}
      >
        {(props) =>
          props.isSubmitting ? (
            <Spinner />
          ) : (
            <div
              style={{ width: "100%" }}
              css={
                props.status === false &&
                css`
                  animation: ${shake} 0.3s ease 1;
                `
              }
            >
              <Container
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                  maxWidth: "33vw",
                }}
              >
                <Logo />
                <SigninForm />
              </Container>
            </div>
          )
        }
      </Formik>
    </div>
  )
}
