import { Container, Header } from "../components"
import { Formik, FormikHelpers } from "formik"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import React from "react"
import OrganizationForm, { FormValues } from "../OrganizationForm"
import { gql } from "@apollo/client"
import { useHistory } from "react-router"

export default () => {
  const history = useHistory()

  const { addToast } = useToasts()

  const [createOrganization] = useMutation(gql`
    mutation CreateOrganization($input: CreateOrganizationInput!) {
      createOrganization(input: $input) {
        id
      }
    }
  `)

  return (
    <Container fixed>
      <Header>
        <h3>Create Organization</h3>
      </Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: "",
        }}
        onSubmit={async (input: any, _actions: FormikHelpers<FormValues>) => {
          await createOrganization({
            variables: {
              input,
            },
            refetchQueries: ["Me"],
          })
          addToast("Organization created!", {
            appearance: "success",
          })
          history.push("/")
        }}
      >
        {(props: any) => <OrganizationForm {...props} />}
      </Formik>
    </Container>
  )
}
