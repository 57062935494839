import { DateTime, Interval } from "luxon"
import React from "react"

const Time = ({ value, timezone }: { value: DateTime, timezone: string }) => {
  const sameDay = value.isValid && value.hasSame(DateTime.local(), "day")
  return (
    <div>
      {sameDay ? null : (
        <div style={{ opacity: 0.66 }}>{value.setZone(timezone).toFormat("d LLL")}</div>
      )}
      <div>{value.setZone(timezone).toFormat("h:mma")}</div>
    </div>
  )
}

export default ({ plate, amount, timezone, ...props }: any) => {

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        paddingRight: "10vmin",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "15vmin",
        }}
      >
        {plate}
      </div>


      {amount ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "15vmin",
          }}
        >
          ${(amount / 100).toFixed(2)}
        </div>
      ) : (
        "N/A"
      )}

        <h2 style={{marginTop:60}}>Processing... Please wait until the gate opens.</h2>

      <div
        className="CTA"
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "5vmin",
          whiteSpace: "pre",
          textAlign: "center",
          lineHeight: "7.5vmin",
        }}
      >
        {prompt}
      </div>
    </div>
  )
}
