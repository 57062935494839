import { Button, Form } from "../components"
import { useQuery } from "@apollo/client"
import CancelButton from "../CancelButton"
import Debug from "../Debug"
import IntervalInput from "../IntervalInput"
import React from "react"
import TenancyInput from "../TenancyInput"
import { gql } from "@apollo/client"

export interface FormValues {
  lo: string
  hi: string
  tenancy: string
  membership: string
}

export default ({ values, isSubmitting }: any) => {
  const { data } = useQuery(
    gql`
      query Tenancy($id: ID!) {
        tenancy(id: $id) {
          id
          facility {
            id
            name
            address
          }
        }
      }
    `,
    { variables: { id: values?.tenancy } }
  )
  const tenancy = data?.tenancy

  return (
    <Form>
      <TenancyInput {...tenancy} />
      {/* <hr /> */}
      <IntervalInput {...values} />
      {/* <hr /> */}
      <div
        style={{
          display: "grid",
          gridGap: "0.5em",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <CancelButton />
        <Button
          disabled={isSubmitting}
          type="submit"
          style={{
            borderColor: "hsl(127, 55%, 43%)",
            color: "hsl(127, 55%, 43%)",
            width: "100%",
          }}
        >
          Continue
        </Button>
      </div>

      <Debug>{values}</Debug>
    </Form>
  )
}
