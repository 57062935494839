import { gql, useQuery } from "@apollo/client"
import React from "react"

export default () => {
  const { data } = useQuery(
    gql`
      query Masquerades {
        masquerades {
          id
          token
          location
        }
      }
    `
  )

  return (
    <div>
      {(data?.masquerades || []).map((masquerade: any) => (
        <div style={{ display: "flex", width: "100%" }}>
          <a
            style={{ width: "100%" }}
            href={`/masquerades/${masquerade.id}`}
          >{`/masquerades/${masquerade.id}`}</a>
          <a
            style={{ width: "100%", display: "block" }}
            href={masquerade.location}
          >
            {masquerade.location}
          </a>
        </div>
      ))}
    </div>
  )
}
