import React, { useRef, useEffect } from "react"
import styled from "@emotion/styled"
import Row from "./Row"
import Span from "./Span"
import Reservation from "./Reservation"
import Label from "./Label"
import LabelInput from "./LabelInput"
import { Button } from "../components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Header from "./Header"
import {
  faCog,
  faSave,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"

const Styled = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  &,
  & * {
    user-select: none;
    -webkit-user-select: none;
  }
`

const Spaces = ({ spaces, state, send }: any) => {
  const newLabelInputRef = useRef()

  return (
    <div
      style={{
        width: state.matches("admin") ? 128 : 64,
      }}
    >
      {spaces.map((space: any) =>
        state.matches("admin") ? (
          <div key={space.id} style={{ display: "flex" }}>
            <LabelInput
              space={space}
              onFocus={() => send("UPDATE")}
              onDirtyBlur={(space: any) => send("SUBMIT", { space })}
            />
            <Button
              style={{
                height: 42,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#eee",
                cursor: "pointer",
                width: "100%",
                padding: "0",
                color: "red",
              }}
              onClick={() => {
                send("REMOVE", { space })
              }}
            >
              <FontAwesomeIcon icon={faTimes} size={"2x"} />
            </Button>
          </div>
        ) : (
          <Label
            key={space.id}
            style={{
              height: 42,
              width: "100%",
            }}
          >
            {space?.label || space?.id}
          </Label>
        )
      )}
      {state.matches("admin.adding") && (
        <LabelInput
          ref={newLabelInputRef}
          autoFocus={true}
          space={state.matches("admin.adding.submitted") && {}}
          onFocus={() => send("SET_INPUT_REF", { newLabelInputRef })}
          onDirtyBlur={(space: any) => send("SUBMIT", { space })}
        />
      )}
    </div>
  )
}

const Left = styled.div`
  position: sticky;
  width: 64px;
  left: 0px;
  z-index: 1337;
  background: ${({ theme }: any) => theme.background};
`

const Toolbar = styled.div`
  background: ${({ theme }: any) => theme.background};
`

export default ({
  extent,
  epoch,
  onMouseDownRow,
  onMouseMove,
  onMouseUp,
  send,
  state,
  onMouseDownReservation,
  spaces,
}: any) => {
  const ref = useRef<any>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.scroll(12 * 64, 0)
    }
    return () => {}
  }, [ref])

  return (
    <Styled ref={ref}>
      <div
        className="stuck"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1336,
          width: "100%",
        }}
      >
        <Header
          {...{
            epoch,
            state,
            extent,
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          width: extent * 64,
        }}
      >
        <Left
          className="stuck"
          style={{
            width: state.matches("admin") ? 128 : 64,
          }}
        >
          <Toolbar
            className="stuck"
            style={{
              width: state.matches("admin") ? 128 : 64,
              height: 42,
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              position: "absolute",
              left: 0,
              top: -42,
              color: "#666",
            }}
            onClick={() =>
              state.matches("admin") ? send("COMPLETE") : send("ADMINISTER")
            }
          >
            {state.matches("admin") ? (
              <FontAwesomeIcon icon={faSave} size={"2x"} />
            ) : (
              <FontAwesomeIcon icon={faCog} size={"2x"} />
            )}
          </Toolbar>

          <Spaces {...{ spaces, state, send }} />

          <div>
            {state.matches("admin") && (
              <Button
                style={{
                  height: 42,
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: 0.5,
                  background: "hsla(0, 0%, 50%, 0.125)",
                  cursor: "pointer",
                  width: "100%",
                  padding: "0",
                }}
                onClick={() => {
                  send("ADD")
                }}
              >
                <FontAwesomeIcon icon={faPlus} size={"2x"} />
              </Button>
            )}
          </div>
        </Left>

        <div>
          {spaces.map((space: any, i: any) => (
            <Row
              key={space.id}
              {...{
                i,
                onMouseDown: onMouseDownRow,
                onMouseMove,
                onMouseUp,
                space,
                epoch,
                extent,
                send,
              }}
            >
              {(state.matches("outlining") || state.matches("draft")) &&
                state.context?.selectedReservation?.space != null &&
                state.context.selectedReservation.space.id === space.id && (
                  <Span
                    {...{
                      epoch,
                      range: state.context.selectedReservation.range,
                      style: {
                        pointerEvents: "none",
                        cursor: "col-resize",
                        userSelect: "none",
                      },
                    }}
                  />
                )}
              {space.reservations?.map((reservation: any) => (
                <Reservation
                  key={reservation.id}
                  {...{ epoch, ...reservation, i }}
                  onMouseDown={onMouseDownReservation}
                  onMouseUp={onMouseUp}
                ></Reservation>
              ))}
            </Row>
          ))}
          {state.matches("admin.adding") && <Row {...{ extent, epoch }}></Row>}
        </div>
      </div>
    </Styled>
  )
}
