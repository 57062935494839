import "./index.css"
import * as Sentry from "@sentry/browser"
import * as serviceWorker from "./serviceWorker"
import { MasqueradeProvider } from "./Admin/Masquerade"
import { BrowserRouter as Router } from "react-router-dom"
import { ThemeProvider } from "./components/ThemeContext"
import { ToastProvider } from "react-toast-notifications"
import { render } from "react-dom"
import Admin from "./Admin"
import Apollo from "./Apollo"
import App from "./App"
import AuthProvider from "./Auth/AuthProvider"
import React from "react"
import Wrapper from "./Wrapper"

import "codemirror/theme/material.css"
import "codemirror/lib/codemirror.css"

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://dfb92ed51b057f8d8e369c5f0835a40a@o4507103417204736.ingest.us.sentry.io/4507945659662336",
  })
}

// @ts-ignore
render(
  <ThemeProvider>
    {/*
// @ts-ignore */}
    <ToastProvider autoDismiss autoDismissTimeout={6000}>
      <MasqueradeProvider>
        <Router>
          <Wrapper
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              overflow: "hidden",
              width: "100vw",
            }}
          >
            <div style={{ height: "100%", overflow: "hidden" }}>
              <Apollo>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </Apollo>
            </div>
            <Admin />
          </Wrapper>
        </Router>
      </MasqueradeProvider>
    </ToastProvider>
  </ThemeProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
