import { Header } from "../components"
import { useQuery } from "@apollo/client"
import React, { useMemo, useState } from "react"
import Table from "../Table"
import { gql } from "@apollo/client"
import moment from "moment"

const Organizations = ({ organizations }: any) => {
  const [filters] = useState([])

  const columns = useMemo(
    () =>
      [
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Cell: ({ cell: { value } }: any) => <div>{value?.length}</div>,
          Header: "Memberships",
          accessor: "memberships",
        },
        {
          Cell: ({ cell: { value } }: any) => (
            <div>{moment(value).fromNow(true)}</div>
          ),
          Header: "Created",
          accessor: "createdAt",
        },
        {
          Cell: ({ cell: { value } }: any) => {
            return <div>{value && moment(value).fromNow(true)}</div>
          },
          Header: "Updated",
          accessor: ({ updatedAt, createdAt }: any) => updatedAt || createdAt,
          id: "updatedAt",
        },
      ].filter(Boolean),
    []
  )

  let pool = organizations || []
  filters.forEach(([_name, predicate]: any) => {
    pool = pool.filter((user: any) => !predicate(user))
  })

  return (
    <Table
      data={pool}
      columns={columns}
      initialState={{ sortBy: [{ id: "name" }] }}
    />
  )
}

export default (_props: any) => {
  const { data } = useQuery(
    gql`
      query Organizations {
        organizations {
          id
          name
          createdAt
          updatedAt
          memberships {
            id
            user {
              id
            }
          }
        }
      }
    `
  )

  return (
    <div style={{ width: "100%" }}>
      <Header>Organizations</Header>
      <Organizations {...{ organizations: data?.organizations }} />
    </div>
  )
}
