import { useQuery } from "@apollo/client"
import COLUMNS from "./columns"
import React from "react"
import Spinner from "../Spinner"
import Table from "../Table"
import { gql } from "@apollo/client"

export default (_props: any) => {
  const { data } = useQuery(
    gql`
      query Facilities {
        facilities {
          id
          name
          address
          placie
          capacity
          spacesLength
          tower
          lite
          createdAt
        }
      }
    `
  )

  return data?.facilities ? (
    <Table
      data={data?.facilities}
      columns={COLUMNS}
      initialState={{ sortBy: [{ desc: true, id: "createdAt" }] }}
      more={_props}
    />
  ) : (
    <Spinner />
  )
}
