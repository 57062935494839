import { Button } from "../components"
import { useHistory } from "react-router"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import React from "react"
import { gql } from "@apollo/client"

export default ({ id }: any) => {
  const history = useHistory()
  const { addToast } = useToasts()

  const [deleteRequest] = useMutation(
    gql`
      mutation DeleteRequest($id: ID!) {
        deleteRequest(id: $id)
      }
    `,
    { refetchQueries: ["Assignments"], variables: { id } }
  )
  return (
    <Button
      negative
      type="button"
      onClick={async () => {
        await deleteRequest()
        addToast("Request deleted!", {
          appearance: "warning",
        })
        history.back()
      }}
    >
      Delete
    </Button>
  )
}
