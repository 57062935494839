import { Field } from "formik"
import { Label, Select } from "../components"
import React from "react"

export default ({ data }: any) => {
  const organizations = data?.me?.memberships
    ? data?.me?.memberships.map((membership: any) => membership.organization)
    : []

  const organizationId = organizations[0]?.id
  const effectiveOrganization = organizations.find(
    ({ id }: any) => id === organizationId
  )

  let memberships = effectiveOrganization?.memberships || []
  memberships = memberships.filter(({ user }: any) => user?.id !== data?.me?.id)

  return (
    <>
      {organizations?.length > 1 ? (
        <>
          <Label>Organization</Label>
          <Field as={Select} name="organization" style={{ height: 44 }}>
            {organizations.map(({ id, name }: any) => (
              <option value={id}>{name}</option>
            ))}
          </Field>
        </>
      ) : null}
      <>
        {/* <Label>Member</Label> */}
        <Field as={Select} name="membership" style={{ height: 44 }}>
          <option value=""></option>
          {memberships.map(({ id, user: { name } }: any) => (
            <option value={id}>{name}</option>
          ))}
        </Field>
      </>
    </>
  )
}
