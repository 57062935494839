import * as jwt from "jsonwebtoken"
import { useHistory } from "react-router"
import { useQuery } from "@apollo/client"
import React, { createContext, useEffect, useReducer, useState } from "react"
import { gql } from "@apollo/client"

export const MasqueradeUUID = "4269D484-C027-488C-894E-EA0E24A268DE"
export const MasqueradeContext: any = createContext({
  dispatch: () => {
    // do nothing
  },
  state: {},
})

const initialState = {}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "clear":
      return initialState
    case "session":
      try {
        if (!action.payload.user.roles.includes("ADMIN")) {
          return state
        }
      } catch (e) {
        return state
      }
      localStorage.setItem(MasqueradeUUID, action.payload.token)
      return { ...state, session: action.payload }
    default:
      return state
  }
}

export function MasqueradeProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value: any = { dispatch, state }
  return (
    <MasqueradeContext.Provider value={value}>
      {props.children}
    </MasqueradeContext.Provider>
  )
}

export default function Masquerade(_props: any) {
  const history = useHistory()

  // const invisible = localStorage.getItem("masquerade") === "false"
  // const [visible, setVisible] = useState(!invisible)
  // const [_visible, _setVisible] = useState(true)
  const [skip, setSkip] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      setSkip(!localStorage.getItem(MasqueradeUUID))
    }, 1111)
    return () => {
      clearInterval(interval)
    }
  })

  // useEffect(() => {
  //   const onKeyDown = (e: any) => {
  //     if (e.key !== "`") {
  //       return
  //     }
  //     const value = !visible
  //     setVisible(value)
  //     localStorage.setItem("masquerade", JSON.stringify(value))
  //   }
  //   window.addEventListener("keydown", onKeyDown)
  //   return () => {
  //     window.removeEventListener("keydown", onKeyDown)
  //   }
  // })

  const { data } = useQuery(
    gql`
      query Masqueradees {
        me {
          id
          masqueradeSecret
          masqueradees {
            id
            roles
            name
          }
        }
      }
    `,
    {
      skip,
    }
  )

  const users = data?.me?.masqueradees || []

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      {users.map(({ id, email, name, avatar }: any) => (
        <div
          key={id}
          onClick={() => {
            const secret = new Buffer(data?.me?.masqueradeSecret, "base64")
            const token = jwt.sign({ email, id, name }, secret)
            localStorage.setItem("token", token)
            localStorage.setItem(
              "user",
              JSON.stringify({
                id,
                email,
                name,
              })
            )
            ;(window as any).APOLLO.resetStore()
            setTimeout(() => {
              history.push("/")
            }, 333)
          }}
          style={{
            backgroundImage: avatar ? `url(${avatar?.url})` : "none",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            cursor: "pointer",
            height: 64,
            width: 64,
          }}
        >
          <div
            style={{
              alignItems: "center",
              background: "white",
              color: "black",
              display: "flex",
              fontSize: "32px",
              height: "100%",
              justifyContent: "center",
              opacity: 0.5,
              width: "100%",
            }}
          >
            {name
              .split(" ")
              .map((n: any) => n[0])
              .join("")}
          </div>
        </div>
      ))}
    </div>
  )
}
