import React from "react"

export const Avatar = ({ name, url, size = 32 }: any) => (
  <div
    style={{
      alignItems: "center",
      backgroundImage: url ? `url(${url})` : "none",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      border: "1px solid hsl(127, 55%, 43%)",
      borderRadius: 3,
      display: "flex",
      height: size,
      justifyContent: "center",
      width: size,
    }}
  >
    {!url && name
      ? name
          .split(" ")
          .map((n: any) => n[0])
          .join("")
      : null}
  </div>
)
