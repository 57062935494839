/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { gql, useQuery, useMutation } from "@apollo/client"
import jwt from "jsonwebtoken"
import { Input, Label, Button } from "../components"
import styled from "@emotion/styled"
import { useState } from "react"
import { useToasts } from "react-toast-notifications"
import CopyToClipboard from "react-copy-to-clipboard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClipboard } from "@fortawesome/free-regular-svg-icons"

const Styles = styled.div`
  margin: 1em;
  code {
    margin: 1em 0;
    background: hsl(0, 0%, 50%, 0.075);
    padding: 1em;
    display: block;
  }
`

const App = (props: any) => {
  const { addToast } = useToasts()

  const [updateApplication] = useMutation(gql`
    mutation UpdateApplication($id: ID!, $input: UpdateApplicationInput!) {
      updateApplication(id: $id, input: $input) {
        id
        endpoint
      }
    }
  `)

  const [endpoint, setEndpoint] = useState(props.endpoint)

  return (
    <div>
      <h2>{props.name}</h2>
      <Label>Endpoint</Label>
      <div style={{ display: "flex", height: 42 }}>
        <Input
          value={endpoint}
          onChange={(e: any) => setEndpoint(e.target.value)}
          style={{ margin: 0, height: "100%" }}
        />
        <Button
          style={{ height: "100%", padding: "0 1em" }}
          onClick={async () => {
            await updateApplication({
              variables: { id: props.id, input: { endpoint } },
            })
            addToast("Endpoint updated!", {
              appearance: "success",
            })
          }}
        >
          Save
        </Button>
      </div>
      <h3>Keys</h3>
      <div>
        {props.keys.map((key: any) => (
          <div>
            <Label>{key.env}</Label>
            <div style={{ position: "relative" }}>
              <code key={key.id}>{key.value}</code>
              <CopyToClipboard
                text={key.value}
                onCopy={() =>
                  addToast("Copied", {
                    appearance: "success",
                  })
                }
              >
                <FontAwesomeIcon
                  css={css`
                    opacity: 0.25;
                    &:hover {
                      opacity: 0.5;
                    }
                  `}
                  size={"2x"}
                  icon={faClipboard}
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    cursor: "pointer",
                  }}
                />
              </CopyToClipboard>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default (props: any) => {
  const { data } = useQuery(
    gql`
      query Applications {
        me {
          id
          memberships {
            id
            organization {
              id
              name
              applications {
                id
                name
                endpoint
                keys {
                  id
                  value
                  env
                }
              }
            }
          }
        }
      }
    `,
    { variables: { id: props.id }, pollInterval: 5555 }
  )

  const memberships = (data?.me?.memberships || []).filter(
    (membership: any) => membership.organization?.applications?.length
  )

  return (
    <Styles>
      {memberships.map((membership: any) => (
        <div>
          <h1>{membership.organization.name}</h1>
          {membership.organization.applications.map((app: any) => (
            <App {...app} />
          ))}
        </div>
      ))}
    </Styles>
  )
}
