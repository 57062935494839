import { Link } from "react-router-dom"
import { useMutation } from "@apollo/client"
import React from "react"
import { gql } from "@apollo/client"

export default ({
  today,
  assignment,
  shares = [],
  hover,
  setHover,
  future,
}: any) => {
  const share = shares[0]

  const [releaseSpace] = useMutation(gql`
    mutation ReleaseSpace($input: ReleaseSpaceInput!) {
      releaseSpace(input: $input)
    }
  `)

  return (
    <div
      onMouseEnter={() => setHover(`${assignment.id}-${today.toString()}`)}
      onMouseLeave={() => setHover(false)}
      style={{
        alignItems: "center",
        background: "#18A4F7",
        cursor: "pointer",
        display: "flex",
        height: 32,
        justifyContent: "space-between",
        opacity: hover ? 1 : 0.5,
      }}
    >
      {share ? (
        <Link
          to={`/shares/${share?.id}`}
          style={{
            alignItems: "center",
            color: "white",
            display: "flex",
            height: "100%",
            justifyContent: "center",
            width: "100%",
          }}
        >
          Shared
        </Link>
      ) : hover && future.overlaps(today) ? (
        assignment.source ? (
          <div
            onClick={async () => {
              await releaseSpace({
                refetchQueries: ["Assignments"],
                variables: {
                  input: {
                    assignment: assignment.id,
                  },
                },
              })
            }}
          >
            Release
          </div>
        ) : (
          <Link
            to={`/spaces/${assignment?.space?.id}/share?lo=${encodeURIComponent(
              today.start.toISOString(true)
            )}&hi=${encodeURIComponent(today.end.toISOString(true))}`}
            style={{
              alignItems: "center",
              color: "white",
              display: "flex",
              height: "100%",
              justifyContent: "center",
              width: "100%",
            }}
          >
            Share
          </Link>
        )
      ) : (
        <div style={{ margin: "2px" }}>
          {assignment.space?.label || assignment.space?.id?.split("-")[0]}
        </div>
      )}
    </div>
  )
}
