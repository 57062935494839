import React from "react"
import { useQuery, gql, useMutation } from "@apollo/client"
import GateClusters from "./GateClusters"
import GateCharges from "./GateCharges"
import Sign from "../Sign2"

export default () => {
  const [updateGate] = useMutation(gql`
    mutation updateGate($id: ID!, $input: UpdateGateInput!) {
      updateGate(id: $id, input: $input) {
        id
      }
    }
  `)

  const { data, loading } = useQuery(
    gql`
      query Facilities {
        facilities {
          id
          name
          address
          organization {
            id
            name
          }
          gates {
            id
            ip
            gateTerminalId
            gateDescription
            observations {
              id
              plate
              createdAt
              open
            }
            gateCharges
          }
        }
      }
    `,
    { pollInterval: 1111 }
  )

  const facilities = (data?.facilities || [])
    .filter(({ gates }: any) => gates.length)
    .filter(({ id }: any) => id !== "404d9e14-3903-4a76-82ee-10dd8a89bfda")

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div
        style={{
          height: 1,
          background: loading ? "green" : undefined,
          transition: "all linear 0.5s",
        }}
      ></div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
          height: "100%",
          overflow: "auto",
        }}
      >
        {facilities.map(({ gates, name, address }: any) => (
          <div style={{ height: "100%", overflow: "auto" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1em",
              }}
            >
              <div>{name}</div>
              <div>{address}</div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              {gates.map(
                ({
                  id,
                  ip,
                  gateTerminalId,
                  gateDescription,
                  observations,
                  gateCharges,
                  signs,
                }: any) => (
                  <div style={{ width: "100%" }}>
                    {ip}
                    {/* {signs?.length ? (
                      <div style={{ height: 240 }}>
                        <Sign id={signs[0].id} />
                      </div>
                    ) : null} */}
                    <div
                      style={{
                        width: "100%",
                        margin: "0.5em",
                        background: `hsla(0, 0%, 50%, 0.075)`,
                      }}
                    >
                      <div>
                        {gateDescription} {gateTerminalId}
                      </div>
                      <div style={{ display: "flex" }}>
                        <GateClusters {...{ observations, gateCharges }} />
                        {/* {gateCharges?.length ? (
                      <GateCharges {...{ gateCharges }} />
                    ) : null} */}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
