import { gql, useQuery } from "@apollo/client"
import React from "react"
import GateTransactions from "../GateTransactions"

export default ({ id }: any) => {
  const { data } = useQuery(
    gql`
      query Gate($id: ID!) {
        gate(id: $id) {
          id
          gateDescription
          facility {
            id
            name
          }
        }
      }
    `,
    {
      variables: {
        id,
      },
    }
  )

  return (
    <div>
      {data?.gate?.gateDescription} @ {data?.gate?.facility?.name}
      <GateTransactions id={id} />
    </div>
  )
}
