import { useApolloClient } from "@apollo/client"
import React, { useEffect } from "react"

export const Signout = ({ history }: any) => {
  const client = useApolloClient()

  const logout = async () => {
    localStorage.removeItem("session")
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    client.resetStore()
    history.push("/signin")
  }

  useEffect(() => {
    logout()
  })

  return <div></div>
}

export default Signout
