import React from "react"
import moment from "moment"
import TimeCell from "../TimeCell"
import { Link } from "react-router-dom"

export default [
  {
    Header: "ID",
    accessor: "id",
    Cell: ({ cell: { value } }: any) => (
      <Link to={`/bookings/${value}`}>{value?.split("-")[0]}</Link>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ cell: { value } }: any) => <div>{value}</div>,
  },
  {
    Header: "Plate",
    accessor: "plate",
    Cell: ({ cell: { value } }: any) => (
      <Link to={`/plate/${value}`}>{value}</Link>
    ),
  },
  {
    Header: "Created",
    accessor: "createdAt",
    Cell: TimeCell,
  },
  {
    Header: "Start",
    accessor: "startDay",
    Cell: ({ cell: { value } }: any) => <div>{value}</div>,
  },
  {
    Header: "End",
    accessor: "endDay",
    Cell: ({ cell: { value } }: any) => <div>{value}</div>,
  },
]
