import React from "react"
import Row from "./Row"
import styled from "@emotion/styled"

const Styles = styled.div`
  background: ${({ theme }: any) => theme.background};
  display: flex;
  height: 100%;
  width: 100%;
`

export default ({ epoch, extent, state, onAdminToggleClick, isAdmin }: any) => (
  <Styles>
    <div
      style={{
        minWidth: state.matches("admin") ? 128 : 64,
        maxWidth: state.matches("admin") ? 128 : 64,
        width: state.matches("admin") ? 128 : 64,
      }}
    ></div>
    <Row
      {...{
        header: true,
        epoch,
        extent,
      }}
    />
  </Styles>
)
