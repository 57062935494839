import { AuthContext } from "./Context"
import { useQuery } from "@apollo/client"
import React from "react"
import { gql } from "@apollo/client"

export default ({ children }: any) => {
  let me = null
  try {
    me = JSON.parse(localStorage.getItem("user") as any)
  } catch (e) {}
  const { data } = useQuery(
    gql`
      query Me {
        me {
          id
          email
          name
          roles
        }
      }
    `
  )
  return (
    <AuthContext.Provider value={data?.me || me}>
      {children}
    </AuthContext.Provider>
  )
}
