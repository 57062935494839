import { Button, Input } from "../../components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUnlink } from "@fortawesome/free-solid-svg-icons"
import { useMutation, useQuery } from "@apollo/client"
import React, { useState } from "react"
import { gql } from "@apollo/client"
import styled from "@emotion/styled"

const SuggestionStyles = styled.div`
  margin: 2px;
  cursor: pointer;
  background: hsla(0, 0%, 100%, 0.125);
  &:hover {
    background: hsla(0, 0%, 100%, 0.25);
  }
`

const Suggestion = ({ id, user, spaceId, createAssignment, callback }: any) => (
  <SuggestionStyles
    key={id}
    onClick={async () => {
      callback(id)
      await createAssignment({
        refetchQueries: ["Spaces"],
        variables: {
          input: {
            membership: id,
            space: spaceId,
          },
        },
      })
    }}
  >
    {user.name}
  </SuggestionStyles>
)

const Suggest = ({ tenancyId, spaceId }: any) => {
  const [createAssignment] = useMutation(gql`
    mutation CreateAssignment($input: CreateAssignmentInput!) {
      createAssignment(input: $input) {
        id
      }
    }
  `)

  const [value, setValue] = useState("")

  const { data } = useQuery(
    gql`
      query Users($id: ID!) {
        tenancy(id: $id) {
          id
          organization {
            id
            memberships {
              id
              user {
                id
                name
              }
            }
          }
        }
      }
    `,
    { variables: { id: tenancyId } }
  )

  const memberships = data?.tenancy?.organization?.memberships || []

  const suggestions = memberships.filter(({ user }: any) => {
    return user.name.toLowerCase().includes(value.trim().toLowerCase())
  })

  return (
    <>
      <Input onChange={(e: any) => setValue(e.target.value)} />
      {value ? (
        <div
          style={{
            padding: 2,
          }}
        >
          {suggestions.map(({ id, user }: any) => (
            <Suggestion
              {...{
                callback: () => {
                  setValue("")
                },
                createAssignment,
                id,
                spaceId,
                user,
              }}
            />
          ))}
        </div>
      ) : null}
    </>
  )
}

const DeleteAction = ({ id }: any) => {
  const [deleteAssignment] = useMutation(gql`
    mutation DeleteAssignment($id: ID!) {
      deleteAssignment(id: $id)
    }
  `)
  return (
    <Button
      negative
      style={{ filter: "saturate(0%)", margin: 0, padding: 4 }}
      onClick={async () => {
        await deleteAssignment({
          refetchQueries: ["Spaces"],
          variables: { id },
        })
      }}
    >
      <FontAwesomeIcon icon={faUnlink} />
    </Button>
  )
}

export default [
  {
    Header: "Label",
    accessor: "label",
  },
  {
    Cell: ({ cell: { value } }: any) => (
      <div>{value?.organization?.name || value?.id}</div>
    ),
    Header: "Tenancy",
    accessor: "tenancy",
  },
  {
    Cell: ({ cell: { value }, row }: any) =>
      value?.[0] ? (
        value[0].membership.user.name
      ) : row.original.tenancy ? (
        <Suggest
          tenancyId={row.original.tenancy.id}
          spaceId={row.original.id}
        />
      ) : (
        <div></div>
      ),
    Header: "Assignee",
    accessor: "assignments",
  },
  {
    Cell: ({ cell: { value } }: any) => {
      const perm = value?.assignments?.find?.(
        (assignment: any) => !assignment.lo && !assignment.hi
      )
      return <>{perm ? <DeleteAction id={value.assignments[0].id} /> : null}</>
    },
    Header: "Actions",
    accessor: (v: any) => v,
  },
]
