import { faCity } from "@fortawesome/free-solid-svg-icons"
import IconicInput from "../IconicInput"
import React from "react"

export default ({ facility }: any) => (
  <IconicInput icon={faCity}>
    <div>{facility?.name}</div>
    <div>{facility?.address}</div>
  </IconicInput>
)
