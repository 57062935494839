import Chance from "chance"

const chance = new Chance()

export function randomPlate() {
  return chance.string({
    length: 8,
    casing: "upper",
    alpha: true,
    numeric: true,
  })
}

export function randomHello() {
  return chance.pickone([
    "Please stop here",
    "Wait here",
    "Stop here",
    "Over here",
    "Move closer",
  ])
}

export function randomThanks() {
  return chance.pickone([
    "Thank you",
    "Thanks!",
    "¡Gracias!",
    "谢谢你",
    "どうも",
    "Merci",
    "Спасибо",
    "Danke",
    "شكرا لك",
    "תודה",
  ])
}