import React, { useState } from "react"
import moment from "moment"

const Day = ({ renderCell, time, isToday, isThisMonth }: any) => {
  const [hover, setHover] = useState(false)
  let cell = <div></div>
  try {
    cell = renderCell({ hover, time })
  } catch (e) {}
  const dayOfMonth = time.date()
  return (
    <div
      style={{
        background: [6, 7].includes(time.isoWeekday())
          ? "hsla(0, 0%, 100%, 0.0125)"
          : "none",
        border: "1px solid hsla(0, 0%, 50%, 0.05)",
        position: "relative",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            alignItems: "center",
            background: isToday ? "#FF3C33" : "none",
            display: "inline-flex",
            justifyContent: "center",
            opacity: isThisMonth ? 1 : 0.33,
            padding: "0.5em",
          }}
        >
          {dayOfMonth === 1 ? `${time.format("D MMM")}` : time.format("D")}
        </div>
      </div>
      {cell}
    </div>
  )
}

const Week = ({ renderCell, now, week }: any) => {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => {
        const time = moment()
          .week(week)
          .startOf("day")
          .isoWeekday(i + 1)
        return (
          <Day
            {...{ renderCell, time }}
            isToday={time.isSame(now, "day")}
            isThisMonth={time.isSame(now, "month")}
          />
        )
      })}
    </>
  )
}

export default ({ renderCell }: any) => {
  const now = moment()
  const week = moment()
    .startOf("month")
    .week()
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ fontSize: "200%", padding: "0.25em" }}>
        <span style={{ fontWeight: "bold" }}>{now.format("MMMM")}</span>{" "}
        {now.format("YYYY")}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          width: "100%",
        }}
      >
        {Array.from({ length: 7 }).map((_, i) => (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {moment()
              .isoWeekday(i + 1)
              .format("ddd")}
            .
          </div>
        ))}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          gridTemplateRows: "repeat(6, 1fr)",
          height: "100%",
          width: "100%",
        }}
      >
        {Array.from({ length: 6 }).map((_, i) => (
          <Week {...{ now, renderCell, week: week + i - 1 }} />
        ))}
      </div>
    </div>
  )
}
