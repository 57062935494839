import { Avatar } from "../Avatar"
import { Link, NavLink } from "react-router-dom"
import React, { useState } from "react"

const ExpandMenu = ({ user }: any) => {
  const [hover, setHover] = useState(false)
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "flex-start",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Link to="/dashboard/settings/profile">
        <Avatar size={32} url={user?.avatar?.url} name={user?.name} />
      </Link>
      {hover && (
        <>
          <Link to="/signout" style={{ margin: "0 1em" }}>
            Sign Out
          </Link>
          <NavLink to="/settings/security" style={{ margin: "0 1em" }}>
            Settings
          </NavLink>
        </>
      )}
    </div>
  )
}

export default ExpandMenu
