import { gql, useMutation } from "@apollo/client"
import { Field, Form, Formik } from "formik"
import qs from "query-string"
import React from "react"
import { useHistory } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { useAuth } from "../Auth"
import { Button, Header, Input, Label } from "../components"

export default () => {
  const auth: any = useAuth()
  const history = useHistory()
  try {
    if (window.location.search) {
      const parsed: any = qs.parse(window.location.search)
      if (parsed.token) {
        auth.dispatch(parsed.token, false)
      }
    }
  } catch (e) {}

  const [updateUser] = useMutation(gql`
    mutation UpdateUser($id: ID!, $input: UserInput!) {
      updateUser(id: $id, input: $input) {
        id
      }
    }
  `)

  const { addToast } = useToasts()

  return (
    <Formik
      {...{ initialValues: {} }}
      onSubmit={async (values: any) => {
        await updateUser({
          variables: { id: auth?.me?.id, input: { password: values.password } },
        })
        addToast("Saved", {
          appearance: "success",
          autoDismiss: true,
        })
        history.push("/")
      }}
    >
      {(_props) => (
        <Form>
          <Header>Change password</Header>
          <>
            <Label>New password</Label>
            <Field
              as={Input}
              type="password"
              name="password"
              required
              autoComplete="new-password"
            />
          </>
          {/* <>
            <Label>Confirm new password</Label>
            <Field
              as={Input}
              type="password"
              name="confirmPassword"
              required
              autoComplete="new-password"
            />
          </> */}
          <Button type="submit" positive>
            Save
          </Button>
        </Form>
      )}
    </Formik>
  )
}
