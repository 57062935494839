import React from "react"
import moment from "moment"
import TimeCell from "../TimeCell"
import { Link } from "react-router-dom"

export default [
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ cell: { value } }: any) => <div>{value}</div>,
  },
  {
    Header: "Start Day",
    accessor: "startDay",
    Cell: ({ cell: { value } }: any) => (
      <div>{value ? moment(value).format("YYYY-MM-DD") : null}</div>
    ),
  },
  {
    Header: "End Day",
    accessor: "endDay",
    Cell: ({ cell: { value } }: any) => (
      <div>{value ? moment(value).format("YYYY-MM-DD") : null}</div>
    ),
  },
  {
    Header: "Closed",
    accessor: "closedAt",
    Cell: TimeCell,
  },
  {
    Header: "Finalised",
    accessor: "finalisedAt",
    Cell: TimeCell,
  },
  {
    Header: "Created",
    accessor: "createdAt",
    Cell: TimeCell,
  },
  {
    Header: "Rate Card",
    accessor: "rateCard",
    Cell: ({ cell: { value } }: any) => (
      <Link to={`/ratecards/${value?.id}`}>{value?.name}</Link>
    ),
  },
  // {
  //   Header: "Payout",
  //   accessor: "payoutId",
  //   Cell: ({ cell: { value } }: any) => <div>{value}</div>,
  // },
  // {
  //   Header: "Stripe",
  //   accessor: "stripeCustomerId",
  //   Cell: ({ cell: { value } }: any) => (
  //     <div>
  //       <div>{value}</div>
  //     </div>
  //   ),
  // },
  // {
  //   Header: "Guest",
  //   accessor: "guestEmail",
  //   Cell: ({ cell: { value } }: any) => (
  //     <div>
  //       <div>{value}</div>
  //     </div>
  //   ),
  // },
  // {
  //   Header: "Gate Terminal",
  //   accessor: "gateTerminalId",
  //   Cell: ({ cell: { value } }: any) => <div>{value}</div>,
  // },
]
