import { Button, Form, Input, Label } from "../components"
import { Field } from "formik"
import Debug from "../Debug"
import React from "react"

export interface FormValues {
  name: string
  address: string
  organization: string
  spaces: number
}

export default ({ values, isSubmitting }: any) => (
  <Form>
    <>
      <Label>Name</Label>
      <Field as={Input} name="name" required />
    </>
    <>
      <Label>Address</Label>
      <Field as={Input} name="address" required />
    </>
    <>
      <Label>Spaces</Label>
      <Field as={Input} name="spaces" required type="number" />
    </>
    <div
      style={{
        display: "grid",
        gridGap: "0.5em",
        gridTemplateColumns: "repeat(2, 1fr)",
      }}
    >
      <Button
        disabled={isSubmitting}
        type="submit"
        style={{
          borderColor: "hsl(127, 55%, 43%)",
          color: "hsl(127, 55%, 43%)",
          width: "100%",
        }}
      >
        Continue
      </Button>
    </div>
    <Debug>{values}</Debug>
  </Form>
)
