import { Button } from "../components"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import React from "react"
import { gql } from "@apollo/client"

export default ({ id, onDelete }: any) => {
  const { addToast } = useToasts()

  const [deleteReservation] = useMutation(
    gql`
      mutation DeleteReservation($id: ID!) {
        deleteReservation(id: $id)
      }
    `,
    { refetchQueries: ["Data"], variables: { id } }
  )
  return (
    <Button
      negative
      onClick={async () => {
        try {
          await deleteReservation()
          addToast("Reservation deleted!", {
            appearance: "warning",
          })
          onDelete()
        } catch (e) {
          console.error(e)
          // addToast("Error deleting reservation!", {
          //   appearance: "warning",
          // })
        }
      }}
    >
      Delete
    </Button>
  )
}
