import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEject } from "@fortawesome/free-solid-svg-icons"
import React from "react"

export default () => (
  <div
    style={{
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      height: 64,
      justifyContent: "center",
      width: 64,
    }}
    onClick={() => {
      localStorage.clear()
      window.location.reload()
    }}
  >
    <FontAwesomeIcon icon={faEject} size={"3x"}></FontAwesomeIcon>
  </div>
)
