import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export default ({ icon, children }: any) => {
  return (
    <div style={{ alignItems: "center", display: "flex", margin: "1em 0" }}>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          margin: "0.5em",
          minWidth: "3em",
          opacity: 0.5,
        }}
      >
        <FontAwesomeIcon {...{ icon }} size={"2x"} />
      </div>
      <div>{children}</div>
    </div>
  )
}
