import { Button, Form } from "../components"
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons"
import { useQuery } from "@apollo/client"
import CancelButton from "../CancelButton"
import Debug from "../Debug"
import IntervalInput from "../IntervalInput"
import Radio from "./Radio"
import React, { useState } from "react"
import SpaceInput from "../SpaceInput"
import TenancyInput from "../TenancyInput"
import UserField from "./UserField"
import { gql } from "@apollo/client"

export interface FormValues {
  space: string
  membership?: string
  organization?: string
  lo: string
  hi: string
}

export default ({ space, values, isSubmitting }: any) => {
  const [visibility, setVisibility] = useState(
    values?.membership ? "private" : "public"
  )

  const { data: membershipsData } = useQuery(gql`
    query {
      me {
        id
        memberships {
          id
          organization {
            id
            name
            memberships {
              id
              user {
                id
                name
              }
            }
          }
          user {
            id
            name
          }
        }
      }
    }
  `)

  return (
    <Form>
      <SpaceInput {...space} />
      <TenancyInput facility={space?.facility} />
      <IntervalInput {...values} />
      <div style={{ marginBottom: "1em" }}>
        <Radio
          onChange={() => setVisibility("public")}
          icon={faUsers}
          name="visibility"
          value="public"
          title="Entire organization"
          subtitle="Anyone in your organization can claim this space."
          checked={visibility === "public"}
        />
        <Radio
          onChange={() => setVisibility("private")}
          icon={faUser}
          name="visibility"
          value="private"
          title="Specific Member"
          subtitle="You choose who can claim this space."
          checked={visibility === "private"}
        />
        {visibility === "private" ? <UserField data={membershipsData} /> : null}
      </div>
      <div
        style={{
          display: "grid",
          gridGap: "0.5em",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <CancelButton />
        <Button
          disabled={isSubmitting}
          type="submit"
          style={{
            borderColor: "hsl(127, 55%, 43%)",
            color: "hsl(127, 55%, 43%)",
            width: "100%",
          }}
        >
          Continue
        </Button>
      </div>
      <Debug>{values}</Debug>
    </Form>
  )
}
