import { gql, useQuery } from "@apollo/client"
import React from "react"
import { Camera } from "../Camera"

export default ({ id }: { id: string }) => {
  const { data } = useQuery(
    gql`
      query Facility($id: ID!) {
        facility(id: $id) {
          id
          name
          address
          organization {
            id
            name
          }
          gates {
            id
            streamName
            gateDescription
            legacyResponseOverride
            gpioTargetLevel
            type
            rank
            ip
            signs {
              id
              mac
            }
          }
        }
      }
    `,
    {
      pollInterval: 1111,
      variables: { id },
    }
  )

  return (
    <div style={{ width: "100%" }}>
      <div>{data?.facility?.name}</div>
      <div style={{ display: "flex", width: "100%" }}>
        {data?.facility?.gates?.map((gate: any) => (
          <Camera id={gate.id} style={{ width: "50%" }} />
        ))}
      </div>
    </div>
  )
}
