import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"

// const URI = "https://acacia.drivo.io/graphql"
const URI = "http://localhost:5000/graphql"

export default () =>
  new ApolloClient({
    link: new HttpLink({
      uri: URI,
    }),
    cache: new InMemoryCache(),
  })
