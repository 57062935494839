import styled from "@emotion/styled"
import { match } from "assert"
import querystring from "querystring"
import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import Applications from "./Applications"
import { useAuthContext } from "./Auth/Context"
import PrivateRoute from "./Auth/PrivateRoute"
import { Recover } from "./Auth/Recover"
import { Camera } from "./Camera"
import { useTheme } from "./components/ThemeContext"
import Composites from "./Composites"
import CreateFacility from "./CreateFacility"
import CreateOrganization from "./CreateOrganization"
import CreateRequest from "./CreateRequest"
import CreateShare from "./CreateShare"
import Dash from "./Dash"
import Devices from "./Devices"
import Docs from "./Docs"
import Edge from "./Edge"
import Edges from "./Edges"
import EditRequest from "./EditRequest"
import EditShare from "./EditShare"
import Event from "./Event"
import Events from "./Events"
import Facilities from "./Facilities"
import Facility from "./Facility"
import FacilityCameras from "./FacilityCameras"
import FacilityControls from "./FacilityControls"
import FacilityDevices from "./FacilityDevices"
import Gate from "./Gate"
import GateStats from "./GateStats"
import GateTransaction from "./GateTransaction"
import Home from "./Home"
import Join from "./Join"
import Lanes from "./Lanes"
import Masquerade from "./Masquerade"
import Masquerades from "./Masquerades"
import Navigation from "./Navigation"
import Observations from "./Observations"
import Organizations from "./Organizations"
import Plate from "./Plate"
import Plates from "./Plates"
import RateCard from "./RateCard"
import { Scene } from "./Scene"
import Settings from "./Settings"
import PasswordSettings from "./Settings/PasswordSettings"
import { DebuggableSign } from "./Sign/DebuggableSign"
import { Signin } from "./Signin"
import Signout from "./Signout"
import Things from "./Things"
import Timeline from "./Timeline"
import UserCalendar from "./UserCalendar"
import Users from "./Users"

const Styles = styled.div``

export default () => {
  const theme = useTheme()
  const location = useLocation()

  const me: any = useAuthContext()

  return (
    <Styles
      className={`App ${theme.dark ? "dark" : "light"}`}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {["/signs/", "/edges", "/macs/"].some((start) =>
        location.pathname.startsWith(start)
      ) ||
      ["/controls"].some((start) =>
        location.pathname.endsWith(start)
      ) ? null : (
        <Navigation />
      )}
      <div style={{ height: "100%", overflowY: "auto" }}>
        <Switch>
          <Route path="/scenes" render={({ location, match }) => <Scene />} />

          <Route
            exact
            path="/cameras/:id"
            render={({ location, match }) => <Camera id={match.params.id} />}
          />

          <Route
            exact
            path="/macs/:mac/sign"
            render={({ match, location }) => (
              <DebuggableSign
                mac={match.params.mac}
                debug={!!querystring.parse(location.search.slice(1)).debug}
              />
            )}
          />
          <Route
            exact
            path="/signs/:id"
            render={({ location, match }) => (
              <DebuggableSign
                id={match.params.id}
                debug={"debug" in querystring.parse(location.search.slice(1))}
              />
            )}
          />

          <PrivateRoute exact path="/devices" render={() => <Devices />} />

          <Route exact path="/lanes" render={() => <Lanes />} />

          <PrivateRoute exact path="/edges" render={() => <Edges />} />

          <PrivateRoute
            exact
            path="/edges/:id"
            render={({ match }: any) => <Edge id={match.params.id} />}
          />

          <PrivateRoute
            exact
            path="/masquerades"
            render={() => <Masquerades />}
          ></PrivateRoute>
          <Route
            exact
            path="/transactions/:id"
            render={({ match }: any) => (
              <GateTransaction id={match.params.id} />
            )}
          />
          <PrivateRoute
            exact
            path="/facilities/:id/cameras"
            render={({ match }: any) => (
              <FacilityCameras id={match.params.id} />
            )}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/facilities/:id/devices"
            render={({ match }: any) => (
              <FacilityDevices id={match.params.id} />
            )}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/facilities/:id/controls"
            render={({ match }: any) => (
              <FacilityControls id={match.params.id} />
            )}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/gates/:id/stats"
            render={({ match }: any) => <GateStats id={match.params.id} />}
          ></PrivateRoute>
          <Route
            exact
            path="/gates/:id"
            render={({ match }: any) => <Gate id={match.params.id} />}
          />
          <Route
            exact
            path="/masquerades/:id"
            render={({ match }: any) => <Masquerade id={match.params.id} />}
          />

          <PrivateRoute exact path="/dash" component={Dash}></PrivateRoute>
          <PrivateRoute
            exact
            path="/ratecards/:id"
            render={({ match }: any) => <RateCard id={match.params.id} />}
          />
          <PrivateRoute
            exact
            path="/plate/:id"
            render={({ match }: any) => <Plate id={match.params.id} />}
          />
          <PrivateRoute
            path="/observations"
            render={({ location }: any) => {
              const parsed = querystring.parse(location.search.slice(1))
              return <Observations plate={parsed.plate} />
            }}
          />
          <Route exact path="/things">
            <Things />
          </Route>

          <Route exact path="/timeline">
            <Timeline />
          </Route>

          <Route
            exact
            path="/facilities/:id/timeline"
            render={({ match }: any) => (
              <Timeline facilityId={match.params.id} />
            )}
          />
          <PrivateRoute
            exact
            path="/calendar"
            component={UserCalendar}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/shares/:id/edit"
            render={({ match }: any) => <EditShare id={match.params.id} />}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/shares/:id"
            render={({ match }: any) => (
              <Redirect to={`/shares/${match.params.id}/edit`} />
            )}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/spaces/:id/share"
            render={({ location, match }: any) => {
              const parsed = querystring.parse(location.search.slice(1))
              return (
                <CreateShare
                  id={match.params.id}
                  lo={parsed.lo}
                  hi={parsed.hi}
                />
              )
            }}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/requests/new"
            render={({ location }: any) => {
              const parsed = querystring.parse(location.search.slice(1))
              return (
                <CreateRequest
                  tenancyId={parsed.tenancy}
                  membershipId={parsed.membership}
                  lo={parsed.lo}
                  hi={parsed.hi}
                />
              )
            }}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/requests/:id/edit"
            render={({ match }: any) => <EditRequest id={match.params.id} />}
          ></PrivateRoute>
          <PrivateRoute exact path="/users" component={Users}></PrivateRoute>

          <PrivateRoute
            exact
            path="/organizations/new"
            component={CreateOrganization}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/organizations"
            component={Organizations}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/facilities/new"
            render={({ location }: any) => {
              const parsed = querystring.parse(location.search.slice(1))
              return <CreateFacility organization={parsed.organization} />
            }}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/facilities"
            render={() => <Facilities me={me} />}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/applications"
            component={Applications}
          ></PrivateRoute>
          <PrivateRoute exact path="/events" component={Events}></PrivateRoute>
          <PrivateRoute
            exact
            path="/events/:id"
            render={({ match }: any) => <Event id={match.params.id} />}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/plates"
            render={({ _match }: any) => <Plates />}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/composites"
            component={Composites}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/facilities/:id"
            render={({ match }: any) => (
              <Facility id={match.params.id} me={me} />
            )}
          ></PrivateRoute>

          <Route path="/join" component={Join} />
          <Route path="/signin" component={Signin} />
          <PrivateRoute
            exact
            path="/signout"
            component={Signout}
          ></PrivateRoute>

          <Route path="/recover" component={Recover} />

          <Route exact path="/settings" component={Settings} />
          <Route exact path="/settings/security" component={PasswordSettings} />

          <Route
            path="/throw"
            render={() => {
              JSON.parse("!@#$%^&*()")
              return <div>throw</div>
            }}
          />

          <Route path="/docs" component={Docs} />

          <PrivateRoute to="/" component={Home} />
        </Switch>
      </div>
    </Styles>
  )
}
