import React from "react"
import styled from "@emotion/styled"

const Styles = styled("div")`
  svg {
    height: 32px;
    width: 128px;
  }
  #DRIVO {
    fill: ${(props: any) => props.theme.color};
    transition: all 333ms ease;
  }
`

export default () => (
  <Styles>
    <svg width="223px" height="54px" viewBox="0 0 223 54" version="1.1">
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group" fillRule="nonzero">
          <path
            d="M17.4023438,51.9257812 C32.484375,51.9257812 41.34375,42.5390625 41.34375,26.5429688 C41.34375,10.5820312 32.4492188,1.1953125 17.4023438,1.1953125 L0,1.1953125 L0,51.9257812 L17.4023438,51.9257812 Z M16.9804688,46.2304688 L6.328125,46.2304688 L6.328125,6.890625 L16.9804688,6.890625 C28.2304688,6.890625 34.875,14.203125 34.875,26.6132812 C34.875,38.9882812 28.3007812,46.2304688 16.9804688,46.2304688 Z M61.740625,51.9257812 L61.740625,31.8515625 L74.2210938,31.8515625 L85.084375,51.9257812 L92.5023438,51.9257812 L80.7601563,30.8671875 C87.1234375,28.8632813 90.9554688,23.3789062 90.9554688,16.3125 C90.9554688,7.171875 84.5570313,1.1953125 74.7835938,1.1953125 L55.4125,1.1953125 L55.4125,51.9257812 L61.740625,51.9257812 Z M74.2210938,26.2265625 L61.740625,26.2265625 L61.740625,6.8203125 L73.9398438,6.8203125 C80.4789063,6.8203125 84.4164063,10.4414062 84.4164063,16.5234375 C84.4164063,22.7460938 80.725,26.2265625 74.2210938,26.2265625 Z M111.879687,51.9257812 L111.879687,1.1953125 L105.551562,1.1953125 L105.551562,51.9257812 L111.879687,51.9257812 Z M199.079687,53.1210938 C213.564062,53.1210938 222.353125,42.75 222.353125,26.5429688 C222.353125,10.265625 213.49375,0 199.079687,0 C184.735937,0 175.80625,10.2304688 175.80625,26.5429688 C175.80625,42.7851562 184.525,53.1210938 199.079687,53.1210938 Z M199.079687,47.2851562 C188.532812,47.2851562 182.310156,39.1289062 182.310156,26.5429688 C182.310156,13.8515625 188.74375,5.8359375 199.079687,5.8359375 C209.450781,5.8359375 215.849219,13.8515625 215.849219,26.5429688 C215.849219,39.1289062 209.485937,47.2851562 199.079687,47.2851562 Z"
            id="DRIVO"
            fill="#FFFFFF"
          ></path>
          <polygon
            id="Path-2"
            fill="#32AB40"
            points="130.064932 6.17773959 130 0 168 25.6289942 130.064932 53 130.064932 47.0881274 159.245681 25.7668061"
          ></polygon>
        </g>
      </g>
    </svg>
  </Styles>
)
