import React from "react"
import { Redirect } from "react-router"
import { useQuery } from "@apollo/client"
import { gql } from "@apollo/client"
import Spinner from "../Spinner"

export default () => {
  const { loading, data } = useQuery(
    gql`
      query Me {
        me {
          id
          roles
          facility {
            id
          }
          memberships {
            id
            organization {
              id
              facilities {
                id
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  )

  if (loading || !data?.me) {
    return <Spinner />
  }
  const me = data?.me
  if (!me.memberships?.length) {
    return <Redirect to={`/organizations/new`} />
  }
  const organization = me.memberships[0].organization
  if (!organization.facilities?.length) {
    return <Redirect to={`/facilities/new?organization=${organization.id}`} />
  }
  const facility = organization.facilities[0]
  return <Redirect to={`/facilities/${facility.id}/timeline`} />
}
