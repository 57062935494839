import { NavLink } from "react-router-dom"
import { uniq } from "lodash"
import { useQuery } from "@apollo/client"
import React from "react"
import Spinner from "../Spinner"
import { gql } from "@apollo/client"
import moment from "moment"

const Cell = ({ children }: any) => (
  <div
    style={{
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }}
  >
    {children}
  </div>
)

const Booking = ({ booking, start, range }: any) => {
  const a = Date.parse(booking.startedAt)
  const b = Date.parse(booking.scheduledEndAt)
  const left = ((a - start.getTime()) / range) * 100
  const right = 100 - ((b - start.getTime()) / range) * 100
  return (
    <div style={{ height: 16, position: "relative", width: "100%" }}>
      <div
        style={{
          alignItems: "center",
          background: "#272728",
          border: "1px solid #3A3A3B",
          display: "flex",
          fontSize: "10px",
          justifyContent: "space-between",
          left: `${Math.max(0, left)}%`,
          opacity: 0.5,
          position: "absolute",
          right: `${Math.max(0, right)}%`,
        }}
      >
        <Cell>{moment(booking.startedAt).fromNow()}</Cell>
        <Cell>{booking.plate}</Cell>
        <Cell>{moment(booking.scheduledEndAt).fromNow()}</Cell>
      </div>
    </div>
  )
}

const Visit = ({ visit, start, range, ghost }: any) => {
  const a = Date.parse(visit.startTriggeredAt)
  const b = visit.endTriggeredAt ? Date.parse(visit.endTriggeredAt) : Date.now()
  const left = ((a - start.getTime()) / range) * 100
  const right = 100 - ((b - start.getTime()) / range) * 100
  return (
    <div style={{ height: 16, position: "relative", width: "100%" }}>
      <div
        style={{
          alignItems: "center",
          background: ghost ? "hsla(127, 50%, 50%)" : "#272728",
          border: "1px solid #3A3A3B",
          display: "flex",
          fontSize: "10px",
          height: 16,
          justifyContent: "space-between",
          left: `${Math.max(0, left)}%`,
          opacity: 0.5,
          position: "absolute",
          right: `${Math.max(0, right)}%`,
        }}
      >
        {ghost ? null : (
          <>
            <Cell>{moment(visit.startTriggeredAt).fromNow()}</Cell>
            <Cell>{visit.plate}</Cell>
            {/* <Cell>{moment(booking.scheduledEndAt).fromNow()}</Cell> */}
          </>
        )}
      </div>
    </div>
  )
}

const Composite = ({ booking, visits, start, end, range }: any) => (
  <div style={{ height: 16, position: "relative", width: "100%" }}>
    {booking ? (
      <div
        style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0 }}
      >
        <Booking {...{ booking, end, range, start }} />
      </div>
    ) : null}
    <div style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0 }}>
      {visits.map((visit: any) => (
        <Visit {...{ end, ghost: true, range, start, visit }} />
      ))}
    </div>
  </div>
)

const Metric = ({ facility }: any) => {
  const { data } = useQuery(
    gql`
      query Facility($id: ID!) {
        facility(id: $id) {
          id

          metric
        }
      }
    `,
    { variables: { id: facility.id } }
  )
  facility = data?.facility || facility
  const { sysUptimeSecs, sysMemUsagePerc, procCpuUsageTotal } =
    facility?.metric || {}
  return facility?.metric ? (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>Uptime: {moment.duration(sysUptimeSecs, "seconds").humanize()}</div>
      <div>Memory: {Math.round(sysMemUsagePerc)}%</div>
      <div>CPU: {parseFloat(procCpuUsageTotal)?.toFixed(1)}</div>
    </div>
  ) : null
}

const Facility = ({ facility, range, end, start }: any) => {
  const { data } = useQuery(
    gql`
      query Facility($id: ID!) {
        facility(id: $id) {
          id
          address
          metric
          installation {
            id
            name
          }
          bookings {
            id
            plate
            startedAt
            scheduledEndAt
          }
          visits {
            id
            plate
            startTriggeredAt
            endTriggeredAt
          }
        }
      }
    `,
    { variables: { id: facility.id } }
  )

  facility = data?.facility || facility

  const bookings = data?.facility?.bookings || []
  let visits = data?.facility?.visits || []
  visits = visits.map((visit: any) => {
    const start = visit.startTriggeredAt
      ? new Date(visit.startTriggeredAt)
      : new Date()
    const end = visit.endTriggeredAt
      ? new Date(visit.endTriggeredAt)
      : new Date()
    const duration = Math.abs(
      new Date(end).getTime() - new Date(start).getTime()
    )
    return {
      ...visit,
      duration,
    }
  })

  const platesWithBookings = uniq(bookings.map((booking: any) => booking.plate))
  let composites = platesWithBookings.map((plate: any) => {
    return {
      booking: bookings.find((booking: any) => booking.plate === plate),
      visits: visits.filter((visit: any) => visit.plate === plate),
    }
  })

  // const platesWithVisits = uniq(visits.map((visit: any) => visit.plate))
  // const _platesWithoutBookings = platesWithVisits.filter(
  //   plate => !platesWithBookings.includes(plate)
  // )

  composites = [
    ...composites,
    ...platesWithBookings
      .map((plate) => {
        return {
          booking: null,
          visits: visits.filter((visit: any) => visit.plate === plate),
        }
      })
      .filter(({ visits }: any) => visits.length)
      .filter(({ visits }: any) =>
        visits.some((visit: any) => visit.duration > 1000 * 60 * 30)
      ),
  ]

  return (
    <div style={{ margin: "1em" }}>
      <div>
        <NavLink to={`/facilities/${facility.id}`}>
          <div>
            {facility.installation?.name || facility.address || facility.id}
          </div>
          <Metric facility={facility} />
        </NavLink>
      </div>
      <div
        style={{
          height: "33vh",
          overflowY: "scroll",
          position: "relative",
        }}
      >
        {data?.facility ? (
          <>
            <div style={{ position: "relative" }}>
              {composites.map((composite: any) => (
                <Composite {...composite} {...{ end, range, start }} />
              ))}
            </div>
            <div
              style={{
                background: "hotpink",
                height: "100%",
                left: `${((Date.now() - start.getTime()) / range) * 100}%`,
                position: "absolute",
                top: 0,
                width: 1,
              }}
            ></div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default () => {
  const { data } = useQuery(gql`
    {
      facilities {
        id
        address
        installation {
          id
          name
        }
      }
    }
  `)

  const START = new Date()
  START.setDate(START.getDate() - 1)
  const END = new Date()
  END.setDate(END.getDate() + 2)
  const RANGE = END.getTime() - START.getTime()

  return data?.facilities ? (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}>
      {data?.facilities
        .filter(
          (facility: any) =>
            facility.id !== "a028be96-ef8f-489a-b361-9d5f4d70cd1a"
        )
        .map((facility: any) => (
          <Facility
            facility={facility}
            {...{ end: END, range: RANGE, start: START }}
          />
        ))}
    </div>
  ) : (
    <Spinner />
  )
}
