import { gql, useQuery } from "@apollo/client"
import React, { useCallback, useState } from "react"
import { Range, getTrackBackground } from "react-range"

export function Camera({ id, style }: any) {
  const streamName = id
  const { data: gateData } = useQuery(
    gql`
      query Gate($id: ID!) {
        gate(id: $id) {
          id
          ip
          facility {
            id
          }
        }
      }
    `,
    { variables: { id } }
  )

  const gate = gateData?.gate
  const facility = gate?.facility

  const [ref, setRef] = useState(null as any)
  const onRefSet = useCallback(
    (ref) => {
      setRef(ref)
      const video = ref?.current || ref
      if (ref) {
        var protocol = "HLS"
        var options = {
          accessKeyId: "AKIAJDJP32PE4VXPPXPQ",
          secretAccessKey: "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",
          region: "ap-southeast-2",
        }
        var kinesisVideo = new (window as any).AWS.KinesisVideo(options)
        var kinesisVideoArchivedContent = new (window as any).AWS.KinesisVideoArchivedMedia(
          options
        )
        kinesisVideo.getDataEndpoint(
          {
            StreamName: streamName,
            APIName:
              protocol === "DASH"
                ? "GET_DASH_STREAMING_SESSION_URL"
                : "GET_HLS_STREAMING_SESSION_URL",
          },
          function (err: any, response: any) {
            if (err) {
              return console.error(err)
            }
            kinesisVideoArchivedContent.endpoint = new (window as any).AWS.Endpoint(
              response.DataEndpoint
            )
            kinesisVideoArchivedContent.getHLSStreamingSessionURL(
              {
                StreamName: streamName,
                PlaybackMode: "LIVE",
                HLSFragmentSelector: {
                  FragmentSelectorType: "SERVER_TIMESTAMP",
                  TimestampRange: undefined,
                },
                ContainerFormat: "FRAGMENTED_MP4",
                DiscontinuityMode: "ALWAYS",
                DisplayFragmentTimestamp: "NEVER",
                MaxMediaPlaylistFragmentResults: undefined,
                Expires: undefined,
              },
              function (err: any, response: any) {
                if (err) {
                  return console.error(err)
                }
                var player = new (window as any).Hls()
                player.loadSource(response.HLSStreamingSessionURL)
                player.attachMedia(video)
                // video.play()
                player.on((window as any).Hls.Events.MANIFEST_PARSED, () => {
                  // video.play()
                })
              }
            )
          }
        )
      }
    },
    [ref]
  )

  const [values, setValues] = React.useState([50])
  const STEP = 10
  const MIN = 0
  const MAX = 100

  return (
    <div style={{ position: "relative" }}>
      <video
        muted
        ref={onRefSet}
        id="hlsjs"
        className="player"
        // controls
        autoPlay
        onMouseEnter={() => {
          ref.play()
        }}
        {...(style || {})}
      ></video>
      {/* <div
        style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0 }}
      >
        <Range
          values={values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => setValues(values)}
          renderMark={({ props, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "8px",
                width: "2px",
                backgroundColor: index * STEP < values[0] ? "#548BF4" : "#ccc",
              }}
            />
          )}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "8px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "4px",
                  width: "100%",
                  borderRadius: "2px",
                  background: getTrackBackground({
                    values: values,
                    colors: ["#548BF4", "#ccc"],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "8px",
                width: "8px",
                borderRadius: "1px",
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA",
              }}
            >
              <div
                style={{
                  height: "16px",
                  width: "5px",
                  backgroundColor: isDragged ? "#548BF4" : "#CCC",
                }}
              />
            </div>
          )}
        />
      </div> */}
    </div>
  )
}
