import React from "react"
import moment from "moment"

export default ({ cell: { value } }: any) =>
  value ? (
    <div>
      {moment(value).calendar()}{" "}
      <span style={{ opacity: 0.25 }}>{moment(value).fromNow()}</span>
    </div>
  ) : null
