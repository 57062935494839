import React from "react"
import moment from "moment"
import Logs from "./Logs"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default [
  {
    Header: "Image",
    accessor: "mac",
    id: "image",
    Cell: ({ cell: { value } }: any) => (
      <img
        width={256}
        height={128}
        src={`https://drivo-kiosks.s3-ap-southeast-2.amazonaws.com/${value}.png`}
      />
    ),
  },
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "State",
    accessor: "state",
    Cell: ({ cell: { value } }: any) => <div>{JSON.stringify(value)}</div>,
  },
  {
    Header: "MAC",
    accessor: "mac",
  },
  {
    Header: "Archetype",
    accessor: "archetype",
    Cell: ({ cell: { value } }: any) => <div>{value?.id}</div>,
  },
  {
    Header: "Observations",
    accessor: (v: any) => v,
    Cell: ({ cell: { value } }: any) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div>{JSON.stringify(value?.temperature?.value)}</div>
        <div>{moment(value?.temperature?.time).calendar()}</div>
        <div style={{ opacity: 0.5 }}>
          {moment(value?.temperature?.time).fromNow()}
        </div>
      </div>
    ),
  },
  {
    Header: "Logs",
    accessor: "logs",
    Cell: ({ cell: { value } }: any) => <Logs value={value} />,
  },
  {
    Header: "SSH",
    accessor: "ssh",
    Cell: ({ cell: { value } }: any) => (
      <div>
        <FontAwesomeIcon icon={value?.value ? faCheck : faTimes} />
      </div>
    ),
  },
  {
    Header: "Created",
    accessor: "createdAt",
    Cell: ({ cell: { value } }: any) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div>{moment(value).calendar()}</div>
        <div style={{ opacity: 0.5 }}>{moment(value).fromNow()}</div>
      </div>
    ),
  },
  {
    Header: "Updated",
    accessor: "updatedAt",
    Cell: ({ cell: { value } }: any) => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div>{moment(value).calendar()}</div>
        <div style={{ opacity: 0.5 }}>{moment(value).fromNow()}</div>
      </div>
    ),
  },
]
