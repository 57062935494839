// @ts-nocheck
import { Container, Header } from "../components"
import { Formik, FormikHelpers } from "formik"
import { useHistory } from "react-router"
import { useMutation, useQuery } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import DeleteShare from "./DeleteShare"
import React from "react"
import ShareForm, { FormValues } from "../ShareForm"
import Spinner from "../Spinner"
import { gql } from "@apollo/client"

export default ({ id }: any) => {
  const history = useHistory()
  const { addToast } = useToasts()

  const [updateShare] = useMutation(gql`
    mutation UpdateShare($id: ID!, $input: UpdateShareInput!) {
      updateShare(id: $id, input: $input) {
        id
      }
    }
  `)

  const { data: membershipsData } = useQuery(gql`
    query {
      me {
        id
        memberships {
          id
          organization {
            id
            name
            memberships {
              id
              user {
                id
                name
              }
            }
          }
          user {
            id
            name
          }
        }
      }
    }
  `)

  const { data: shareData } = useQuery(
    gql`
      query Share($id: ID!) {
        share(id: $id) {
          id
          hi
          lo
          membership {
            id
            user {
              id
              name
            }
          }
          organization {
            id
          }
          space {
            id
            label
            facility {
              id
              name
              address
            }
          }
        }
      }
    `,
    { variables: { id } }
  )
  const share = shareData?.share
  const space = shareData?.share?.space

  return membershipsData?.me?.memberships && share && space ? (
    <Container fixed>
      <Header>
        <h3>Update your share</h3>
        <DeleteShare {...{ id }} />
      </Header>
      <Formik
        initialValues={{
          hi: share?.hi,
          lo: share?.lo,
          membership: share?.membership?.id,
          organization: share?.organization?.id,
          space: share?.space?.id,
        }}
        onSubmit={async (values: any, _actions: FormikHelpers<FormValues>) => {
          await updateShare({
            variables: {
              id,
              input: {
                ...values,
              },
            },
          })
          addToast("Share updated!", {
            appearance: "success",
          })
          history.back()
        }}
      >
        {(props: any) => <ShareForm {...props} {...{ space }} />}
      </Formik>
    </Container>
  ) : (
    <Spinner />
  )
}
