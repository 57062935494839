import { useQuery } from "@apollo/client"
import COLUMNS from "./columns"
import React from "react"
import Table from "../Table"
import { gql } from "@apollo/client"

export default ({ plate }: any) => {
  const { data } = useQuery(
    gql`
      query Observations($plate: String!) {
        observations(plate: $plate) {
          id
          plate
          createdAt
          open
          gate {
            id
            gateDescription
          }
        }
      }
    `,
    { variables: { plate }, skip: !plate }
  )

  const observations = (data || {}).observations || []

  return (
    <Table
      data={observations}
      columns={COLUMNS}
      initialState={{ sortBy: [{ desc: true, id: "createdAt" }] }}
    />
  )
}
