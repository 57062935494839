import { gql, useApolloClient, useQuery } from "@apollo/client"
import React from "react"
import { Redirect, useHistory } from "react-router"
import jsonwebtoken from "jsonwebtoken"

export default ({ id }: any) => {
  const client = useApolloClient()
  const history = useHistory()

  const { data } = useQuery(
    gql`
      query Masquerade($id: ID!) {
        masquerade(id: $id) {
          id
          location
          token
          theme
        }
      }
    `,
    { variables: { id } }
  )

  const masquerade = (data || {}).masquerade

  if (masquerade) {
    ;(async () => {
      localStorage.setItem("token", masquerade.token)
      const user = jsonwebtoken.decode(masquerade.token)
      localStorage.setItem("user", JSON.stringify(user))

      if (masquerade.theme) {
        localStorage.setItem(
          "3A123271-316D-44E1-87DE-765786736C03",
          masquerade.theme
        )
      }

      try {
        await client.resetStore()
      } catch (e) {
        setTimeout(async () => {
          try {
            await client.resetStore()
          } catch (e) {}
        }, 111)
      }

      history.push(masquerade.location || "/")
    })()

    return <div></div>
  } else {
    return <div></div>
  }
}
