import { gql, useQuery } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Cam } from "../Cam"
import { faSpider } from "@fortawesome/free-solid-svg-icons"
import { Camera } from "../Camera"

export function Lane({ id, state, compact }: any) {
  const { data } = useQuery(
    gql`
      query Observations($id: ID!) {
        gate(id: $id) {
          id
          ip
          stream
          facility {
            id
            gateway
          }
          signs {
            id
            mac
          }
          gateDescription
          issue
          scanRate
        }
      }
    `,
    { variables: { id }, pollInterval: 3333 }
  )
  const src =
    process.env.NODE_ENV === "production"
      ? `https://api.hotelparking.com.au/lanes/${id}/scapture`
      : `http://localhost:4000/lanes/${id}/scapture`
  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <div style={{ position: "relative", height: "100%" }}>
        {data?.gate ? (
          data?.gate?.stream === true ? (
            <Camera
              id={data?.gate?.id}
              style={{ width: "100%", height: "100%" }}
            />
          ) : data?.gate?.stream === false ? (
            <Cam ip={data?.gate?.ip} {...{ src, gate: id }} />
          ) : null
        ) : null}
        <div
          style={{
            pointerEvents: "none",
            width: "100%",
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            textAlign: "right",
            textTransform: "uppercase",
            padding: "1em",
          }}
        >
          {data?.gate?.gateDescription}
        </div>
        {data?.gate?.signs?.[0] ? (
          <iframe
            src={`https://drivo.io/signs/${data?.gate?.signs?.[0]?.id}`}
            style={{
              border: "none",
              width: compact ? 120 : 300,
              height: compact ? 80 : 240,
              position: "absolute",
              bottom: 0,
              right: 0,
              opacity: 0.5,
            }}
          />
        ) : null}
        {state?.value !== "idle" && state?.context?.plate ? (
          <div
            style={{
              pointerEvents: "none",
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: 64,
              background: "hsla(0, 0%, 10%, 0.5)",
              fontSize: 64,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>{state?.context?.plate}</div>
          </div>
        ) : null}
        {data?.gate?.issue ? (
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              opacity: 0.5,
              fontSize: "300%",
            }}
          >
            <FontAwesomeIcon
              icon={({ faSpider } as any)[data?.gate?.issue]}
            />
          </div>
        ) : null}

        {/* {data?.gate?.scanRate?.all && process.env.NODE_ENV === "development" ? (
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              opacity: 0.5,
              fontSize: "300%",
            }}
          >
            {(
              (data?.gate?.scanRate?.miss / data?.gate?.scanRate?.all) *
              100
            ).toFixed(3)}
          </div>
        ) : null} */}
      </div>

      {/* <div style={{ height: "100%", overflowY: "scroll" }}>
        {laneTransactions.map(({ plate, createdAt }: any) => (
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>{plate}</div>
            <div>{DateTime.fromISO(createdAt).toRelative()}</div>
          </div>
        ))}
      </div> */}
    </div>
  )
}
