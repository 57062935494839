import React from "react"
import "./index.css"

export default ({ children, style, ...rest }: any) => (
  <div
    className="SignContainer"
    style={{
      color: "#ccc",
      background: "#060606",
      position: "relative",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      ...(style || {}),
    }}
    {...rest}
  >
    {children}
  </div>
)
