import styled from "@emotion/styled"

export const Header = styled.header`
  color: ${(props: any) => props.theme.color};
  padding: 0.5em 0;
  border-bottom: 1px solid hsl(0, 0%, 50%, 0.125);
  align-items: center;
  display: flex;
  justify-content: space-between;
  h3 {
    margin: 0;
  }
  margin-bottom: 0.5em;
`
