import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons"
import { useTheme } from "../components/ThemeContext"
import React from "react"
import styled from "@emotion/styled"

const Styles = styled("div")`
  color: ${(props: any) => props.theme.color};
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default () => {
  const themeState = useTheme()
  return (
    <Styles onClick={() => themeState.toggle()} style={{ cursor: "pointer" }}>
      <FontAwesomeIcon icon={themeState.dark ? faMoon : faSun} size={"3x"} />
    </Styles>
  )
}
