import { MasqueradeUUID } from "./Masquerade"
import { setContext } from "@apollo/client/link/context"
import { InMemoryCache, HttpLink, ApolloClient } from "@apollo/client"
import ENDPOINT from "../Apollo/endpoint"

export default () => {
  const httpLink = new HttpLink({
    uri: ENDPOINT,
  })

  const authLink = setContext((_: any, { headers }: any) => {
    const token = localStorage.getItem(MasqueradeUUID)
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    }
  })

  return new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
      watchQuery: {
        fetchPolicy: "cache-and-network",
        returnPartialData: true,
      },
    },
    link: authLink.concat(httpLink),
  })
}
