import React from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"
import { useAuthContext } from "./Context"

export const withHooksHOC = (Component: any) => {
  return (props: any) => {
    const authContext = useAuthContext()
    return <Component isAuthenticated={authContext} {...props} />
  }
}

export default withHooksHOC(
  class PrivateRoute<
    T extends RouteProps & { isAuthenticated: any } = RouteProps & {
      isAuthenticated: any
    }
  > extends React.Component<T, any> {
    render() {
      const { isAuthenticated, component, render, ...rest } = this.props

      return (
        // @ts-ignore
        <Route
          {...rest}
          render={(props) =>
            isAuthenticated ? (
              render ? (
                render(props)
              ) : component ? (
                React.createElement(component as any, props)
              ) : (
                React.createElement("div", props)
              )
            ) : (
              <Redirect to="/signin" />
            )
          }
        />
      )
    }
  }
)
