import React, { useState, useEffect } from "react"
import { Input } from "../components"

export default React.forwardRef(({ space, onDirtyBlur, ...rest }: any, ref) => {
  const [value, setValue] = useState(space?.label || "")

  useEffect(() => {
    setValue(space?.label || "")
  }, [space])

  return (
    <Input
      ref={ref}
      value={value}
      style={{
        WebkitUserSelect: "text",
        userSelect: "text",
        margin: 0,
        padding: 0,
        height: 42,
        width: "100%",
        textAlign: "center",
      }}
      onChange={(e: any) => setValue(e.target.value)}
      onBlur={() => {
        space?.label !== value
          ? onDirtyBlur({ id: space?.id, label: value })
          : onDirtyBlur()
      }}
      {...rest}
    />
  )
})
