import React from "react"
import Table from "../Table"
import COLUMNS from "./columns"
import useThings from "./useThings"

export default () => {
  const things = useThings()
  return (
    <Table
      data={things}
      columns={COLUMNS}
      initialState={{ sortBy: [{ desc: true, id: "activeAt" }] }}
    />
  )
}
