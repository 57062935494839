import { useQuery, gql } from "@apollo/client"


export default ({ mac, ...props }: { id: string; mac: string }) => {
    const { data: signByMacData } = useQuery(
        gql`
            query SignByMac($mac: String!) {
                sign: signByMac(mac: $mac) {
                    id
                }
            }
        `,
        { skip: !mac, variables: { mac }, pollInterval: 1000 }
    )

    const id = props.id || signByMacData?.sign?.id

    const { data: signData } = useQuery(
        gql`
            query Sign($id: ID!) {
                sign(id: $id) {
                    id
                    state
                    poi
                    updatedAt
                    gate {
                        gateDescription
                    }
                    facility {
                        id
                        name
                        city {
                            id
                            timezone
                        }
                    }
                }
            }
        `,
        { variables: { id }, skip: !id, pollInterval: 1000 }
    )

    /*
    const { data } = useSubscription(
        gql`
            subscription SignUpdated($id: ID!) {
                signUpdated(id: $id) {
                    id
                    state
                    updatedAt
                }
            }
        `,
        {
            variables: { id }, skip: !id, onSubscriptionData: async (options) => {
                try {
                    const sign = options?.subscriptionData?.data?.signUpdated;
                    if (!sign?.id) return;

                    const screen = getScreenFromSign(sign);
                    await notifyBackend({
                        variables: {
                            signId: sign.id,
                            plate: sign.state.plate,
                            amount: Number(sign.state.amount),
                            screen: screen,
                        }
                    });

                } catch (e) {

                }
            }
        }
    )
    */

    return {
        ...(signData?.sign || {}),
        // ...(data?.signUpdated || {}),
    }
}

export function getScreenFromSign(sign: any) {
    return sign?.state?.amount
        ? "payment.tender"
        : sign?.state?.processing ? "payment.processing"
            : sign?.state?.plate || sign?.state?.checkWhitelist
                ? "payment.checkWhitelist"
                : sign?.state?.salutation
                    ? "awaitingPlate"
                    : sign?.state?.complete
                        ? "gateOpen"
                        : "idle";
}
