import { Button } from "../components"
import { Link } from "react-router-dom"
import { extendMoment } from "moment-range"
import AssignmentCell from "./AssignmentCell"
import Calendar from "../Calendar"
import Moment from "moment"
import React, { useState } from "react"
import RequestButton from "./RequestButton"
import useData from "./useData"
const moment = extendMoment(Moment as any)

export default () => {
  const [hover, setHover] = useState(null)
  const todayAndAfter = (moment.range as any)(moment().startOf("day"))
  const afterToday = (moment.range as any)(
    moment()
      .startOf("day")
      .add(1, "day")
  )

  const { data } = useData()

  const memberships = data?.me?.memberships || []

  const assignments = (data?.me?.assignments || []).map((assignment: any) => ({
    ...assignment,
    range: moment.range(assignment.lo, assignment.hi),
  }))

  const shares = (data?.me?.shares || []).map((share: any) => ({
    ...share,
    range: moment.range(share.lo, share.hi),
  }))

  const requests = (data?.me?.requests || []).map((request: any) => ({
    ...request,
    range: moment.range(request.lo, request.hi),
  }))

  const organizations = memberships.map(({ organization }: any) => organization)
  const tenancies = organizations.reduce(
    (acc: any, organization: any) => [
      ...acc,
      ...(organization.tenancies || []),
    ],
    []
  )

  return (
    <Calendar
      renderCell={({ time, hover: cellHover }: any) => {
        const today = moment.range(
          moment(time).startOf("day"),
          moment(time).endOf("day")
        )
        const assignmentsToday = assignments.filter(({ range }: any) =>
          range.overlaps(today)
        )
        const sharesToday = shares.filter(({ range }: any) =>
          range.overlaps(today)
        )
        const requestsToday = requests.filter(({ range }: any) =>
          range.overlaps(today)
        )
        return assignmentsToday?.length ? (
          <>
            {assignmentsToday.map((assignment: any) => {
              const key = `${assignment.id}-${today.toString()}`
              return (
                <AssignmentCell
                  {...{
                    assignment,
                    future: todayAndAfter,
                    hover: hover === key,
                    key,
                    setHover,
                    today,
                  }}
                  shares={sharesToday}
                />
              )
            })}
          </>
        ) : requestsToday?.length ? (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {requestsToday.map((request: any) => (
              <Link to={`/requests/${request.id}/edit`}>
                <Button>Requested</Button>
              </Link>
            ))}
          </div>
        ) : cellHover && afterToday.overlaps(today) ? (
          <RequestButton
            {...{ membership: memberships[0], tenancy: tenancies[0], today }}
          />
        ) : (
          <></>
        )
      }}
    />
  )
}
