import { gql, useApolloClient, useMutation } from "@apollo/client"
import { useContext } from "react"
import { useHistory } from "react-router-dom"
import { MasqueradeContext } from "../Admin/Masquerade"
import { useAuthContext } from "./Context"

export const useAuth = () => {
  const me: any = useAuthContext()
  const history = useHistory()
  const client = useApolloClient()
  const masquerade: any = useContext(MasqueradeContext)

  const [signinMutation] = useMutation(gql`
    mutation Signin($email: String!, $password: String!) {
      signin(email: $email, password: $password)
    }
  `)
  const [signupMutation] = useMutation(gql`
    mutation Join($input: JoinInput!) {
      join(input: $input)
    }
  `)

  const dispatch = async (token: any, redirect: boolean = true) => {
    window.localStorage.setItem("token", token)

    const {
      data: { me },
    } = await client.query({
      query: gql`
        query Me {
          me {
            id
            email
            roles
            name
          }
        }
      `,
    })

    window.localStorage.setItem("user", JSON.stringify(me))

    await client.resetStore()

    if (redirect) {
      setTimeout(() => {
        history.push("/")
      }, 333)
    }

    try {
      masquerade.dispatch({
        payload: {
          user: me,
          token,
        },
        type: "session",
      })
    } catch (e) {}
  }

  const signin = async (variables: any) => {
    const { data } = await signinMutation({ variables })
    await dispatch(data.signin)
  }

  const join = async (variables: any) => {
    const { data } = await signupMutation({ variables })
    await dispatch(data.join)
  }

  return { dispatch, join, me, signin }
}
