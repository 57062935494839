import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSortAlphaDownAlt,
  faSortAlphaUp,
} from "@fortawesome/free-solid-svg-icons"
import { useFilters, useSortBy, useTable } from "react-table"
import React from "react"
import matchSorter from "match-sorter"
import styled from "@emotion/styled"

const Styles = styled.div`
  table {
    width: 100%;
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 2px;
      border: none;
    }
    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
    tr:nth-child(even) {
      background: hsla(0, 0%, 0%, 0.05);
    }
    tr:hover {
      background: hsla(0, 0%, 0%, 0.1);
    }
  }
  .pagination {
    padding: 0.5rem;
  }
`

// function DefaultColumnFilter({
//   column: { filterValue, preFilteredRows, setFilter }
// }) {
//   const count = preFilteredRows.length;
//   return (
//     <input
//       value={filterValue || ""}
//       onChange={e => {
//         setFilter(e.target.value || undefined);
//       }}
//       placeholder={`Search ${count} records...`}
//     />
//   );
// }

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}
fuzzyTextFilterFn.autoRemove = (val) => !val

function Table({ columns, data, initialState = {}, more }) {
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  // const defaultColumn = React.useMemo(
  //   () => ({
  //     Filter: DefaultColumnFilter
  //   }),
  //   []
  // );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      // defaultColumn,
      filterTypes,
      initialState,
    },
    useFilters,
    useSortBy
  )

  return (
    <>
      {/* <div>
        <pre>
          <code>{JSON.stringify(state.filters, null, 2)}</code>
        </pre>
      </div> */}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div style={{ display: "flex" }}>
                    <div>{column.render("Header")}</div>
                    {column.isSorted && (
                      <div style={{ margin: "0 0.25em" }}>
                        {column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortAlphaDownAlt} />
                        ) : (
                          <FontAwesomeIcon icon={faSortAlphaUp} />
                        )}
                      </div>
                    )}
                  </div>
                  {/* <div>{column.canFilter ? column.render("Filter") : null}</div> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, _i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render("Cell", { more: more || {} })}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default (props) => (
  <Styles>
    <Table {...props} />
  </Styles>
)
