import React, { useState, useMemo } from "react"
import { format } from "prettier/standalone"
import prettierBabel from "prettier/parser-babel"
import parserGraphql from "prettier/parser-graphql"
import styled from "@emotion/styled"
import { useQuery, gql, ApolloClient, InMemoryCache } from "@apollo/client"
import { Controlled as CodeMirror } from "react-codemirror2"
import "codemirror/addon/hint/show-hint"
import "codemirror/addon/lint/lint"
import "codemirror/mode/javascript/javascript"
import "codemirror-graphql/hint"
import "codemirror-graphql/lint"
import "codemirror-graphql/mode"
import { Label, Input, Button, Select } from "../components"
import { Field, Formik, Form } from "formik"
import moment from "moment"
import { sortBy } from "lodash"
import Intro from "./Intro"
import GQL_ENDPOINT from "../Apollo/endpoint"

const Styles = styled.div`
  .CodeMirror {
    height: 200px;
  }
`

const Foo = ({
  id,
  heading,
  query,
  mutation,
  variables,
  token,
  endpoint,
  children,
  initialValues = {},
  handleSubmit,
  prepare,
  apollo,
}: any) => {
  const [value, setValue] = useState(
    format(mutation || query, {
      parser: "graphql",
      plugins: [parserGraphql],
    })
  )
  const [response, setResponse] = useState(``)

  const execute = async (variables: any) => {
    setResponse("")
    try {
      let out = query
        ? await apollo.query({
            query: gql(value),
            variables,
            fetchPolicy: "network-only",
          })
        : await apollo.mutate({
            mutation: gql(value),
            variables: prepare ? prepare(variables) : variables,
          })
      setResponse(JSON.stringify({ data: out?.data }, null, 2))
    } catch (e) {
      setResponse(JSON.stringify(e, null, 2))
    }
  }

  return (
    <Formik
      id={id}
      initialValues={initialValues}
      onSubmit={(values: any) => {
        execute(values)
      }}
      enableReinitialize={true}
    >
      {(props: any) => (
        <Form style={{ marginTop: "1em", marginBottom: "2em" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "33%", padding: "0 1em" }}>
              <h3>{heading}</h3>
              {children instanceof Function ? children(props) : children}
              <Button type="submit" style={{ margin: "1em 0" }}>
                Run
              </Button>
            </div>
            <div style={{ width: "66%" }}>
              <div style={{}}>
                <div style={{ width: "100%" }}>
                  {/*
// @ts-ignore */}
                  <CodeMirror
                    value={value}
                    options={{
                      mode: "graphql",
                      theme: "material",
                      lineNumbers: true,
                    }}
                    onBeforeChange={(_editor: any, data: any, value: any) => {
                      setValue(value)
                    }}
                    onChange={() => {}}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  {/*
// @ts-ignore */}
                  <CodeMirror
                    value={format(response, {
                      parser: "json",
                      plugins: [prettierBabel],
                    })}
                    options={{
                      mode: "javascript",
                      theme: "material",
                      lineNumbers: true,
                    }}
                    onBeforeChange={() => {}}
                    onChange={() => {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const LoHi = ({ values, setFieldValue }: any) => (
  <>
    <Label>lo</Label>
    <Field
      as={Input}
      name="lo"
      type="datetime-local"
      value={moment(values.lo).format("YYYY-MM-DDTHH:mm")}
      onChange={(e: any) =>
        setFieldValue(
          "lo",
          moment(e.target.value, "YYYY-MM-DDTHH:mm").toISOString(true)
        )
      }
    />
    <Label>hi</Label>
    <Field
      as={Input}
      name="hi"
      type="datetime-local"
      value={moment(values.hi).format("YYYY-MM-DDTHH:mm")}
      onChange={(e: any) =>
        setFieldValue(
          "hi",
          moment(e.target.value, "YYYY-MM-DDTHH:mm").toISOString(true)
        )
      }
    />
  </>
)

const FacilityInput = () => {
  const { data } = useQuery(gql`
    {
      facilities {
        id
        name
        placie
      }
    }
  `)

  const facilities = (data?.facilities || []).filter(
    (facility: any) => facility.placie
  )

  return (
    <>
      <Label>Facility</Label>
      <Field as={Select} name="facility">
        <option></option>
        {sortBy(facilities, "name").map(({ id, name }: any) => (
          <option value={id}>
            {id} ({name})
          </option>
        ))}
      </Field>
    </>
  )
}

export default () => {
  const { data } = useQuery(
    gql`
      query Applications {
        me {
          id
          memberships {
            id
            organization {
              id
              name
              applications {
                id
                name
                endpoint
                keys {
                  id
                  value
                  env
                }
              }
            }
          }
        }
      }
    `
  )

  const memberships = data?.me?.memberships || []

  const membership = memberships.filter(
    (membership: any) => membership.organization?.applications?.length
  )?.[0]

  const keys = membership?.organization?.applications?.[0]?.keys || []

  const [env, setEnv] = useState("development")

  const key = keys.find((key: any) => key.env === env)?.value || "n/a"

  const queries = [
    {
      query: `query {
        facilities {
          id
          location {
            latitude
            longitude
          }
          reservations {
            id
            plate
            lo
            hi
          }
        }
      }`,
      children: <>Retrieve all Facilities and their current Reservations</>,
      heading: "All Facilities and Reservations",
      id: "One",
    },
    {
      query: `query ParkingEstimate($lo: String!, $hi: String!) {
        facilities {
          id
          estimate(lo: $lo, hi: $hi) {
            cost
            capacity
          }
        }
      }`,
      initialValues: {
        lo: moment().startOf("day").add(7, "hours").toISOString(),
        hi: moment().startOf("day").add(17, "hours").toISOString(),
      },
      children: (props: any) => (
        <>
          Retrieve all Facilities and their parking Estimates for a given time
          period
          <LoHi {...props} />
        </>
      ),
      heading: "All Facilities with Estimates",
      id: "Two",
    },
  ]
  const mutations = [
    {
      mutation: `
        mutation CreateReservation($input: CreateReservationInput!) {
          createReservation(input: $input) {
            id
            lo
            hi
            plate
            facility {
              id
            }
          }
        }
        `,
      initialValues: {
        lo: moment().startOf("day").add(7, "hours").toISOString(),
        hi: moment().startOf("day").add(17, "hours").toISOString(),
        plate: "123ABC",
      },
      children: (props: any) => (
        <>
          <LoHi {...props} />
          <>
            <Label>plate</Label>
            <Field as={Input} name="plate" />
          </>
          <FacilityInput />
        </>
      ),
      heading: "Create a Reservation",
      id: "Three",
      prepare: (variables: any) => ({ input: variables }),
    },
    {
      mutation: `
        mutation CancelReservation($id: ID!) {
          cancelReservation(id: $id) {
            id
            cancelled
          }
        }
        `,
      children: (props: any) => (
        <>
          <Label>ID</Label>
          <Field as={Input} name="id" />
        </>
      ),
      heading: "Cancel a Reservation",
      id: "Four",
    },
    {
      mutation: `
        mutation TriggerReservation($id: ID!, $input: TriggerReservationInput!) {
          triggerReservation(id: $id, input: $input)
        }
        `,
      children: (props: any) => (
        <>
          Upon completion of a reservation (Driver has arrived at exit gate)
          final amount owing (whole cents) and interval (
          <a href="https://en.wikipedia.org/wiki/ISO_8601#Time_intervals">
            ISO 8601 interval
          </a>
          ) will be calculated and sent via HTTP POST request to configured
          endpoint with the following data:
          <pre>
            {format(
              `{
                  "amount": $amountOwing,
                  "interval": $interval,
                  "reservation": {
                    "facility": {
                      "id": "$UUID"
                    },
                    "hi": "2020-04-23T07:00:00.000Z",
                    "id": "$UUID",
                    "lo": "2020-04-22T21:00:00.000Z"
                  }
                }`,
              {
                parser: "json",
                plugins: [prettierBabel],
              }
            )}
          </pre>
          This endpoint should process payment with the application users stored
          payment method and return confirmation. Response should be cached as
          in the event of network failure this request may be tried multiple
          times and response from previous successful transaction should be
          returned.
          <>
            <Label>ID</Label>
            <Field as={Input} name="id" />
          </>
          <>
            <Label>Amount</Label>
            <Field as={Input} name="amount" />
          </>
          <>
            <Label>Endpoint</Label>
            <Field as={Input} name="endpoint" />
          </>
        </>
      ),
      heading: "Trigger payment webhook",
      id: "Five",
      prepare: ({ id, endpoint, amount }: any) => ({
        id,
        input: {
          endpoint,
          amount: amount != null ? parseInt(amount, 10) : undefined,
        },
      }),
    },
  ]

  const apollo = useMemo(() => {
    const client = new ApolloClient({
      uri: GQL_ENDPOINT,
      headers: {
        Authorization: `Bearer ${key}`,
      },
      cache: new InMemoryCache(),
    })
    return client
  }, [key])

  const props = {
    apollo,
  }

  return (
    <Styles style={{ padding: "0 1em" }}>
      <Intro token={key} {...{ env, setEnv, apollo }} />
      {/* <div>
        <Label>Key</Label>
        <input value={key} />
      </div> */}
      <div>
        <h2>Queries</h2>
        {queries.map((op: any) => (
          <Foo {...props} {...op} />
        ))}
        <h2>Mutations</h2>
        {mutations.map((op: any) => (
          <Foo {...props} {...op} />
        ))}
      </div>
    </Styles>
  )
}
