import { Container, Header } from "../components"
import { Formik } from "formik"
import React from "react"
import ReservationForm from "../ReservationForm"

export default ({ onCancel, reservation, ...props }: any) => {
  return (
    <Container fixed>
      <Header>
        <h3>Create Reservation</h3>
      </Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          hi: reservation?.range?.end,
          lo: reservation?.range?.start,
          plate: reservation?.plate,
          space: reservation?.space?.id,
        }}
        {...props}
      >
        {(props: any) => <ReservationForm {...props} {...{ onCancel }} />}
      </Formik>
    </Container>
  )
}
