import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export default ({
  name,
  value,
  title,
  subtitle,
  checked,
  icon,
  onChange,
}: any) => (
  <label style={{ cursor: "pointer", margin: "1em" }}>
    <div style={{ alignItems: "center", display: "flex" }}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        style={{ margin: "1em" }}
      />
      <FontAwesomeIcon icon={icon} style={{ margin: "0 1rem" }} size={"2x"} />
      <div>
        <div>{title}</div>
        <div>{subtitle}</div>
      </div>
    </div>
  </label>
)
