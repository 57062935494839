import styled from "@emotion/styled"

export default styled.div`
  align-items: center;
  background: hsla(0, 0%, 50%, 0.125);
  display: flex;
  justify-content: center;
  width: 10%;
  user-select: none;
`
