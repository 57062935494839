import styled from "@emotion/styled"

export const Textarea = styled.textarea`
  border-radius: 3px;
  padding: 1em;
  display: block;
  background: hsla(0, 0%, 100%, 0.9);
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 16px;
  min-height: 20vh;
  resize: none;
`
