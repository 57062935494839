import { motion } from "framer-motion"
import React from "react"
import styled from "@emotion/styled"

const Styles = styled(motion.div)`
  background: ${({ theme }: any) => theme.background};
`

export default ({ children }: any) => (
  <Styles
    initial={{ scale: 1, translateX: "33vw" }}
    animate={{ scale: 1, translateX: 0 }}
    exit={{ scale: 1, translateX: "33vw" }}
    transition={{
      damping: 20,
      stiffness: 260,
      type: "spring",
    }}
    style={{
      height: "100%",
      padding: "1.61rem",
      position: "absolute",
      right: 0,
      top: 0,
      width: "33vw",
      zIndex: 9000,
    }}
  >
    {children}
  </Styles>
)
