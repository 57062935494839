import { gql, useQuery } from '@apollo/client'

export default (facilityId: any) => {
  return useQuery(
    gql`
      query Data($id: ID!) {
        facility(id: $id) {
          id
          spaces {
            id
            label
            reservations {
              id
              lo
              hi
              plate
            }
          }
        }
      }
    `,
    { variables: { id: facilityId } }
  )
}
