import { gql, useQuery } from "@apollo/client"
import { DateTime, Duration, Interval } from "luxon"
import React from "react"

export default ({ id }: any) => {
  const { data } = useQuery(
    gql`
      query Gate($id: ID!, $lo: String, $hi: String) {
        gate(id: $id) {
          id
          observations(lo: $lo, hi: $hi) {
            id
            plate
            createdAt
          }
        }
      }
    `,
    {
      variables: {
        id,
        lo: DateTime.local().minus({ days: 7 }).startOf("minute").toISO(),
        hi: DateTime.local().startOf("minute").toISO(),
      },
    }
  )

  const scans = data?.gate?.observations || []

  const epoch = DateTime.local().minus({ days: 6 }).startOf("day")

  const days = Array.from({ length: 7 }).map((_: any, i: any) => {
    const interval = Interval.fromDateTimes(
      epoch.plus({ days: i }),
      epoch.plus({ days: i + 1 })
    )
    return {
      start: epoch.plus({ days: i }),
      interval,
      scans: scans.filter(({ createdAt }: any) =>
        interval.contains(DateTime.fromISO(createdAt))
      ),
      index: i,
    }
  })

  return (
    <div style={{ display: "flex", width: "100%", alignItems: "flex-end" }}>
      {days.map(({ scans, start }: any) => {
        const hours = Array.from({ length: 24 }).map((_: any, i: any) => {
          const interval = Interval.fromDateTimes(
            start.plus({ hours: i }),
            start.plus({ hours: i + 1 })
          )
          return {
            interval,
            scans: scans.filter(({ createdAt }: any) =>
              interval.contains(DateTime.fromISO(createdAt))
            ),
          }
        })

        return (
          <div style={{ height: scans, width: "100%" }}>
            <div>{start.toFormat("ccc")}</div>
            {hours.map(({ scans }: any) => (
              <div
                style={{
                  background: "#333",
                  opacity: Math.max(0.125, scans.length / 500),
                }}
              >
                {scans.length}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}
