import { useQuery, gql } from "@apollo/client"
import React from "react"
import Observations from "../Observations"
import Charges from "../Charges"
import Bookings from "../Bookings"

export default (props: any) => {
  const { data } = useQuery(
    gql`
      query Plate($id: ID!) {
        plate(id: $id) {
          id
        }
      }
    `,
    { variables: { id: props.id }, pollInterval: 1337, skip: !props.id }
  )

  const plate = data.plate || {}

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div>
          <h1>Observations</h1>
          <Observations plate={plate?.id} />
        </div>
        <div>
          <h1>Charges</h1>
          <Charges plate={plate?.id} />
        </div>
        <div>
          <h1>Bookings</h1>
          <Bookings plate={plate?.id} />
        </div>
      </div>
    </div>
  )
}
