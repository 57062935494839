import React from "react"
import moment from "moment"

export default ({ value }: any) => (
  <div style={{}}>
    {(value || []).map(([k, v]: any) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>{v}</div>
        <div style={{ flex: 1, opacity: 0.5 }}>
          {moment(Math.round(parseInt(k) / 1000 / 1000)).fromNow(true)}
        </div>
      </div>
    ))}
  </div>
)
