import { Button } from "../components"
import { Link } from "react-router-dom"
import React from "react"

export default ({ today, tenancy, membership }: any) => (
  <div
    style={{
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <Link
      to={`/requests/new?tenancy=${tenancy?.id}&membership=${
        membership?.id
      }&lo=${encodeURIComponent(
        today.start.toISOString(true)
      )}&hi=${encodeURIComponent(today.end.toISOString(true))}`}
    >
      <Button>Request</Button>
    </Link>
  </div>
)
