import { useQuery } from "@apollo/client"
import React from "react"
import Spaces from "./Spaces"
import Tenancies from "./Tenancies"
import { gql } from "@apollo/client"
import { Link } from "react-router-dom"

export default ({ id, me }: any) => {
  const { data } = useQuery(
    gql`
      query Facility($id: ID!) {
        facility(id: $id) {
          id
          name
          address
          gates {
            id
            gateDescription
            type
          }
          # installation {
          #   id
          #   name
          # }
        }
      }
    `,
    { returnPartialData: true, variables: { id } }
  )

  const facility = data?.facility || { id }

  const name = facility?.name || facility?.installation?.name

  return me?.roles?.includes("SUDO") ? (
    <div>
      <div>{data?.facility?.name}</div>
      <div>gates</div>
      {(data?.facility?.gates || [])
        .filter(({ type }: any) => type === "EXIT")
        .map(({ id, gateDescription }: any) => (
          <div style={{ padding: "2em", margin: "2em" }}>
            <Link to={`/gates/${id}`}>{gateDescription}</Link>
          </div>
        ))}
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        margin: "1em",
      }}
    >
      <div>
        <h1>{name}</h1>
      </div>
      <div style={{ display: "flex", height: "50%" }}>
        <div style={{ width: "33%" }}>
          <h2>Tenancies</h2>
          <Tenancies facility={facility} />
        </div>
        <div style={{ width: "66%" }}>
          <h2>Spaces</h2>
          <Spaces facility={facility} />
        </div>
      </div>
    </div>
  )
}
